

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,

  Modal,
} from "react-bootstrap";
import { GetDashboardCreditPerServiceFeeDetail } from "../../../store/api/apiSlice";
import { useAppDispatch } from "../../../store/hooks";
import GridComponent from "../../../components/grid/gridComponent";
import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
import { formateIds, gridAlignment } from "../../../interface/comman.constant";
import { getTableFields } from "../../../helpers/getTableFields";

function CreditsPerServiceFeeDollar(props: any) {
  const dispatch = useAppDispatch();
  const [gridApi, setGridApi] = useState<any>(null);
  const userAccounts = useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage = useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const creditPerServiceFeeCols = (JSON.parse(localStorage.getItem('creditPerServiceFeeCol') || '[]'));
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const [totalCredit, setTotalCredit] = useState('$0.0')
  const [totalServiceFee, setTotalServiceFee] = useState('$0.0')
  const [totalCreditServiceFee, setTotalCreditServiceFee] = useState('$0.0')
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "GetDashboardCreditPerServiceFeeDetail.csv",
    URL: "Dashboard/GetDashboardCreditPerServiceFeeDetail",
    filterOptions: [],
  });
  const columnitems = [
    {
      headerName: 'Order#',
      field: "orderNumber",
      hide: creditPerServiceFeeCols.includes("orderNumber"),
      comparator: customComparator,
      cellStyle: { cursor: "pointer" },
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true
      },
      menuTabs: ["filterMenuTab"],
      width: 160
    },
    {
      headerName: 'Total Credits',
      field: "totalCredits",
      hide: creditPerServiceFeeCols.includes("totalCredits"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: 'Total Service Fee',
      field: "totalServiceFees",
      hide: creditPerServiceFeeCols.includes("totalServiceFees"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Credits/Service Fee Dollar",
      field: "creditServiceFees",
      hide: creditPerServiceFeeCols.includes("creditServiceFees"),
      comparator: customComparator,
      type: gridAlignment.RIGHT,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true
      },
      menuTabs: ["filterMenuTab"],
    },
  ];
  const [colDefs, setColDefs] = useState(columnitems);
  function customComparator() {
    return 0; //means no comparing and no sorting client side
  }


  useEffect(() => {

    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        refreshData();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      refreshData();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.globalSearch,
    gridApi,
    gridData.sortField,
    gridData.sortOrder
  ]);

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));
  const onGridReady = (params: any) => {
    setGridApi(params.api);
  }

  const refreshData = () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              pageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              pageSize: gridData.pageSize,
              GlobalSearch: gridData.globalSearch,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
              SortField: gridData.sortField,
              sortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
            }
            dispatch(GetDashboardCreditPerServiceFeeDetail(data)).then((result: any) => {
              updateGridData("totalRows", result?.payload?.data?.totalCount);
              updateGridData("rows", result?.payload?.data?.creditServiceFeeDetails);
              setTotalCredit(result?.payload?.data?.totalCredit);
              setTotalServiceFee(result?.payload?.data?.totalServiceFee);
              setTotalCreditServiceFee(result?.payload?.data?.totalCreditServiceFee);
              if (result?.payload?.data?.creditServiceFeeDetails?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.creditServiceFeeDetails,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
            });
          }
        }

        gridApi.setGridOption('serverSideDatasource', dataSource);
        gridApi.setGridOption('domLayout', 'autoHeight')
      }
    } catch (error) { }

  }
  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();
    const cols: any = [];

    columnValues.forEach((value: any) => {
      if (value?.hide === true)
        cols.push(value.field);
    });

    localStorage.setItem("creditPerServiceFeeCol", JSON.stringify(cols));
    setColDefs(columnValues);
    myRef?.current.handleClose();
  };
  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };
  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };
  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", '');
  }
  const pinData = [{
    orderNumber: 'Total',
    totalCredits: totalCredit,
    totalServiceFees: totalServiceFee,
    creditServiceFees: totalCreditServiceFee,
  }]
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  }
  return (
    <Modal
      show={props.show}
      size="lg"
      onHide={props.handleClose}
      className="custom_modal credit_dollar"
    >
      <Modal.Header className="custom_modal_header">
        <Modal.Title className="custom_modal_header_title">
          <div className="custom_modal_header_title_left">Credits Per Service Fee Dollar</div>
          <div className="custom_modal_header_title_right">
            <Button variant="link" onClick={props.handleClose}>
              <i className="fa-solid fa-times"></i>
            </Button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom_modal_body">
        <GridComponent
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          pinnedBottomRowData={totalCredit != "$0.00" ? pinData : []}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          isAutoHeight={true}
          paginationChange={paginationChange}
        ></GridComponent>
      </Modal.Body>
    </Modal>
  );
}

export default CreditsPerServiceFeeDollar;
