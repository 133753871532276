import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useAppDispatch } from "../../../store/hooks";
import { postReportIssue } from "../../../store/api/apiSlice";
import { toast } from "react-toastify";
import { isScript } from "../../../interface/comman.constant";

function ReportIssue(props: any) {
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSubjectMessage, setErrorSubjectMessage] = useState("");
  const [errorFile, setErrorFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [data, setData] = useState({
    Subject: "",
    Message: "",
  });
  const [file, setFile]: any = useState("");
  const allowedTypes = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "image/jpeg",
    "image/png",
    "image/gif",
  ];

  const validate = () => {
    if (data.Subject === "" && data.Message === "") {
      setErrorMessage("Message is Required");
      setErrorSubjectMessage("Subject is Required");
    } else if (data.Subject === "") {
      setErrorSubjectMessage("Subject is Required");
    } else if (data.Message === "") {
      setErrorMessage("Message is Required");
    } else if (!isScript.test(data?.Subject) && !isScript.test(data?.Message)
    ) {
      setErrorSubjectMessage("Only letters, numbers, and spaces are allowed.");
      setErrorMessage("Only letters, numbers, and spaces are allowed.");
    } else if (!isScript.test(data?.Subject)) {
      setErrorSubjectMessage("Only letters, numbers, and spaces are allowed.");
    } else if (!isScript.test(data?.Message)) {
      setErrorMessage("Only letters, numbers, and spaces are allowed.");
    } else {
      return true;
    }
  };

  const handleSubmit = () => {

    if (validate() && errorFile === "") {
      const formData = new FormData();
      formData.append("Subject", data.Subject);
      formData.append("Message", data.Message);
      formData.append("FileName", file);

      dispatch(postReportIssue(formData)).then((result: any) => {
        if (result?.payload?.isSuccess === true) {
          toast.success("Saved Successfully");
          
          setData({
            Subject: "",
            Message: "",
          });
          setFile("");
          setErrorFile('');
          setFileName('');
          setErrorSubjectMessage('');
          setErrorMessage("");

          props.setShow(false);
          props.setSuccessShow(true);
        }
      });
    }
  };

  const inputFileRef: any = React.useRef();
  // const onFileChangeCapture = (e: any) => {
  //   e.preventDefault();
  //   setFileName(e.target.files[0]?.name);
  //   setFile(e?.target?.files[0]);
  // };

  const onFileChangeCapture = (e: any) => {
    e.preventDefault();
    const file = e.target.files?.[0];
    const maxFileSize = 15000000;
    if (file.size >= maxFileSize) {
      setErrorFile(
        "Invalid file size. Please upload maximum 15MB file."
      );
      return;
    }

    if (file && allowedTypes.includes(file.type)) {
      setFileName(file.name);
      setFile(file);
      setErrorFile("");
    } else {
      setErrorFile(
        "Invalid file type. Please upload a Word document, PDF, or image file."
      );
    }
  };
  const onBtnClick = () => {
    inputFileRef?.current.click();
  };

  const handleCloseModal = () => {
    setData({
      Subject: "",
      Message: "",
    });
    setErrorFile('');
    setFile('');
    setFileName('');
    setErrorSubjectMessage('');
    setErrorMessage("");

    props.handleClose();
  }

  return (
    <Modal
      centered={true}
      show={props.show}
      onHide={handleCloseModal}
      className="custom_modal"
    >
      <Modal.Header className="custom_modal_header">
        <Modal.Title className="custom_modal_header_title">
          <div className="custom_modal_header_title_left">{props?.beforeRegistration ? "Contact Us" : "Report Issue"}</div>
          <div className="custom_modal_header_title_right">
            <Button variant="link" onClick={handleCloseModal}>
              <i className="fa-solid fa-times"></i>
            </Button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom_modal_body">
        <p>
          Help us serve you better: Provide detailed description of the issue.
        </p>
        <Form className="form_box">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              onChange={(e) => {
                setErrorSubjectMessage("");
                setData((data) => ({ ...data, Subject: e.target.value }));
              }}
            />
            {errorSubjectMessage && (
              <p className="error-validation">{errorSubjectMessage}</p>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={(e) => {
                setErrorMessage("");
                setData((data) => ({ ...data, Message: e.target.value }));
              }}
            />
            {errorMessage && <p className="error-validation">{errorMessage}</p>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
            <Form.Label>File Attachment</Form.Label>
            <div>
              <input
                type="file"
                ref={inputFileRef}
                onChangeCapture={onFileChangeCapture}
                style={{ display: "none" }}
                accept=".pdf"
              />
              <a href="#" className="text-link" onClick={onBtnClick}>
                <i className="fa-solid fa-paperclip"></i> Attach a file
              </a>
              <span> (Up to 15MB)</span>
              <br></br>
              <span>{fileName}</span>
            </div>
            {errorFile && <p className="error-validation">{errorFile}</p>}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="custom_modal_footer">
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
        <Button variant="secondary" onClick={handleCloseModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ReportIssue;
