import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import { deleteReturnOrders, getViewHistorySummary } from "../../../../store/api/apiSlice";
import { useAppDispatch } from "../../../../store/hooks";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ViewHistoryExport from "./viewHistoryExport";
import {
  COLUMN_CONFIG_REPORT_NAMES,
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { getTableFields } from "../../../../helpers/getTableFields";
import { useSelector } from "react-redux";
import moment from "moment";
import getFiles from "../../../shared/csvDownload/csvDownload";
import GlobalPopup from "../../../shared/GlobalPopup/globalPopup";
import { useUserSettings } from "../../../../helpers/hooks/useUserSettings";
import { showConfigColumns } from "../../../../helpers/getSortedVisibleCols";

function ViewHistory() {
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const [showDeletePopup, setDeletePopup] = useState(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "ViewHistory.csv",
    URL: "ReturnOrder/GetReturnOrders",
    filterOptions: [],
    startDate: "",
    endDate: "",
  });
  const [gridApi, setGridApi] = useState<any>(null);
  const columnItems = [
    {
      headerName: "Kits Order#",
      field: "orderNumber",
      tooltipField: "orderNumber",
      headerTooltip: "Kits Order#",
      tooltipShowDelay: 0,
      hide: false,
      comparator: customComparator,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      type: gridAlignment.LEFT,
      valueFormatter: function (params: any) {
        if (params.data.status === "Inventory Only") {
          return "-";
        } else {
          return params.value;
        }
      },
    },
    {
      headerName: "BNS ACCOUNT NAME",
      field: "bnsAccountName",
      tooltipField: "bnsAccountName",
      headerTooltip: "BNS ACCOUNT NAME",
      tooltipShowDelay: 0,
      hide: false,
      comparator: customComparator,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "QTY. OF SHIPPING LABELS",
      field: "shippingLabelQuantity",
      headerTooltip: "QTY. OF SHIPPING LABELS",
      tooltipShowDelay: 0,
      hide: false,
      comparator: customComparator,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      type: gridAlignment.RIGHT,
      valueFormatter: function (params: any) {
        if (
          params.data.status === "Inventory Only" ||
          params.value === null
        ) {
          return "-";
        } else {
          return params.value;
        }
      },
    },
    {
      headerName: "QTY. OF TAMPER-EVIDENT BAGS",
      field: "tamperEvidentBagQuantity",
      headerTooltip: "QTY. OF TAMPER-EVIDENT BAGS",
      tooltipShowDelay: 0,
      hide: false,
      comparator: customComparator,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      type: gridAlignment.RIGHT,
      valueFormatter: function (params: any) {
        if (
          params.data.status === "Inventory Only" ||
          params.value === null
        ) {
          return "-";
        } else {
          return params.value;
        }
      },
    },
    {
      headerName: "TOTAL INVENTORY",
      field: "totalProducts",
      headerTooltip: "TOTAL INVENTORY",
      tooltipShowDelay: 0,
      hide: false,
      comparator: customComparator,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],

      type: gridAlignment.RIGHT,
    },
    {
      headerName: "LAST Modified On",
      field: "lastModifiedOn",
      headerTooltip: "LAST Modified On",
      tooltipShowDelay: 0,
      hide: false,
      comparator: customComparator,
      filter: "agDateColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      valueFormatter: function (params: any) {
        if (params.value === null) {
          return "";
        } else {
          return formatDatePipe(params.value);
        }
      },
      menuTabs: ["filterMenuTab"],
      type: gridAlignment.LEFT,
    },
    {
      headerName: "STATUS",
      field: "status",
      headerTooltip: "STATUS",
      tooltipShowDelay: 0,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      hide: false,
      comparator: customComparator,
      cellRenderer: function (params: any) {
        let tmpClass: any = "";
        if (params.data.status === "Shipped") {
          tmpClass = "shipped";
        }
        if (params.data.status === "Submitted") {
          tmpClass = "created";
        }
        if (params.data.status === "Pending") {
          tmpClass = "pending";
        }
        if (params.data.status === "Draft") {
          tmpClass = "draft";
        }
        if (params.data.status === "Inventory Only") {
          tmpClass = "inventory";
        }
        return (
          <div className="status">
            <div className={tmpClass}>{params.data.status}</div>
            {params.data.status === "Draft" ? (
              <>
                <span className="icon-edit-gray">
                  {" "}
                  <i
                    className="fa fa-pencil"
                    aria-hidden="true"
                    onClick={() => onEditClick(params.data)}
                  ></i>
                </span>
                <span
                  className="icon-edit-gray"
                  onClick={() => setDeletePopup(params?.data?.id)}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </span>
              </>
            ) : (
              <span className="icon-edit-gray">
                <i
                  className="fa fa-eye"
                  aria-hidden="true"
                  onClick={() => onEyeClick(params.data)}
                ></i>
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const onEditClick = (param: any) => {
    navigate("/services/start-Inventoried-return/" + param.id);
  };

  const onEyeClick = (param: any) => {
    navigate("/services/start-Inventoried-return/" + param.id);
  };

  const { id, dbColumnConfig } = useUserSettings(COLUMN_CONFIG_REPORT_NAMES?.InventoriedReturnHistory);
  const [colDefs, setColDefs] = useState(() => showConfigColumns(columnItems, dbColumnConfig));

  function customComparator() {
    return 0; //means no comparing and no sorting client side
  }

  const onGridReady = (params: any) => {
    setTimeout(() => {
      params.api.sizeColumnsToFit();
    }, 100);
    setGridApi(params.api);
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();

    toast.success("Saved Successfully");
    setColDefs(columnValues);
    myRef?.current.handleClose();
  };

  const onKitFilterChange = (e: any) => {
    const monthsAgo = parseInt(e.value) || 12;
    let d = new Date();
    let end = d.toLocaleDateString();
    d.setMonth(d.getMonth() - monthsAgo);
    let start = d.toLocaleDateString();

    updateGridData("startDate", start);
    updateGridData("endDate", end);
  };

  const getSummary = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              PageSize: gridData.pageSize,
              PageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              GlobalSearch: gridData.globalSearch,
              IsForExcel: gridData.isForExcel,
              StartDate: gridData.startDate
                ? moment
                  .utc(gridData.startDate, "MM/DD/YYYY")
                  .format("YYYY-MM-DD")
                : null,
              EndDate: gridData.endDate
                ? moment
                  .utc(gridData.endDate, "MM/DD/YYYY")
                  .format("YYYY-MM-DD")
                : null,
              SortField:
                gridData.sortField === "lastModifiedOn"
                  ? "modifiedOn"
                  : gridData.sortField,
              SortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(getViewHistorySummary(data)).then((result: any) => {
              if (result?.payload?.data?.returnOrders?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.returnOrders || [],
                  rowCount: result?.payload?.data?.count || 0,
                });
              }
              updateGridData("totalRows", result?.payload?.data?.count);
              updateGridData("rows", result?.payload?.data?.returnOrders);
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        getSummary();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      getSummary();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
    gridData.startDate,
    gridData.endDate,
  ]);

  useEffect(() => {
    if (gridData.isForExcel === true) {
      ViewHistoryExport(gridData, formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  useEffect(() => {
    if (Object.keys(dbColumnConfig).length > 0)
      setColDefs(showConfigColumns(columnItems, dbColumnConfig));
  }, [dbColumnConfig])

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };
  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };

  const onExport = (e: any) => {
    updateGridData("isForExcel", true);
  };
  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  };

  const handleDeleteHistory = () => {
    dispatch(deleteReturnOrders(showDeletePopup)).then((result: any) => {
      toast.success("Order Deleted Successfully", {
        autoClose: 1000,
        onClose: () => {
          getSummary();
        },
      });
    });
    setDeletePopup(0);
  };

  return (
    <Container fluid>
      <PageTitle
        name="View History"
        buttonName="Start an Inventoried Return"
        routePage="/services/start-Inventoried-return"
        isPrimary={true}
        redirectToRoute={true}
      ></PageTitle>
      <div className="content_body invoice-summary-grid">
        <GridComponent
          dbUserReportId={id}
          dbReportName={COLUMN_CONFIG_REPORT_NAMES?.InventoriedReturnHistory}
          dbColumnConfig={dbColumnConfig}
          defaultColumns={columnItems}
          kitFilter={true}
          kitFilterChange={onKitFilterChange}
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        ></GridComponent>
      </div>
      <GlobalPopup
        show={!!showDeletePopup}
        showImage={false}
        handleClose={() => setDeletePopup(0)}
        handleSave={handleDeleteHistory}
        message="Are you sure you want to delete?"
      />
    </Container>
  );
}
export default ViewHistory;
