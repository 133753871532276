import { toast } from "react-toastify";
import axios from "../../../../services/httpService";
import getToken from "../../../../store/api/api";
import { defaultErrorMessage, formateIds } from "../../../../interface/comman.constant";

async function ViewHistoryExport(props: any, userAccounts: any) {
  const API_URL = axios.Api;
  const INVENTORYAPI = axios.InventoryAPI || "";
  let data = {
    PageNumber: props.page,
    PageSize: props.pageSize.toString(),
    SortField: props.sortField,
    SortDirection: props.sortOrder === "asc" ? 0 : 1,
    IsForExcel: true,
    GlobalSearch: props.globalSearch,
    filterOptions: props.filterOptions,
    CustomerIds: userAccounts,
  };

  try {
    const azureB2C = localStorage.getItem('AzureB2CImpersonate');
    let fixUrl = "";
    fixUrl = API_URL + INVENTORYAPI + props.URL;
    fetch(fixUrl, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (await getToken()),
        ...(((azureB2C) && { UserImpersonated: azureB2C }) || {})
      },
      body: JSON.stringify(data),
    })
      .then(async (response: any) => {
        const b = await response.blob();
        let url = URL.createObjectURL(b);
        let a = document.createElement("a");
        a.href = url;
        a.download = props.csvName;
        a.click();
      })
      .catch((err) => toast.error(defaultErrorMessage));
  } catch (error) { }
}
export default ViewHistoryExport;
