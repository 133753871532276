import { Button, Container, Navbar } from "react-bootstrap";
import Footer from "../layout/footer/footer";
import { useEffect, useState } from "react";
import { hideLoader, showLoader } from "../GlobalLoader";
import { useNavigate } from "react-router-dom";

export const PageNotFound = () => {
  const [showContent, setShowContent] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    showLoader();
    setTimeout(() => {
      setShowContent(true);
      hideLoader(true);
    }, 5000);
  }, []);

  return (
    !showContent ? null :
      <>
        <header className="header" id="header">
          <Navbar expand="lg" className="">
            <Container fluid className="align-items-center ">
              <Navbar.Brand href="/" className="header_logo">
                <img src="/assets/images/logo.png" alt="" className="img-fluid" />
              </Navbar.Brand>
            </Container>
          </Navbar>
        </header>
        <Container fluid>
          <div className="content_body  mt-4">
            <div className="d-flex align-items-center justify-content-center page-not-found-wrapper">
              <div className="icon">
                <i className="fas fa-exclamation-triangle"></i>
              </div>
              <div className="description">
                <h3>Ooops!</h3>
                <h4>404 - PAGE NOT FOUND</h4>
                <p>The page you're looking for couldn't be found.</p>
                <p>Please check the URL or return to the Dashboard.</p>
                <div className="mt-3" style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="primary"
                    className="text-decoration-none"
                    onClick={(e) => { navigate('/') }}
                  >
                    <>
                      <i className="fa-solid fa-arrow-left me-1"></i>
                      Back to Dashboard
                    </>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Footer />
      </>
  );
};

export default PageNotFound;