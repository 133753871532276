import { Button, Container, Navbar } from "react-bootstrap";
import Footer from "../layout/footer/footer";

export const TechnicalFailure = () => {
  function handleContactClick() {
    window.open("https://pharmalogistics.com/contact-us/", "_blank");
  }

  return (
    <>
      <header className="header" id="header">
        <Navbar expand="lg" className="">
          <Container fluid className="align-items-center ">
            <Navbar.Brand href="/" className="header_logo">
              <img src="/assets/images/logo.png" alt="" className="img-fluid" />
            </Navbar.Brand>
          </Container>
        </Navbar>
      </header>
      <Container fluid>
        <div className="content_body  mt-4">
          <div className="d-flex align-items-center justify-content-center page-not-found-wrapper">
            <div className="icon">
              <i className="fas fa-exclamation-triangle"></i>
            </div>
            <div className="description">
              <h4>WE'RE REPAIRING A TECHNICAL ISSUE</h4>
              <p>The Pharma Logistics Customer Portal is currently undergoing maintenance and will be back soon.</p>
              <Button variant="primary" className="mt-4" onClick={handleContactClick}>
                Contact Us
              </Button>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default TechnicalFailure;