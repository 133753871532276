import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import GridTotalCountHeader from "../../../../components/grid/gridTotalCountHeader";
import { useAppDispatch } from "../../../../store/hooks";
import {
  getDisbursementSummary,
  postVerifySummary,
} from "../../../../store/api/apiSlice";
import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import getFiles from "../../../shared/csvDownload/csvDownload";
import { useNavigate } from "react-router-dom";
import {
  COLUMN_CONFIG_REPORT_NAMES,
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { getTableFields } from "../../../../helpers/getTableFields";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useUserSettings } from "../../../../helpers/hooks/useUserSettings";
import { showConfigColumns } from "../../../../helpers/getSortedVisibleCols";

function DisbursementSummary() {
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [gridApi, setGridApi] = useState<any>(null);
  const [isToast, setIsToast] = useState<any>(null);
  const [isVerifiedClick, setIsVerifiedClick] = useState(false);
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "DisbursementSummary.csv",
    URL: "Reports/DisbursementSummary",
    filterOptions: [],
  });

  const columnItems = [
    {
      headerName: "Disbursement ID",
      field: "disbursementId",
      tooltipField: "disbursementId",
      headerTooltip: "Disbursement ID",
      tooltipShowDelay: 0,
      hide: false,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      comparator: customComparator,
      cellStyle: { cursor: "pointer" },
      type: gridAlignment.RIGHT,
      cellRenderer: function (params: any) {
        return (
          <div
            className="text-link"
            onClick={(e) => {
              navigate(
                "/reports/disbursement-details/" + params.data.disbursementId
              );
            }}
          >
            {params.data.disbursementId}
          </div>
        );
      },
    },
    {
      headerName: "Disbursement Date",
      field: "disbursementDate",
      tooltipField: "disbursementDate",
      headerTooltip: "Disbursement Date",
      tooltipShowDelay: 0,
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: false,
      valueFormatter: function (params: any) {
        return formatDatePipe(params.value);
      },
      comparator: customComparator,
    },
    {
      headerName: "Customer Id",
      field: "customerId",
      tooltipField: "customerId",
      headerTooltip: "Customer Id",
      tooltipShowDelay: 0,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Customer Name",
      headerTooltip: "Customer Name",
      tooltipShowDelay: 0,
      field: "customerName",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Order#",
      field: "orderNumber",
      headerTooltip: "Order#",
      tooltipShowDelay: 0,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      field: "transactionType",
      filter: "agTextColumnFilter",
      tooltipField: "transactionType",
      headerTooltip: "Transaction Type",
      tooltipShowDelay: 0,
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      field: "disbursementType",
      tooltipField: "disbursementType",
      headerTooltip: "DisbursementType",
      tooltipShowDelay: 0,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      field: "checkOrWholesalerCreditNumber",
      hide: false,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      comparator: customComparator,
    },
    {
      headerName: "Gross Amount",
      field: "grossAmount",
      hide: false,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Invoice Paydown",
      field: "invoicePaydown",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Net Amount",
      field: "netAmount",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Is Verified",
      field: "isVerified",
      hide: false,
      sortable: false,
      comparator: customComparator,
      suppressHeaderMenuButton: true,
      cellRenderer: (params: any) => {
        return (
          <Button
            variant="primary"
            size="sm"
            className="px-3"
            onClick={(e) => params?.data && verifyButtonClick(e, params?.data)}
            disabled={params.value}
          >
            {params.data.isVerified ? "Verified" : "Verify"}
          </Button>
        );
      },
    },
  ];

  const { id, dbColumnConfig } = useUserSettings(COLUMN_CONFIG_REPORT_NAMES?.DisbursementSummary);
  const [colDefs, setColDefs] = useState(() => showConfigColumns(columnItems, dbColumnConfig));

  function customComparator() {
    return 0; //means no comparing and no sorting client side
  }

  const verifyButtonClick = (e: any, data: any) => {
    e.preventDefault();

    try {
      let requestData = {
        disbursementId: data.disbursementId,
        checkOrWholesalerCreditNumber: data.checkOrWholesalerCreditNumber,
      };
      dispatch(postVerifySummary(requestData)).then((result: any) => {
        if (result.payload.isSuccess === true) {
          toast.success("Saved Successfully");
          setIsVerifiedClick(true);
        }
      });
    } catch (error) { }
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        getSummary();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      getSummary();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
    isVerifiedClick,
  ]);

  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  useEffect(() => {
    if (Object.keys(dbColumnConfig).length > 0)
      setColDefs(showConfigColumns(columnItems, dbColumnConfig));
  }, [dbColumnConfig])

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();

    setIsToast(true);
    setColDefs(columnValues);
    myRef?.current.handleClose();
  };
  // this function defination change after enterprise
  const getSummary = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              pageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              pageSize: gridData.pageSize,
              sortField: gridData.sortField,
              sortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              isForExcel: gridData.isForExcel,
              globalSearch: gridData.globalSearch,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(getDisbursementSummary(data)).then((result: any) => {
              if (result?.payload?.data?.disbursements?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.disbursements,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
              updateGridData(
                "totalAmount",
                CurrencyConverter.format(result?.payload?.data?.totalAmount)
              );
              updateGridData("totalRows", result?.payload?.data?.totalCount);
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) { }
  };

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };

  const onExport = (e: any) => {
    if (gridData.totalRows > 100000) {
      toast.warning(
        "The system limits exports to a maximum of 100,000 records"
      );
    } else {
      updateGridData("isForExcel", true);
    }
  };

  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  };

  return (
    <Container fluid>
      <PageTitle
        name="Disbursement Summary"
        buttonName="Back"
        routePage="/reports/view-all"
      ></PageTitle>
      <div className="content_body">
        <GridTotalCountHeader
          title="Total Disbursements"
          count={gridData.totalAmount}
          description="Disbursements issued for the summary of selected records."
        ></GridTotalCountHeader>
        <GridComponent
          dbUserReportId={id}
          dbReportName={COLUMN_CONFIG_REPORT_NAMES?.DisbursementSummary}
          dbColumnConfig={dbColumnConfig}
          defaultColumns={columnItems}
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
          autoSizeStrategy={{
            type: "fitCellContents",
            colIds: [
              "checkOrWholesalerCreditNumber",
              "customerId",
              "disbursementDate",
              "disbursementDetails",
              "disbursementId",
              "disbursementType",
              "grossAmount",
              "invoicePaydown",
              "isVerified",
              "netAmount",
              "orderNumber",
              "transactionType",
            ],
          }}
        />
      </div>
    </Container>
  );
}
export default DisbursementSummary;
