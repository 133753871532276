import { useEffect } from 'react';
import { useParams} from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';
import { checkExpiryChangepassword } from '../../../store/api/apiSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { b2cPolicies } from '../../../authConfig';

export const ChangePasswordSetup = () => {
    const { instance } = useMsal();

        const { email } = useParams() as { email: string };
        const { id } = useParams() as { id: string };
        const dispatch = useAppDispatch();
        const { t } = useTranslation();
        useEffect(() => {
            setTimeout(() => {
            if(instance) {
                verifyToken()
            }
        }, 2000);
        }, []);
        const   verifyToken = () =>{
           
            try {
                let data = {
                    Email:window.atob(email),
                    id:window.atob(id)
                  };
            dispatch(checkExpiryChangepassword(data)).then(async (result: any) => {
                if(result?.payload?.data?.isValid) {
                    const changePasswordRequest = {
                        authority: b2cPolicies.authorities.changePass.authority,
                        scopes: [],
                        loginHint:window.atob(email) ,
                    };
                       await instance.loginRedirect(changePasswordRequest);
                } else {
                    toast.error(<>{t("LINK_EXPIRE")}</>);
                    setTimeout(() => {
                         instance.logoutRedirect();
                     }, 2000);
                }
               
                
            });
        } catch (error) { }
         
        }


    return (
        <></>
    );
};

export default ChangePasswordSetup;

