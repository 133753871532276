import { useState, useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  COLUMN_CONFIG_REPORT_NAMES,
  formateIds,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import {
  deleteUser,
  getImpersonatedPermissions,
  getImpersonation,
  getUserList,
  saveUserSettings,
  setPermissions,
  updateUserStatus,
} from "../../../../store/api/apiSlice";
import { getTableFields } from "../../../../helpers/getTableFields";
import { useAppDispatch } from "../../../../store/hooks";

import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import downloadFileFromUrl from "../../../shared/csvDownload/fileDownloader";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import getFiles from "../../../shared/csvDownload/csvDownload";
import GlobalPopup from "../../../shared/GlobalPopup/globalPopup";
import { CreateUser } from "./components/createUser";
import { EditUser } from "./components/editUser";
import { setSelectedAccounts } from "../../../../store/slice/hierarchy";
import { hideLoader, showLoader } from "../../../shared/GlobalLoader";
import { usePermission } from "../../../../helpers/hooks/usePermissions";
import { cloneDeep } from "lodash";
import { useUserSettings } from "../../../../helpers/hooks/useUserSettings";
import { showConfigColumns } from "../../../../helpers/getSortedVisibleCols";

function UserManagement() {
  const gridRef = useRef<AgGridReact>(null);
  const userAccounts = useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const myRef = useRef<any>();
  const permissions = usePermission(true);
  const dispatch = useAppDispatch();

  const [gridApi, setGridApi] = useState<any>(null);
  const [show, setShow] = useState("");
  const [editUser, setEditUser] = useState(null);
  const [showDeletePopup, setDeletePopup] = useState(0);
  const [showImpersonatePopup, setImpersonatePopup] = useState<any>(null);
  const [showActivePopup, setActivePopup] = useState<any>(null);
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    isForExcel: false,
    sortField: "",
    csvName: "UserManagement.csv",
    URL: "User/List",
    filterOptions: [],
  });

  const columnItems: any = [
    {
      headerName: "Name",
      field: "fullName",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Email",
      field: "email",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Account",
      field: "account",
      tooltipShowDelay: 0,
      tooltipField: "account",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      sortable: false,
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Group Account",
      field: "groupAccount",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      sortable: false,
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Role",
      field: "custentitY_PORTAL_USER_ROLE",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      suppressMenu: true,
      comparator: customComparator,
      cellRenderer: function (params: any) {
        let role: any = "";
        if (params.data.custentitY_PORTAL_USER_ROLE === 1) {
          role = "Pharmacy Staff";
        } else if (params.data.custentitY_PORTAL_USER_ROLE === 2) {
          role = "Management";
        } else if (params.data.custentitY_PORTAL_USER_ROLE === 3) {
          role = "Admin";
        } else if (params.data.custentitY_PORTAL_USER_ROLE === 4) {
          role = "Internal Admin";
        }
        else {
          role = "";
        }
        return <div>{role}</div>;
      },
    },
    {
      headerName: "Status",
      field: "status",
      sortable: false,
      suppressMenu: true,
      hide: false,
      cellStyle: { textAlign: "center" },
      comparator: customComparator,
      cellRenderer: function (params: any) {
        return (
          <div
            className="status"
            style={{ cursor: "pointer" }}
            onClick={() => setActivePopup(params?.data)}
          >
            {params?.data?.status === "Active" ? (
              <div className={"created"}>Active</div>
            ) : (
              <div className={"destroyed"}>Inactive</div>
            )}
          </div>
        );
      },
    },
    ...permissions?.['role'] === 4 ? [{
      headerName: "Impersonate",
      field: "Impersonate",
      sortable: false,
      hide: false,
      suppressMenu: true,
      comparator: customComparator,
      cellStyle: { textAlign: "center" },
      cellRenderer: function (params: any) {
        let message = params?.data?.status === "Inactive" ?
          "Inactive user cannot be impersonated" :
          params?.data?.isImpersonationRestricted == true ?
            "The newly added user cannot be impersonated" : "";

        return params?.data?.status === "Active" && params?.data?.isImpersonationRestricted === false ? (
          <span
            className="icon-edit-gray"
            onClick={() => {
              setImpersonatePopup(params?.data || null);
            }}
          >
            <i className="fa fa-user-secret" aria-hidden="true"></i>
          </span>
        ) : (
          <OverlayTrigger
            overlay={
              <Tooltip id="t-1">{message}</Tooltip>
            }
          >
            <span className="icon-edit-gray">
              <i className="fa fa-user-secret" aria-hidden="true"></i>
            </span>
          </OverlayTrigger>
        );
      },
    }] : [],
    {
      headerName: "Actions",
      field: "Actions",
      sortable: false,
      hide: false,
      suppressMenu: true,
      comparator: customComparator,
      cellStyle: { textAlign: "center" },
      cellRenderer: function (params: any) {
        return (
          params?.data?.isImpersonationRestricted === false ?
            (
              <>
                <span
                  className="icon-edit-gray"
                  onClick={() => handleEditUser(params?.data)}
                >
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </span>

                <span
                  className="icon-edit-gray"
                  onClick={() => setDeletePopup(params?.data?.id)}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </span>
              </>
            ) :
            (
              <>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="edit-tooltip-restricted">The newly added user cannot be edited</Tooltip>
                  }
                >
                  <span className="icon-edit-gray">
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </span>
                </OverlayTrigger>

                <OverlayTrigger
                  overlay={
                    <Tooltip id="delete-tooltip-restricted">The newly added user cannot be deleted</Tooltip>
                  }
                >
                  <span className="icon-edit-gray">
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </span>
                </OverlayTrigger>
              </>
            )
        );
      },
    },
  ];

  const { id, dbColumnConfig } = useUserSettings(COLUMN_CONFIG_REPORT_NAMES?.UserManagement);
  const [colDefs, setColDefs] = useState(() => showConfigColumns(columnItems, dbColumnConfig));

  function customComparator() {
    return 0; //means no comparing and no sorting
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();

    toast.success("Saved Successfully");
    setColDefs(cloneDeep(columnValues));
    myRef?.current.handleClose();

    if (gridRef.current) {
      gridRef.current.api.autoSizeAllColumns();
    }
  };

  const userManagement = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              PageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              PageSize: gridData.pageSize,
              SortField: gridData.sortField,
              sortDirection: parseInt(gridData.sortOrder || "0"),
              IsForExcel: gridData.isForExcel,
              GlobalSearch: gridData.globalSearch,
              filterOptions: getTableFields(params.request.filterModel),
              customerIds: formateIds(userAccounts),
            };

            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );

            dispatch(getUserList(data)).then((result: any) => {
              params.success({
                rowData: result?.payload?.data?.users || [],
                rowCount: result?.payload?.data?.totalCount || 0,
              });

              updateGridData(
                "totalRows",
                result?.payload?.data?.totalCount || 0
              );
            });
          },
        };

        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) { }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };

  const onExport = (e: any) => {
    updateGridData("isForExcel", true);
  };

  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };

  const handleEditUser = (editUser: any) => {
    setEditUser(editUser);

    setShow("edit");
  };

  const handleUserModalClose = () => {
    editUser && setEditUser(null);
    setShow("");
  };

  const handleModalShow = () => setShow("create");

  const handleUserStatus = () => {
    const body = {
      status: !(showActivePopup?.status === "Active"),
      id: showActivePopup?.id,
    };
    dispatch(updateUserStatus(body)).then((result: any) => {
      toast.success("User Status Changed Successfully", {
        autoClose: 2000,
        onClose: () => {
          userManagement();
        },
      });
    });

    setActivePopup(null);
  };

  const handleDeleteUser = () => {
    dispatch(deleteUser(showDeletePopup)).then((result: any) => {
      toast.success("User Deleted Successfully", {
        autoClose: 2000,
        onClose: () => {
          userManagement();
        },
      });
    });

    setDeletePopup(0);
  };

  const handleImpersonate = () => {
    showLoader();
    setImpersonatePopup(null);

    dispatch(getImpersonation({ email: showImpersonatePopup?.email })).then(
      (result: any) => {
        if (Object.keys(result?.error || {}).length === 0) {
          localStorage.setItem("AzureB2CImpersonate", result?.payload);
          localStorage.setItem(
            "ImpersonateUser",
            showImpersonatePopup?.fullName || ""
          );

          dispatch(getImpersonatedPermissions({})).then((result: any) => {
            if (Object.keys(result?.error || {}).length === 0) {
              dispatch(setSelectedAccounts([]));
              setPermissions(result?.payload?.data);
              localStorage.setItem(
                "permissions",
                JSON.stringify(result?.payload?.data || {})
              );

              toast.success("User Impersonation Started", {
                autoClose: 2000,
                onClose: () => {
                  hideLoader();
                  window.location.href = "/";
                },
              });
            } else {
              hideLoader();
              localStorage.removeItem("AzureB2CImpersonate");
              localStorage.removeItem("ImpersonateUser");
            }
          });
        } else {
          hideLoader();
        }
      }
    );
  };

  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  };

  const onSortChanged = ({ api: { sortController } }: { api: { sortController: any }; }): void => {
    console.log("onSortChanged");

    const sortModel: any[] = sortController.getSortModel();

    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];

      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    } else {

      updateGridData("sortOrder", "");
      updateGridData("sortField", "");
    }
  };

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        userManagement();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      userManagement();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
  ]);

  useEffect(() => {
    if (Object.keys(dbColumnConfig).length > 0) {
      console.log('useEffect', columnItems, dbColumnConfig);
      setColDefs(showConfigColumns(columnItems, dbColumnConfig));
    }
  }, [dbColumnConfig])

  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(
        gridData,
        (visibleColumnNames(colDefs) || []).filter((n) => n === null),
        formateIds(userAccounts)
      );
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  return (
    <Container fluid>
      <PageTitle
        name="User Management"
        customRightSection={
          <Button variant="primary" onClick={handleModalShow}>
            <i className="fa-solid fa-user me-1"></i> Add User
          </Button>
        }
      />

      <div className="content_body grid-reset">
        <GridComponent
          dbUserReportId={id}
          dbReportName={COLUMN_CONFIG_REPORT_NAMES?.UserManagement}
          dbColumnConfig={dbColumnConfig}
          defaultColumns={columnItems}
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          shortOrder={gridData.sortOrder}
          autoSizeStrategy={{ type: "fitGridWidth" }}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        />
      </div>

      <CreateUser
        handleModalClose={handleUserModalClose}
        show={show}
        refreshList={userManagement}
      />
      <EditUser
        handleModalClose={handleUserModalClose}
        show={show}
        userData={editUser}
        refreshList={userManagement}
      />

      <GlobalPopup
        show={!!showDeletePopup}
        showImage={false}
        handleClose={() => setDeletePopup(0)}
        handleSave={handleDeleteUser}
        message="Are you sure you want to delete?"
      />

      <GlobalPopup
        show={!!showActivePopup}
        showImage={false}
        handleClose={() => setActivePopup(null)}
        handleSave={handleUserStatus}
        message={`Are you sure you want to ${showActivePopup?.status === "Active" ? "de-activate" : "activate"
          }?`}
      />

      <GlobalPopup
        show={!!showImpersonatePopup}
        showImage={false}
        handleClose={() => setImpersonatePopup(null)}
        handleSave={handleImpersonate}
        message={`Are you sure you want to impersonate ${showImpersonatePopup?.fullName}?`}
      />
    </Container>
  );
}

export default UserManagement;
