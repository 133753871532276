import { cloneDeep } from "lodash";

export function showConfigColumns(columnItems: any, dbColumnConfig: any) {
  let newColumnItems = cloneDeep(columnItems);

  if ((dbColumnConfig?.columns || []).length === 0) return newColumnItems;

  let sortedArray = ([...dbColumnConfig?.columns]).sort((a: any, b: any) => (a.order - b.order));

  let newColItems: any = [];
  newColumnItems.forEach((element: any) => {
    let hide = true;
    (sortedArray).forEach((col: any) => {
      if (col.name === element.field) {
        hide = false;
        // newColItems.push(element);
      }
    });
    element.hide = hide;
  });
  console.log("IN", newColumnItems);
  return newColumnItems;
}