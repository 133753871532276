import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import GridHeader from "./gridHeader";
import GridFooter from "./gridFooter";
import CustomNoRowsOverlay from "./cutomNoRowsOverlay";
import "ag-grid-enterprise";
import { ModuleRegistry } from "@ag-grid-community/core";
import {
  LicenseManager,
  ServerSideRowModelModule,
  MenuModule,
  ColumnsToolPanelModule,
} from "ag-grid-enterprise";
import { defaultNoRecordFound } from "../../interface/comman.constant";
import { cloneDeep } from "lodash";
import { saveUserSettings } from "../../store/api/apiSlice";
import { useAppDispatch } from "../../store/hooks";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-059196}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Returns_R_Us,_LLC_d/b/a__Pharma_Logistics_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Pharma_Logistics_Customer_Portal}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Pharma_Logistics_Customer_Portal}_need_to_be_licensed___{Pharma_Logistics_Customer_Portal}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{26_May_2025}____[v3]_[01]_MTc0ODIxNDAwMDAwMA==5d2a2ff83b6054b978f113b6196755c7"
);
ModuleRegistry.registerModules([
  ColumnsToolPanelModule,
  MenuModule,
  ServerSideRowModelModule,
]);

const GridComponent = React.forwardRef((props: any, ref) => {
  let timer: any;
  const [gridKey, setGridKey] = useState<string>(`grid-key-${Math.random()}`);
  const [columnValues, SetColumnValues]: any = useState();
  const dispatch = useAppDispatch();

  const noRowsOverlayComponent = useMemo(() => {
    return CustomNoRowsOverlay;
  }, []);
  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () => <span> {defaultNoRecordFound}</span>,
    };
  }, []);
  const [finalHeight, setFinalHeight] = useState<any>(400);
  const handleResize = () => {
    clearTimeout(timer);

    timer = setTimeout(function () {
      var header = document.getElementsByClassName("header")[0]?.clientHeight;
      var headerTitle =
        document.getElementsByClassName("content_header")[0]?.clientHeight;
      var gridSearch =
        document.getElementsByClassName("content_body_head")[0]?.clientHeight;
      var gridPagination =
        document.getElementsByClassName("ag-paging-panel")[0]?.clientHeight;
      var footer = document.getElementsByClassName("footer")[0]?.clientHeight;
      var totalHeight =
        header + headerTitle + gridSearch + gridPagination + footer;
      var finalHeight = window.innerHeight - totalHeight;
      setFinalHeight(finalHeight);
    }, 500);
  };

  const popupSave = (event: any, columnValues: any, isResetToDefault = false) => {
    event.preventDefault();

    const visibleColumns: any = [];
    let i = 0;

    (columnValues || []).forEach((e: any) => {
      if (e?.hide === false) {
        visibleColumns.push({ order: i + 1, name: e?.field });
        i++;
      }
    });

    setUserSettings({
      id: props?.dbUserReportId,
      gridSettings: {
        [`${props?.dbReportName}`]: {
          ...props?.dbColumnConfig,
          columns: isResetToDefault ? [] : [...visibleColumns],
          isResetToDefault
        }
      }
    });

    props.popupSave(event, columnValues);
    SetColumnValues(columnValues);
  };

  const onGridSizeChanged = useCallback(
    (params: any) => {
      if (props?.autoSizeStrategy) return;

      // get the current grids width
      var gridWidth = document.querySelector(".ag-body-viewport")!.clientWidth;
      // keep track of which columns to hide/show
      var columnsToShow = [];
      var columnsToHide = [];
      // iterate over all columns (visible or not) and work out
      // now many columns can fit (based on their minWidth)
      var totalColsWidth = 0;
      // var allColumns = params.api.getColumns();
      var allColumns = columnValues;
      if (allColumns && allColumns.length > 0) {
        for (var i = 0; i < allColumns.length; i++) {
          var column = allColumns[i];
          totalColsWidth += column.getMinWidth() || 0;
          if (totalColsWidth > gridWidth) {
            columnsToHide.push(column.getColId());
          } else {
            columnsToShow.push(column.getColId());
          }
        }
      }
      // show/hide columns based on current grid width
      params.api.setColumnsVisible(columnsToShow, true);
      params.api.setColumnsVisible(columnsToHide, false);
      // wait until columns stopped moving and fill out
      // any available space to ensure there are no gaps
      window.setTimeout(() => {
        params.api.sizeColumnsToFit();
      }, 10);
    },
    [window]
  );

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", () => {
      handleResize();
    });
    return () =>
      window.removeEventListener("resize", () => {
        handleResize();
      });
  }, [finalHeight]);
  const gridStyle = useMemo(
    () => ({
      height: props.isAutoHeight ? "100%" : finalHeight + "px",
      width: "100%",
    }),
    [finalHeight]
  );

  const setUserSettings = (params: any) => {

    dispatch(saveUserSettings(params)).then((result: any) => {
      console.log(result);
    });
  }

  const onGridReady = (params: any) => {
    const filterModel = cloneDeep(props?.dbColumnConfig?.filters || {});
    const sortModel = [{
      colId: (props?.dbColumnConfig?.sortField || null),
      sort: (props?.dbColumnConfig?.sortDirection) === 0 ? "asc" : "desc"
    }]

    if (props?.dbColumnConfig?.sortField) {
      params.api.applyColumnState({ state: sortModel });
    }

    if (Object.keys(filterModel).length > 0) {

      Object.keys(filterModel).map((key) => {
        if (filterModel?.[key]?.filterType === "number") {
          filterModel[key].filter = parseFloat(filterModel?.[key]?.filter || '0');

          if (filterModel?.[key]?.filterTo) filterModel[key].filterTo = parseFloat(filterModel?.[key]?.filterTo || '0');
        }

        if (filterModel?.[key]?.filterType === "date" && (["inRange", "lessThan", "greaterthan"]).includes(filterModel?.[key]?.type)) {
          if (filterModel?.[key]?.filter) filterModel[key].dateFrom = (filterModel?.[key]?.filter || '').toString();

          if (filterModel?.[key]?.filterTo) filterModel[key].dateTo = (filterModel?.[key]?.filterTo || '').toString();
        }
      })

      params.api.setFilterModel(filterModel);
    }


    props.onGridReady && props.onGridReady(params);
  };

  const onFilterChanged = (params: any) => {
    let filterModel = cloneDeep(params.api.getFilterModel() || {});

    const filterFinal: any = {};

    Object.keys(filterModel).map((key) => {
      if (filterModel?.[key]?.filterType === "number") {
        filterModel[key].filter = (filterModel?.[key]?.filter || '').toString();

        if (filterModel?.[key]?.filterTo) filterModel[key].filterTo = (filterModel?.[key]?.filterTo || '').toString();
      }

      if (filterModel?.[key]?.filterType === "date") {
        if (filterModel?.[key]?.dateFrom) filterModel[key].filter = (filterModel?.[key]?.dateFrom || '').toString();

        if (filterModel?.[key]?.dateTo) filterModel[key].filterTo = (filterModel?.[key]?.dateTo || '').toString();
      }

      filterFinal[key] = {
        filterType: filterModel?.[key]?.filterType,
        type: filterModel?.[key]?.type,
        filter: filterModel?.[key]?.filter,
        ...(filterModel?.[key]?.filterTo && {
          filterTo: filterModel?.[key]?.filterTo
        })
      };
    })

    const newSettings = {
      id: props?.dbUserReportId,
      gridSettings: {
        [`${props?.dbReportName}`]: {
          ...props?.dbColumnConfig,
          filters: filterFinal
        }
      }
    };

    setUserSettings(newSettings);
  };

  const onSortChanged = (params: any) => {
    const sortModel: any[] = params.api.sortController.getSortModel();

    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];

      const payload = {
        id: props?.dbUserReportId,
        gridSettings: {
          [`${props?.dbReportName}`]: {
            ...props?.dbColumnConfig,
            sortField: colId,
            sortDirection: sort === "asc" ? 0 : 1
          }
        }
      };

      setUserSettings(payload);
    } else {
      const payload = {
        id: props?.dbUserReportId,
        gridSettings: {
          [`${props?.dbReportName}`]: {
            ...props?.dbColumnConfig,
            sortField: null,
            sortDirection: null
          }
        }
      };

      setUserSettings(payload);
    }

    props.sortChange && props.sortChange(params);
  };

  const onColumnMoved = (params: any) => {

    if (params.finished) {
      const columnOrder = params.columnApi.getAllDisplayedColumns();

      const columns = columnOrder.map((e: any, i: any) => {
        return {
          order: i + 1,
          name: e?.colId
        }
      });

      const newSettings = {
        id: props?.dbUserReportId,
        gridSettings: {
          [`${props?.dbReportName}`]: {
            ...props?.dbColumnConfig,
            columns: cloneDeep(columns)
          }
        }
      };

      setUserSettings(newSettings);
    }
  };

  const isDesktop = window?.innerWidth > 1200;

  //const gridStyle = useMemo(() => ({ }), []);
  return (
    <>
      <div className="ag-theme-quartz">
        {!props.hideheader ? (
          <GridHeader
            defaultColumns={props.defaultColumns}
            kitFilter={props.kitFilter}
            showProductTour={props.showProductTour}
            kitFilterChange={props.kitFilterChange}
            ref={ref}
            gridRef={props.gridRef}
            columnDefs={props.colDefs}
            handleSave={popupSave}
            searchId={props.searchId}
            globalSearch={props.globalSearch}
            onExport={props.onExport}
            gridfilterbutton={props.gridfilterbutton}
            hidesearch={props.hidesearch}
            onClearFilter={props.onClearFilter}
          ></GridHeader>
        ) : (
          <></>
        )}
        <div
          style={gridStyle}
          className={
            props.isModalGrid === true
              ? "content_body_inner ag-grid-wrapper"
              : "content_body_inner ag-grid-wrapper"
          }
        >
          <AgGridReact
            key={gridKey}
            ref={props.gridRef}
            columnDefs={props.colDefs}
            defaultColDef={props.defaultColDef}
            suppressExcelExport={true}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            rowSelection={"multiple"}
            rowGroupPanelShow={"always"}
            pivotPanelShow={"always"}
            rowModelType="serverSide"
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={props.paginationPageSize}
            paginationPageSizeSelector={props.paginationPageSizeSelector}
            onSortChanged={onSortChanged}
            onColumnMoved={onColumnMoved}
            onFilterChanged={onFilterChanged}
            onPaginationChanged={props.paginationChange}
            cacheBlockSize={props.paginationPageSize}
            maxBlocksInCache={1}
            {...(isDesktop
              ? {
                onGridSizeChanged: onGridSizeChanged,
                autoSizeStrategy: props.autoSizeStrategy
                  ? props.autoSizeStrategy
                  : { type: "fitGridWidth" },
              }
              : {})}
            noRowsOverlayComponent={noRowsOverlayComponent}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            pinnedBottomRowData={
              props.pinnedBottomRowData ? props.pinnedBottomRowData : []
            }
          // domLayout="autoHeight"
          />
        </div>
        {/* <GridFooter
          gridData={props.gridData}
          setGridData={props.setGridData}
        ></GridFooter> */}
      </div>
    </>
  );
});

export default GridComponent;
