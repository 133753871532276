import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useCallback, useEffect } from "react";
import { Container } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import moment from "moment";
import { useAppDispatch } from "../../../../store/hooks";
import { getDisbursementDetails } from "../../../../store/api/apiSlice";
import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import getFiles from "../../../shared/csvDownload/csvDownload";
import { useParams } from "react-router-dom";
import {
  COLUMN_CONFIG_REPORT_NAMES,
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getTableFields } from "../../../../helpers/getTableFields";
import { useUserSettings } from "../../../../helpers/hooks/useUserSettings";
import { showConfigColumns } from "../../../../helpers/getSortedVisibleCols";

function DisbursementDetails() {
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const dispatch = useAppDispatch();
  const queryParams: any = useParams();
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "DisbursementDetail.csv",
    URL: "Reports/DisbursementDetail",
    DisbursementId: queryParams.id,
    filterOptions: [],
  });
  const columnItems = [
    {
      headerName: "Disbursement ID",
      headerTooltip: "Disbursement ID",
      tooltipShowDelay: 0,
      field: "disbursementId",
      hide: false,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Disbursement Date",
      headerTooltip: "Disbursement Date",
      tooltipShowDelay: 0,
      field: "disbursementDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: false,
      valueFormatter: function (params: any) {
        return formatDatePipe(params.value);
      },
      comparator: customComparator,
    },
    {
      headerName: "Customer ID",
      headerTooltip: "Customer ID",
      tooltipShowDelay: 0,
      field: "customerId",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Order#",
      headerTooltip: "Order#",
      tooltipShowDelay: 0,
      field: "orderNumber",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Line ID",
      headerTooltip: "Line ID",
      tooltipShowDelay: 0,
      field: "lineId",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "NDC",
      headerTooltip: "NDC",
      tooltipShowDelay: 0,
      field: "ndc",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Product",
      headerTooltip: "Product",
      tooltipShowDelay: 0,
      field: "product",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Manufacturer",
      headerTooltip: "Manufacturer",
      tooltipShowDelay: 0,
      field: "manufacturer",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "ERV",
      headerTooltip: "ERV",
      tooltipShowDelay: 0,
      field: "erv",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "ARV",
      headerTooltip: "ARV",
      tooltipShowDelay: 0,
      field: "arv",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
  ];

  const { id, dbColumnConfig } = useUserSettings(COLUMN_CONFIG_REPORT_NAMES?.DisbursementDetail);
  const [colDefs, setColDefs] = useState(() => showConfigColumns(columnItems, dbColumnConfig));

  function customComparator() {
    return 0; //means no comparing and no sorting
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();

    toast.success("Saved Successfully");
    setColDefs(columnValues);
    myRef?.current.handleClose();
    if (gridRef.current) {
      gridRef.current.api.autoSizeAllColumns();
    }
  };

  const DisbursementDetails = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              DisbursementId: queryParams.id,
              PageSize: gridData.pageSize,
              PageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              SortField: gridData.sortField,
              SortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              IsForExcel: gridData.isForExcel,
              GlobalSearch: gridData.globalSearch,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(getDisbursementDetails(data)).then((result: any) => {
              if (result?.payload?.data?.disbursementDetails?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.disbursementDetails,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
              updateGridData("totalRows", result?.payload?.data?.totalCount);
              updateGridData(
                "totalAmount",
                CurrencyConverter.format(result?.payload?.data?.totalAmount)
              );
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        if (queryParams && queryParams.id && queryParams.id > 0) {
          DisbursementDetails();
        }
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      if (queryParams && queryParams.id && queryParams.id > 0) {
        DisbursementDetails();
      }
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
  ]);

  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  useEffect(() => {
    if (Object.keys(dbColumnConfig).length > 0)
      setColDefs(showConfigColumns(columnItems, dbColumnConfig));
  }, [dbColumnConfig])

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };
  const onExport = (e: any) => {
    updateGridData("isForExcel", true);
  };
  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  }
  return (
    <Container fluid>
      <PageTitle
        name="Disbursement Details"
        buttonName="Back"
        routePage="/reports/disbursement-summary"
      ></PageTitle>
      <div className="content_body grid-reset">
        <GridComponent
          dbUserReportId={id}
          dbReportName={COLUMN_CONFIG_REPORT_NAMES?.DisbursementDetail}
          dbColumnConfig={dbColumnConfig}
          defaultColumns={columnItems}
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          shortOrder={gridData.sortOrder}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        // autoSizeStrategy={{
        //   type: "fitCellContents",
        //   colIds: [
        //     "disbursementId",
        //     "disbursementDate",
        //     "customerId",
        //     "orderNumber",
        //     "lineId",
        //     "ndc",
        //     "product",
        //     "manufacturer",
        //     "erv",
        //     "arv",
        //   ],
        // }}
        ></GridComponent>
      </div>
    </Container>
  );
}
export default DisbursementDetails;
