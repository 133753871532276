import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "../../../../services/httpService";
import getToken from "../../../../store/api/api";
import DragDropComponent from "./dragDropComponent";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { defaultErrorMessage } from "../../../../interface/comman.constant";

function ImportInventoryModal(props: any) {
  const [isImport, setIsImport] = useState(false);
  const [file, setFile]: any = useState();
  const [fileName, setFileName] = useState();
  const navigate = useNavigate();
  const API_URL = axios.Api;
  const INVENTORYAPI = axios.InventoryAPI || "";
  const [importData, setImportData]: any = useState();
  const [showError, setShowError]: any = useState();

  const importButtonClick = () => {
    if (file !== undefined && showError === false) {
      //POST API call
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(
          API_URL +
          INVENTORYAPI +
          `ReturnOrder/` +
          props.id +
          `/ImportInventory`,
          file,
          config
        )
        .then((response) => {
          // handle the response
          setImportData(response?.data?.data);
          setIsImport(true);
        })
        .catch((error) => {
          // handle errors
          toast.error(defaultErrorMessage);
        });
    }
  };

  const downloadTemplateClick = async () => {
    try {
      const azureB2C = localStorage.getItem("AzureB2CImpersonate");
      let fixUrl = "";
      fixUrl =
        API_URL +
        INVENTORYAPI +
        `ReturnOrder/GetProductImportInventoryTemplate`;

      fetch(fixUrl, {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await getToken()),
          ...((azureB2C && { UserImpersonated: azureB2C }) || {}),
        },
      })
        .then(async (response: any) => {
          const b = await response.blob();
          let url = URL.createObjectURL(b);
          let a = document.createElement("a");
          a.href = url;
          a.download = "Download Template.csv";
          a.click();
        })
        .catch((err) => toast.error(defaultErrorMessage));
    } catch (error) { }
  };

  const downloadError = (data: any) => {
    let csvContent = atob(data);
    var blob = new Blob([csvContent], {
      type: "data:application/octet-stream;base64",
    });
    let url = URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = "Download Error Log.csv";
    a.click();
  };

  const fileUploadChange = (e: any) => {
    if (e[0].name.split(".").pop() !== "csv") {
      setShowError(true);
    } else {
      const formData = new FormData();
      formData.append("file", e[0]);
      setFile(formData);
      setFileName(e[0].name);
      setShowError(false);
    }
  };

  return (
    <Modal
      show={props.show}
      // onHide={props.handleClose}
      className="custom_modal"
    >
      <Modal.Header className="custom_modal_header">
        <Modal.Title className="custom_modal_header_title">
          <div className="custom_modal_header_title_left">Import Inventory</div>
          <div className="custom_modal_header_title_right">
            <Button variant="link" onClick={props.handleClose}>
              <i className="fa-solid fa-times"></i>
            </Button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom_modal_body">
        {isImport ? (
          <>
            <div className="custom_table_wrap">
              <table className="custom_table">
                <tr>
                  <th>File Name </th>
                  <th>Success#</th>
                  <th>Error#</th>
                </tr>
                <tr>
                  <td>{fileName}</td>
                  <td className="text-success">{importData?.success}</td>
                  <td className="text-danger">{importData?.errors}</td>
                </tr>
              </table>
            </div>
            {importData?.errors ? (
              <Button
                variant="link"
                className="text-decoration-none p-0"
                onClick={() => downloadError(importData.errorLog)}
              >
                <i className="fa-solid fa-file-download me-2"></i>Download Error
                Log
              </Button>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <Button
              variant="link"
              className="text-decoration-none p-0"
              onClick={downloadTemplateClick}
            >
              <i className="fa-solid fa-file-lines me-2"></i>Download Template
            </Button>
            <div className="mt-2 p-3 report-content ">
              Instructions:
              <ol>
                <li>Download CSV template.</li>
                <li>Review the example provided. Delete example after review.</li>
                <li>Add all required data.</li>
                <li>For non C2's,Do Not add Expiration date, Lot Numbers and Gs1.</li>
                <li>Upload completed template.</li>
              </ol>
            </div>
            <DragDropComponent
              type={".csv"}
              fileUploadChange={fileUploadChange}
              fileSelectMessage={file === undefined ? "No file selected" : ""}
              fileErrorMessage={
                showError ? "Invalid file type only supports '.csv'" : ""
              }
            ></DragDropComponent>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="custom_modal_footer">
        {!isImport ? (
          <Button variant="primary" onClick={importButtonClick}>
            Import
          </Button>
        ) : (
          <></>
        )}
        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ImportInventoryModal;
