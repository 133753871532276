import { useEffect } from 'react';

import { useMsal } from '@azure/msal-react';
import { b2cPolicies } from '../../../authConfig';

export const AdminLogin = () => {
    const { instance } = useMsal();
     
        useEffect(() => {
            if(instance) {
                loadAdmin()
            }
        }, []);
        const   loadAdmin = async () =>{
            try {
                const resetPasswordRequest = {
                    authority: b2cPolicies.authorities.parma_login.authority,
                    scopes: [],
                };
                   await instance.loginRedirect(resetPasswordRequest);
        } catch (error) { }
         
        }


    return (
        <></>
    );
};

export default AdminLogin;

