import { Outlet } from "react-router";
import Header from "./header/header";
import Footer from "./footer/footer";
const Layout = () => {
  return (
    <div className="layout-wrap">
      <div className="layout-inner">
        <Header />
        <div className="layout-content">
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default Layout;
