import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const setSelectedAccounts = createAsyncThunk(
  "add/items",
  async (data: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(addToAccount(data));
    } catch (error) {
      return thunkAPI.rejectWithValue(0);
    }
  }
);
export const setRefreshPage = createAsyncThunk(
  "add/flag",
  async (data: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(refreshPage(data));
    } catch (error) {
      return thunkAPI.rejectWithValue(0);
    }
  }
);
export const clearFullAccountData = createAsyncThunk(
  "clear/account",
  async (data: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(clearAccount());
    } catch (error) {
      return thunkAPI.rejectWithValue(0);
    }
  }
);
export const setrefreshNotificationPage = createAsyncThunk(
  "add/flag-for-notification",
  async (data: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(refreshNotification(data));
    } catch (error) {
      return thunkAPI.rejectWithValue(0);
    }
  }
);
const initialState = {
  chosenIds: localStorage.getItem("chosenIds")
    ? JSON.parse(localStorage.getItem("chosenIds") as string)
    : [],
  refreshPage: false,
  refreshNotificationFlag:""
};
const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    addToAccount: (state: any, action) => {
      let chosenIds: any[] = [];
      // const numericItems = action.payload.filter((item: string) =>
      //   /^\d+$/.test(item)
      // );
      // chosenIds.push(...chosenIds, numericItems);
      chosenIds.push(...chosenIds, action.payload);
      localStorage.setItem("chosenIds", JSON.stringify(chosenIds));
      return {
        // "spread" the original state object
        ...state,
        // but replace the "chosenIds" field
        chosenIds,
      };
    },
    refreshPage: (state: any, action) => {
      return {
        // "spread" the original state object
        ...state,
        // but replace the "chosenIds" field
        refreshPage: action.payload,
      };
    },
    refreshNotification: (state: any, action) => {
      return {
        // "spread" the original state object
        ...state,
        // but replace the "chosenIds" field
        refreshNotificationFlag: action.payload,
      };
    },
    clearAccount: () => {
      return initialState;
    },
  },
});
const { reducer, actions } = accountsSlice;
export const { addToAccount, clearAccount, refreshPage,refreshNotification } = actions;
export default reducer;
