import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import { useAppDispatch } from "../../../../store/hooks";
import { getInvoiceDetails } from "../../../../store/api/apiSlice";
import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import getFiles from "../../../shared/csvDownload/csvDownload";
import { useParams } from "react-router-dom";
import {
  COLUMN_CONFIG_REPORT_NAMES,
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getTableFields } from "../../../../helpers/getTableFields";
import { useUserSettings } from "../../../../helpers/hooks/useUserSettings";
import { showConfigColumns } from "../../../../helpers/getSortedVisibleCols";

function InvoiceDetails() {
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const dispatch = useAppDispatch();
  const queryParams: any = useParams();
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const InvoiceDetailRow = localStorage.getItem("InvoiceDetailRow")
    ? JSON.parse(localStorage.getItem("InvoiceDetailRow") as any)
    : {};

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "InvoiceDetails.csv",
    URL: "Reports/GetInvoiceDetail",
    customerId: InvoiceDetailRow?.customerInternalId,
    orderNumber: Number(InvoiceDetailRow?.orderNumber),
    filterOptions: [],
  });

  const columnItems = [
    {
      headerName: "Order#",
      headerTooltip: "Order#",
      tooltipShowDelay: 0,
      field: "orderNumber",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Process Date",
      headerTooltip: "Process Date",
      tooltipShowDelay: 0,
      field: "processDate",
      hide: false,
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return formatDatePipe(params.value);
      },
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
    },
    // {
    //   headerName: "Invoice Date",
    //   headerTooltip: "Invoice Date",
    //   tooltipShowDelay: 0,
    //   field: "invoiceDate",
    //   hide: false,
    //   comparator: customComparator,
    //   valueFormatter: function (params: any) {
    //     return formatDatePipe(params.value);
    //   },
    //   filter: "agDateColumnFilter",
    //   menuTabs: ["filterMenuTab"],
    //   filterParams: {
    //     suppressAndOrCondition: true,
    //     buttons: ["reset"],
    //     debounceMs: 1000,
    //   },
    // },
    {
      headerName: "Item",
      headerTooltip: "Item",
      tooltipShowDelay: 0,
      tooltipField: "item",
      field: "item",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Quantity",
      headerTooltip: "Quantity",
      tooltipShowDelay: 0,
      field: "quantity",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      type: gridAlignment.RIGHT,
      comparator: customComparator,
    },
    // {
    //   headerName: "Rate",
    //   headerTooltip: "Rate",
    //   tooltipShowDelay: 0,
    //   field: "rate",
    //   hide: false,
    //   comparator: customComparator,
    //   type: gridAlignment.RIGHT,
    //   filter: "agNumberColumnFilter",
    //   filterParams: {
    //     buttons: ["reset"],
    //     debounceMs: 1000,
    //     suppressAndOrCondition: true,
    //   },
    //   menuTabs: ["filterMenuTab"],
    // },
    {
      headerName: "Amount",
      headerTooltip: "Amount",
      tooltipShowDelay: 0,
      field: "amount",
      hide: false,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
  ];

  const { id, dbColumnConfig } = useUserSettings(COLUMN_CONFIG_REPORT_NAMES?.InvoiceDetail);
  const [colDefs, setColDefs] = useState(() => showConfigColumns(columnItems, dbColumnConfig));

  const [topItemslength, setTopItemslength] = useState(0);
  const [dataTopItems, setDataTopItems] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: "#000351",
        borderWidth: 0,
        borderRadius: 15,
        barThickness: 15,
      },
    ],
  });

  const optionsTopItems = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          callback: (value: any, index: any, values: any) => {
            let label: any = dataTopItems.labels[value];
            if (label == null) {
              return "";
            }
            if (label.length > 10) {
              label = label.substring(0, 10);
              label += "..";
            }
            return label;
          },
          maxRotation: 90,
          minRotation: 90,
        },
        grid: { display: false },
      },
    },
  };

  function customComparator() {
    return 0; //means no comparing and no sorting
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    // setTimeout(() => {
    //   params.api.autoSizeAllColumns();
    // }, 100);
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();

    const cols: any = [];

    columnValues.forEach((value: any) => {
      if (value?.hide === true)
        cols.push(value.field);
    });

    localStorage.setItem("invoiceDetailCols", JSON.stringify(cols));

    toast.success("Saved Successfully");
    setColDefs(columnValues);
    myRef?.current.handleClose();
    if (gridRef.current) {
      gridRef.current.api.autoSizeAllColumns();
    }
  };

  const updateTopItems = (data1: any) => {
    setDataTopItems({
      ...dataTopItems,
      datasets: [
        {
          ...dataTopItems.datasets[0],
          data: data1, // Update the data array with the new data
        },
      ],
    });
  };

  const updateTopLabel = (k: any, v: any) =>
    setDataTopItems((prev) => ({ ...prev, [k]: v }));

  const invoiceDetails = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              customerId: InvoiceDetailRow?.customerInternalId,
              orderNumber: Number(InvoiceDetailRow?.orderNumber),
              PageSize: gridData.pageSize,
              pageNumber: gridApi.paginationGetCurrentPage() === 0 ? 1 : gridApi.paginationGetCurrentPage() + 1,
              SortField: gridData.sortField,
              SortDirection: gridData.sortOrder
                ? Number(gridData.sortOrder)
                : 1,
              IsForExcel: gridData.isForExcel,
              GlobalSearch: gridData.globalSearch,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };

            let label: any = [];
            let topItems: any = [];
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(getInvoiceDetails(data)).then((result: any) => {
              updateGridData("totalRows", result?.payload?.data?.totalCount);
              updateGridData(
                "totalAmount",
                CurrencyConverter.format(result?.payload?.data?.totalAmount)
              );

              if (result?.payload?.data && result?.payload?.isSuccess) {
                setTopItemslength(
                  result?.payload?.data?.invoiceChartDetails.length
                );
                result?.payload?.data?.invoiceChartDetails.forEach(
                  (element: any) => {
                    label.push(element.name);
                    topItems.push(element.value);
                  }
                );
              }

              updateTopItems(topItems);
              updateTopLabel("labels", label);
              if (result?.payload?.data?.invoices?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.invoices,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        if (queryParams && queryParams.id && queryParams.id > 0) {
          invoiceDetails();
        }
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      if (queryParams && queryParams.id /* && queryParams.id > 0 */) {
        invoiceDetails();
      }
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
  ]);



  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };

  const onExport = (e: any) => {
    updateGridData("isForExcel", true);
  };

  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  }
  return (
    <Container fluid>
      <PageTitle
        name={`Invoice Details: ${InvoiceDetailRow?.customerId || ""}`}
        buttonName="Back"
        routePage="/reports/invoice-summary"
      ></PageTitle>

      <div className="order-history-top">
        <div className="content_body">
          <Row>
            <Col xs={12}>
              <div className="highlight-section">
                <div className="first-section d-flex flex-wrap gap-3 justify-content-between my-3">
                  <div className="d-flex flex-column">
                    <label>Customer Id: </label>
                    <span className="value">
                      {InvoiceDetailRow?.customerId}
                    </span>
                  </div>

                  <div className="d-flex flex-column">
                    <label>Order#: </label>
                    <span className="value">
                      {InvoiceDetailRow?.orderNumber}
                    </span>
                  </div>

                  <div className="d-flex flex-column">
                    <label>Process Date:</label>
                    <span className="value">
                      {InvoiceDetailRow?.processDate}
                    </span>
                  </div>

                  <div className="d-flex flex-column">
                    <label>Amount</label>
                    <span className="value">{InvoiceDetailRow?.amount}</span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* <div className="graph-wrapper ">
        <Row>
          <Col xs={12} md={12}  >
            <div className="content_body" >
              <div className="heading">
                <h2>Top Items</h2>
              </div>
              {topItemslength &&
                <div>
                  <BarChart id={'count-of-schedule'} data={dataTopItems} options={optionsTopItems} />
                </div>
              }
              {topItemslength === 0 && (
                <div className="no-records-wrapper mincardheight justify-content-center">
                  <svg
                    width="48"
                    height="42"
                    viewBox="0 0 48 42"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 5.25C0 2.3543 2.39167 0 5.33333 0H18.6667V10.5C18.6667 11.952 19.8583 13.125 21.3333 13.125H32V16.2914C25.8417 18.0059 21.3333 23.5758 21.3333 30.1875C21.3333 35.0355 23.7583 39.3176 27.475 41.9426C27.2083 41.9836 26.9417 42 26.6667 42H5.33333C2.39167 42 0 39.6457 0 36.75V5.25ZM32 10.5H21.3333V0L32 10.5ZM36 18.375C39.1826 18.375 42.2348 19.6195 44.4853 21.8348C46.7357 24.0501 48 27.0546 48 30.1875C48 33.3204 46.7357 36.3249 44.4853 38.5402C42.2348 40.7555 39.1826 42 36 42C32.8174 42 29.7652 40.7555 27.5147 38.5402C25.2643 36.3249 24 33.3204 24 30.1875C24 27.0546 25.2643 24.0501 27.5147 21.8348C29.7652 19.6195 32.8174 18.375 36 18.375ZM36 38.0625C36.5304 38.0625 37.0391 37.8551 37.4142 37.4859C37.7893 37.1167 38 36.6159 38 36.0938C38 35.5716 37.7893 35.0708 37.4142 34.7016C37.0391 34.3324 36.5304 34.125 36 34.125C35.4696 34.125 34.9609 34.3324 34.5858 34.7016C34.2107 35.0708 34 35.5716 34 36.0938C34 36.6159 34.2107 37.1167 34.5858 37.4859C34.9609 37.8551 35.4696 38.0625 36 38.0625ZM36 22.3125C35.2667 22.3125 34.6667 22.9031 34.6667 23.625V30.1875C34.6667 30.9094 35.2667 31.5 36 31.5C36.7333 31.5 37.3333 30.9094 37.3333 30.1875V23.625C37.3333 22.9031 36.7333 22.3125 36 22.3125Z"
                      fill="#3F475C"
                      fillOpacity="0.3"
                    />
                  </svg>
                  <span>Filtered records do not include selected values</span>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div> */}

      <div className="content_body grid-reset">
        <GridComponent
          dbUserReportId={id}
          dbReportName={COLUMN_CONFIG_REPORT_NAMES?.InvoiceDetail}
          dbColumnConfig={dbColumnConfig}
          defaultColumns={columnItems}
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          shortOrder={gridData.sortOrder}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        ></GridComponent>
      </div>
    </Container>
  );
}
export default InvoiceDetails;
