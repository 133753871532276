import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import { useAppDispatch } from "../../../../store/hooks";
import {
  getPaymentSchedules,
  getRcpReport,
  getRcpSummaries,
} from "../../../../store/api/apiSlice";
import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import getFiles from "../../../shared/csvDownload/csvDownload";
import { useParams } from "react-router-dom";
import {
  COLUMN_CONFIG_REPORT_NAMES,
  defaultNoRecordFound,
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getTableFields } from "../../../../helpers/getTableFields";
import { useUserSettings } from "../../../../helpers/hooks/useUserSettings";
import { showConfigColumns } from "../../../../helpers/getSortedVisibleCols";
import drugFileDownload from "../../../shared/csvDownload/drugFileDownload";

function RapidCredit() {
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const dispatch = useAppDispatch();
  const queryParams: any = useParams();
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const rcpRow = localStorage.getItem("RCPRow")
    ? JSON.parse(localStorage.getItem("RCPRow") as any)
    : {};
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridData, setGridData] = useState<any>({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    rcpPaymentSchedules: [],
    rcpSummary: {},
    rcpDetail: {},
    sortField: "",
    csvName: "RapidCreditReport.csv",
    URL: "Reports/GetRCPOrderLineDetails",
    orderNumber: queryParams.id,
    filterOptions: [],
  });

  const columnItems = [
    {
      headerName: "Product",
      field: "product",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Manufacturer",
      field: "manufacturer",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Expiration Date",
      field: "expirationDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: false,
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Lot#",
      field: "lot",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Credit Disposition",
      field: "creditDisposition",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Eligibility",
      field: "eligibility",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Tier",
      field: "tier",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "ERV",
      field: "erv",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Offer",
      field: "offer",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
  ];

  const { id, dbColumnConfig } = useUserSettings(
    COLUMN_CONFIG_REPORT_NAMES?.RCP
  );
  const [colDefs, setColDefs] = useState(() =>
    showConfigColumns(columnItems, dbColumnConfig)
  );

  function customComparator() {
    return 0; //means no comparing and no sorting
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setTimeout(() => {
      params.api.sizeColumnsToFit();
    }, 100);
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev: any) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();

    toast.success("Saved Successfully");
    setColDefs(columnValues);
    myRef?.current.handleClose();

    if (gridRef.current) {
      gridRef.current.api.sizeColumnsToFit();
    }
  };

  const rapidCredit = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              orderNumber: queryParams.id.toString(),
              PageSize: gridData.pageSize,
              pageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              SortField: gridData.sortField,
              SortDirection: gridData.sortOrder
                ? Number(gridData.sortOrder)
                : 1,
              IsForExcel: gridData.isForExcel,
              GlobalSearch: gridData.globalSearch,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };

            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );

            dispatch(getRcpSummaries({ orderNumber: data?.orderNumber })).then(
              (result: any) => {
                updateGridData("rcpDetail", result?.payload?.data?.rcpDetail);
                updateGridData(
                  "rcpSummary",
                  result?.payload?.data?.rcpSummaries
                );

                if (
                  Object.keys(result?.payload?.data?.rcpSummaries || {})
                    .length > 0
                ) {
                  dispatch(getRcpReport(data)).then((result: any) => {
                    updateGridData(
                      "totalRows",
                      result?.payload?.data?.totalCount
                    );
                    updateGridData(
                      "totalAmount",
                      CurrencyConverter.format(
                        result?.payload?.data?.totalAmount
                      )
                    );
                    if (
                      result?.payload?.data?.rcpOrderLineDetails?.length === 0
                    ) {
                      gridApi.showNoRowsOverlay();
                      params.success({
                        rowData: [],
                        rowCount: 0,
                      });
                    } else {
                      gridApi!.hideOverlay();
                      params.success({
                        rowData: result?.payload?.data?.rcpOrderLineDetails,
                        rowCount: result?.payload?.data?.totalCount,
                      });
                    }
                  });

                  dispatch(
                    getPaymentSchedules({ orderNumber: data?.orderNumber })
                  ).then((result: any) => {
                    updateGridData(
                      "paymentSchedules",
                      result?.payload?.data?.rcpPaymentSchedules
                    );
                  });
                }
              }
            );
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        if (queryParams && queryParams.id && queryParams.id > 0) {
          rapidCredit();
        }
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      if (queryParams && queryParams.id && queryParams.id > 0) {
        rapidCredit();
      }
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
  ]);

  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  useEffect(() => {
    if (Object.keys(dbColumnConfig).length > 0)
      setColDefs(showConfigColumns(columnItems, dbColumnConfig));
  }, [dbColumnConfig]);

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };

  const onExport = (e: any) => {
    updateGridData("isForExcel", true);
  };

  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  };
  return (
    <Container fluid>
      <PageTitle
        name={`RCP - ${queryParams.id || ""}`}
        buttonName="Back"
        routePage="/reports/order-history"
      ></PageTitle>

      <div className="rcp-top">
        <div className="content_body">
          <div className="heading">
            <h2>RAPID CREDIT</h2>
            <p>Detailed information regarding the rapid credit order</p>
          </div>

          <div className="rcp-detail">
            <h3>{rcpRow?.customerName}</h3>
            <Row>
              <Col xs={12} md={6}>
                <div className="card-gray d-flex justify-content-between flex-wrap">
                  <div className="d-flex flex-column">
                    <label>Account#</label>
                    <span className="value">
                      {gridData?.rcpDetail?.account || ""}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <label>Process Date</label>
                    <span className="value">{rcpRow?.processDate || ""}</span>
                  </div>
                  <div className="d-flex flex-column">
                    <label>Order#</label>
                    <span className="value">
                      {gridData?.rcpDetail?.job || ""}
                    </span>
                  </div>
                </div>
              </Col>

              <Col xs={12} md={6}>
                <div className="card-gray d-flex justify-content-between flex-wrap mt-3 mt-md-0">
                  <div className="d-flex flex-column">
                    <label>Payment(s)</label>
                    <span className="value">
                      {gridData?.rcpDetail?.paymentDate || ""}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <label>Check</label>
                    <span className="value">
                      {gridData?.rcpDetail?.check || ""}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <label>Invoice Paydown(s)</label>
                    <span className="value">
                      {gridData?.rcpDetail?.invoicePayDown || ""}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <Row>
        <Col xs={12} md={6}>
          <div className="rcp-summary">
            <div className="content_body">
              <div className="heading">
                <h2>Summary</h2>
              </div>
              {Object.keys(gridData?.rcpSummary || {}).length === 0 ? (
                <div className="no-records-wrapper mincardheight justify-content-center">
                  <svg
                    width="48"
                    height="42"
                    viewBox="0 0 48 42"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 5.25C0 2.3543 2.39167 0 5.33333 0H18.6667V10.5C18.6667 11.952 19.8583 13.125 21.3333 13.125H32V16.2914C25.8417 18.0059 21.3333 23.5758 21.3333 30.1875C21.3333 35.0355 23.7583 39.3176 27.475 41.9426C27.2083 41.9836 26.9417 42 26.6667 42H5.33333C2.39167 42 0 39.6457 0 36.75V5.25ZM32 10.5H21.3333V0L32 10.5ZM36 18.375C39.1826 18.375 42.2348 19.6195 44.4853 21.8348C46.7357 24.0501 48 27.0546 48 30.1875C48 33.3204 46.7357 36.3249 44.4853 38.5402C42.2348 40.7555 39.1826 42 36 42C32.8174 42 29.7652 40.7555 27.5147 38.5402C25.2643 36.3249 24 33.3204 24 30.1875C24 27.0546 25.2643 24.0501 27.5147 21.8348C29.7652 19.6195 32.8174 18.375 36 18.375ZM36 38.0625C36.5304 38.0625 37.0391 37.8551 37.4142 37.4859C37.7893 37.1167 38 36.6159 38 36.0938C38 35.5716 37.7893 35.0708 37.4142 34.7016C37.0391 34.3324 36.5304 34.125 36 34.125C35.4696 34.125 34.9609 34.3324 34.5858 34.7016C34.2107 35.0708 34 35.5716 34 36.0938C34 36.6159 34.2107 37.1167 34.5858 37.4859C34.9609 37.8551 35.4696 38.0625 36 38.0625ZM36 22.3125C35.2667 22.3125 34.6667 22.9031 34.6667 23.625V30.1875C34.6667 30.9094 35.2667 31.5 36 31.5C36.7333 31.5 37.3333 30.9094 37.3333 30.1875V23.625C37.3333 22.9031 36.7333 22.3125 36 22.3125Z"
                      fill="#3F475C"
                      fillOpacity="0.3"
                    />
                  </svg>
                  <span>{defaultNoRecordFound}</span>
                </div>
              ) : (
                <>
                  <div className="summary-top">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <label>Eligible expected</label>
                      <span className="value">
                        {gridData?.rcpSummary?.eligibleExpected || "$0"}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <label>Ineligible expected</label>
                      <span className="value">
                        {gridData?.rcpSummary?.ineligibleExpected || "$0"}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <label>Offer</label>
                    <span className="value">
                      {gridData?.rcpSummary?.offer || "$0"}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <label>Pending Pop</label>
                    <span className="value">
                      {gridData?.rcpSummary?.pendingPoP || "$0"}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <label>Service Fee</label>
                    <span className="value">
                      -{gridData?.rcpSummary?.serviceFee || "$0"}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <label>Overstock Fee</label>
                    <span className="value">
                      {gridData?.rcpSummary?.overstockFee || "$0"}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <label>Other Fees</label>
                    <span className="value">
                      {gridData?.rcpSummary?.otherFees || "$0"}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center total-row">
                    <label>Payment</label>
                    <span className="value">
                      {gridData?.rcpSummary?.payment || "$0"}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </Col>

        <Col xs={12} md={6}>
          <div className="rcp-payment-schedule">
            <div className="content_body">
              <div className="heading">
                <h2>Payment Schedule</h2>
              </div>
              <div className="detail-table mt-3 mt-md-0">
                {(gridData?.paymentSchedules || []).length === 0 ? (
                  <div className="no-records-wrapper mincardheight justify-content-center">
                    <svg
                      width="48"
                      height="42"
                      viewBox="0 0 48 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 5.25C0 2.3543 2.39167 0 5.33333 0H18.6667V10.5C18.6667 11.952 19.8583 13.125 21.3333 13.125H32V16.2914C25.8417 18.0059 21.3333 23.5758 21.3333 30.1875C21.3333 35.0355 23.7583 39.3176 27.475 41.9426C27.2083 41.9836 26.9417 42 26.6667 42H5.33333C2.39167 42 0 39.6457 0 36.75V5.25ZM32 10.5H21.3333V0L32 10.5ZM36 18.375C39.1826 18.375 42.2348 19.6195 44.4853 21.8348C46.7357 24.0501 48 27.0546 48 30.1875C48 33.3204 46.7357 36.3249 44.4853 38.5402C42.2348 40.7555 39.1826 42 36 42C32.8174 42 29.7652 40.7555 27.5147 38.5402C25.2643 36.3249 24 33.3204 24 30.1875C24 27.0546 25.2643 24.0501 27.5147 21.8348C29.7652 19.6195 32.8174 18.375 36 18.375ZM36 38.0625C36.5304 38.0625 37.0391 37.8551 37.4142 37.4859C37.7893 37.1167 38 36.6159 38 36.0938C38 35.5716 37.7893 35.0708 37.4142 34.7016C37.0391 34.3324 36.5304 34.125 36 34.125C35.4696 34.125 34.9609 34.3324 34.5858 34.7016C34.2107 35.0708 34 35.5716 34 36.0938C34 36.6159 34.2107 37.1167 34.5858 37.4859C34.9609 37.8551 35.4696 38.0625 36 38.0625ZM36 22.3125C35.2667 22.3125 34.6667 22.9031 34.6667 23.625V30.1875C34.6667 30.9094 35.2667 31.5 36 31.5C36.7333 31.5 37.3333 30.9094 37.3333 30.1875V23.625C37.3333 22.9031 36.7333 22.3125 36 22.3125Z"
                        fill="#3F475C"
                        fillOpacity="0.3"
                      />
                    </svg>
                    <span>{defaultNoRecordFound}</span>
                  </div>
                ) : (
                  <Table responsive>
                    <thead className="sticky-top">
                      <tr>
                        <th>Item Description</th>
                        <th>Expiration as of Process Date</th>
                        <th>Rapid Credit Rate</th>
                        <th>Offer</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(gridData?.paymentSchedules || []).map(
                        (element: any, index: number) => {
                          return (
                            <tr key={element?.itemDescription + index}>
                              <td>{element?.itemDescription || ""}</td>
                              <td>{element?.processDate || ""}</td>
                              <td>{element?.rapidCreditRate || ""}</td>
                              <td>{element?.offer || ""}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                )}
              </div>
            </div>
          </div>
          <div className="pdf-listing-wrapper mt-2 mb-2">
            <div className="pdf-listing">
              <ul>
                <li
                  className="cursor_pointer"
                  onClick={(e) => {
                    drugFileDownload(
                      "Reports/GetWriteOffLetterFile",
                      "Write_Off_Letter.pdf",
                      queryParams.id,
                      formateIds(userAccounts)
                    );
                  }}
                >
                  <i className="fa fa-file-pdf icon" aria-hidden="true"></i>
                  <a className="me-4">Write Off Letter</a>
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>

      <div className="content_body grid-reset">
        <GridComponent
          dbUserReportId={id}
          dbReportName={COLUMN_CONFIG_REPORT_NAMES?.RCP}
          dbColumnConfig={dbColumnConfig}
          defaultColumns={columnItems}
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          shortOrder={gridData.sortOrder}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        ></GridComponent>
      </div>
    </Container>
  );
}

export default RapidCredit;
