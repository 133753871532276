import { useState, useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  COLUMN_CONFIG_REPORT_NAMES,
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { getDestructionManifests } from "../../../../store/api/apiSlice";
import { getTableFields } from "../../../../helpers/getTableFields";
import { useAppDispatch } from "../../../../store/hooks";

import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import downloadFileFromUrl from "../../../shared/csvDownload/fileDownloader";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import getFiles from "../../../shared/csvDownload/csvDownload";
import fileDownloaderPost from "../../../shared/csvDownload/fileDownloaderPost";
import { showLoader } from "../../../shared/GlobalLoader";
import { showConfigColumns } from "../../../../helpers/getSortedVisibleCols";
import { useUserSettings } from "../../../../helpers/hooks/useUserSettings";

function DestructionManifests() {
  const gridRef = useRef<AgGridReact>(null);
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const myRef = useRef<any>();

  const dispatch = useAppDispatch();
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    csvName: "DestructionManifests.csv",
    URL: "Reports/DestructionManifests",
    filterOptions: [],
  });

  const columnItems = [
    {
      headerName: "Customer ID",
      headerTooltip: "Customer ID",
      tooltipShowDelay: 0,
      field: "customerId",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Customer Name",
      headerTooltip: "Customer Name",
      tooltipShowDelay: 0,
      field: "customerName",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Order#",
      headerTooltip: "Order#",
      tooltipShowDelay: 0,
      field: "orderNumber",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Process Date",
      headerTooltip: "Process Date",
      tooltipShowDelay: 0,
      field: "processDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: false,
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Manifest#",
      headerTooltip: "Manifest#",
      tooltipShowDelay: 0,
      field: "manifestNumber",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
      cellStyle: { cursor: "pointer" },
      cellRenderer: function (params: any) {
        return (
          <div
            className="text-link"
            onClick={(e) => {
              if (params.data.fileName) {
                fileDownloaderPost(`Reports/DestructionManifestsFile`, params);
              }
            }}
          >
            {params.data.manifestNumber}
          </div>
        );
      },
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Destruction Date",
      headerTooltip: "Destruction Date",
      tooltipShowDelay: 0,
      field: "destructionDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: false,
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Destruction Vendor",
      headerTooltip: "Destruction Vendor",
      tooltipShowDelay: 0,
      field: "destructionVendor",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Waste Type",
      headerTooltip: "Waste Type",
      tooltipField: "wasteType",
      tooltipShowDelay: 0,
      field: "wasteType",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
  ];

  const { id, dbColumnConfig } = useUserSettings(COLUMN_CONFIG_REPORT_NAMES?.DestructionManifests);
  const [colDefs, setColDefs] = useState(() => showConfigColumns(columnItems, dbColumnConfig));

  function customComparator() {
    return 0; //means no comparing and no sorting
  }

  const onGridReady = (params: any) => {
    setTimeout(() => {
      // params.api.autoSizeAllColumns();
    }, 100);

    setGridApi(params.api);
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();

    toast.success("Saved Successfully");
    setColDefs(columnValues);
    myRef?.current.handleClose();

    if (gridRef.current) {
      gridRef.current.api.autoSizeAllColumns();
    }
  };

  const destructionManifests = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              PageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              PageSize: gridData.pageSize,
              SortField: gridData.sortField,
              sortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              IsForExcel: gridData.isForExcel,
              GlobalSearch: gridData.globalSearch,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            dispatch(getDestructionManifests(data)).then((result: any) => {
              if (result?.payload?.data?.destructionManifests?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.destructionManifests,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
              updateGridData("totalRows", result?.payload?.data?.totalCount);
              updateGridData(
                "totalAmount",
                CurrencyConverter.format(result?.payload?.data?.totalAmount)
              );
            });
          },
        };

        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        destructionManifests();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      destructionManifests();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
  ]);

  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  useEffect(() => {
    if (Object.keys(dbColumnConfig).length > 0)
      setColDefs(showConfigColumns(columnItems, dbColumnConfig));
  }, [dbColumnConfig])

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };
  const onExport = (e: any) => {
    updateGridData("isForExcel", true);
  };
  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  }
  return (
    <Container fluid>
      <PageTitle
        name="Destruction Manifests"
        buttonName="Back"
        routePage="/reports/view-all"
      ></PageTitle>
      <div className="content_body grid-reset">
        <GridComponent
          dbUserReportId={id}
          dbReportName={COLUMN_CONFIG_REPORT_NAMES?.DestructionManifests}
          dbColumnConfig={dbColumnConfig}
          defaultColumns={columnItems}
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          shortOrder={gridData.sortOrder}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        ></GridComponent>
      </div>
    </Container>
  );
}

export default DestructionManifests;
