import { toast } from "react-toastify";
import axios from "../../../services/httpService";
import getToken from "../../../store/api/api";
import { defaultErrorMessage } from "../../../interface/comman.constant";
import { hideLoader, showLoader } from "../GlobalLoader";

export default async function downloadFileFromUrl(
  urlEndpoint: any,
  fileName: any
) {
  const azureB2C = localStorage.getItem('AzureB2CImpersonate');
  const API_URL = axios.Api;
  const REPORTAPI = axios.ReportAPI || "";
  try {
    showLoader();
    fetch(API_URL + REPORTAPI + urlEndpoint, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (await getToken()),
        ...(azureB2C) && ({ UserImpersonated: azureB2C })
      },
    })
      .then(async (response: any) => {
        const b = await response.blob();
        let url = URL.createObjectURL(b);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        toast.error(defaultErrorMessage)

      });
  } catch (error) {
    hideLoader();
  }
}
