import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect, FormEvent } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import { useAppDispatch } from "../../../../store/hooks";
import {
  getDisposalAccount,
  getServies,
  postRequestOnSiteService,
} from "../../../../store/api/apiSlice";
import GlobalPopup from "../../../shared/GlobalPopup/globalPopup";
import moment from "moment";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { getTableFields } from "../../../../helpers/getTableFields";
import { useSelector } from "react-redux";
import { formatDatePipe, formateIds } from "../../../../interface/comman.constant";

function RequestOnsiteService() {
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const dispatch = useAppDispatch();
  const formRef = useRef<any>();
  var currentDate = new Date();
  var currentDate = addWeekdaysToDate(new Date(), 7);
  var futureDate = addWeekdaysToDate(currentDate, 59);
  const [gridApi, setGridApi] = useState<any>(null);
  const [minDate, setMinDate] = useState(currentDate);
  const [maxDate, setMaxDate] = useState(futureDate);
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const [gridData, setGridData] = useState({
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalAmount: "",
    isForExcel: false,
    sortField: "",
    sortOrder: "",
  });

  function addWeekdaysToDate(startDate:any, daysToAdd:any) {
    var currentDate = new Date(startDate);
    var count = 0;
    while (count < daysToAdd) {
      currentDate.setDate(currentDate.getDate() + 1);      
      // Check if it's a weekend (Saturday or Sunday)
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        count++;
      }
    }
    
    return currentDate;
  }
  const columnitems = [
    {
      headerName: "PICKUP ACCOUNT",
      field: "companyName",
      tooltipField: "pickupAccount",
      headerTooltip: "PICKUP ACCOUNT",
      tooltipShowDelay: 0,
      hide: false,
      flex: 1,
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.data.disposalAccountName;
      },
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "DATE RANGE",
      headerTooltip: "DATE RANGE",
      tooltipShowDelay: 0,
      field: "fromDate",
      hide: false,
      flex: 1,
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.data.fromDate + " to " + params.data.toDate;
      },
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
    },
  ];
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [colDefs, setColDefs] = useState(columnitems);
  const [pickupAccount, setPickupAccount] = useState();
  const [pickupAccountName, setPickupAccountName] = useState("");
  const [selectedFromDate, setSelectedFromDate] = useState(minDate);
  const [selectedToDate, setSelectedToDate] = useState(minDate);
  const [errorFromMessage, setErrorFromMessage] = useState("");
  const [errorPickupAccountMessage, setErrorPickupAccountMessage] =
    useState("");
  const [errorToMessage, setErrorToMessage] = useState("");
  const [pickupAccountData, setpickupAccountData] = useState([]);

  const options: any = [];

  pickupAccountData &&
    pickupAccountData.map((item: any) =>
      options.push({
        value: item.id,
        label: item.customerCode + "-" + item.disposalAccountName,
      })
    );

  function customComparator() {
    return 0; //means no comparing and no sorting client side
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const getServices = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              PageSize: gridData.pageSize,
              pageNumber: gridApi.paginationGetCurrentPage() === 0 ? 1: gridApi.paginationGetCurrentPage() + 1,
              GlobalSearch: gridData.globalSearch,
              SortField: gridData.sortField,
              SortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            dispatch(getServies(data)).then((result: any) => {
              if (result?.payload?.data?.requestOnSiteServices?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.requestOnSiteServices,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
              updateGridData("totalRows", result?.payload?.data?.totalCount);
              updateGridData(
                "rows",
                result?.payload?.data?.requestOnSiteServices
              );
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getServices();
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.globalSearch,
    gridData.isForExcel,
    gridData.sortOrder,
    gridData.sortField,
    gridApi,
    refreshPage,
  ]);

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(false);
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    event.preventDefault();
    if (pickupAccount === undefined) {
      setErrorPickupAccountMessage("Please Select Pickup Account");
    } else {
      setErrorPickupAccountMessage("");
    }
    if (new Date(selectedFromDate) >= new Date(selectedToDate)) {
      setErrorFromMessage("Start date should be less than end date.");
      setValidated(false);
      return;
    } else if (formatDatePipe(new Date(selectedFromDate).toDateString()) == formatDatePipe(new Date(selectedToDate).toDateString()) ) {
      setErrorFromMessage("Start date should be same as end date.");
      setValidated(false);
      return;
    } else {
      setErrorFromMessage("");
    }
    if (new Date(selectedToDate) <= new Date(selectedFromDate)) {
      setErrorToMessage("End date should be greater than start date.");
      setValidated(false);
      return;
    } else {
      setErrorToMessage("");
    }
    event.preventDefault();
    if (
      form.checkValidity() === true &&
      errorToMessage === "" &&
      errorFromMessage === ""
    ) {
      setValidated(false);
      let momentStartDate = moment(selectedFromDate).utc(true);
      let momentEndDate = moment(selectedToDate).utc(true);
      try {
        let requestData = {
          pickupAccountId: Number(pickupAccount),
          pickupAccountName: pickupAccountName,
          fromDate: momentStartDate.toISOString(),
          toDate: momentEndDate.toISOString(),
        };

        dispatch(postRequestOnSiteService(requestData)).then((result: any) => {
          if (result?.payload?.isSuccess) {
            setShow(true);
            toast.success("Saved Successfully");
            getServices();
            handleReset();
            setSelectedFromDate(minDate);
            setSelectedToDate(minDate);
          } else {
            // setShow(false);
          }
        });
      } catch (error) {}
    }
  };
  const handleReset = () => {
    formRef?.current.reset();
    setValidated(false);
  };
  const handleClose = (e: any) => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleFromDateChange = (e: any) => {
    setErrorFromMessage("");
    setErrorToMessage("");
    setSelectedFromDate(e);
  };
  const handleToDateChange = (e: any) => {
    setErrorFromMessage("");
    setErrorToMessage("");
    setSelectedToDate(e);
  };

  const getDropDownData = () => {
    try {
      let data = 2;
      dispatch(
        getDisposalAccount({ data: data, customerIds: formateIds(userAccounts) })
      ).then((result: any) => {
        setpickupAccountData(result?.payload?.data);
      });
    } catch (error) {}
  };

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();
    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };

  useEffect(() => {
    getDropDownData();
  }, []);

  const pickupAccountChange = (e: any) => {
    setErrorPickupAccountMessage("");
    pickupAccountData?.map((s: any) => {
      if (s.id === e.value)
        return setPickupAccountName(
          s.customerCode + "-" + s.disposalAccountName
        );
    });
    setPickupAccount(e.value);
  };

  const isWeekday = (date: any) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };
  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange =() => {
    if(gridApi) {
        updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  }
  return (
    <Container fluid>
      <GlobalPopup
        show={show}
        showImage={true}
        handleClose={(e: any) => handleClose(e)}
        doneButton={true}
        message={
          "Your request has been submitted successfully. Our customer support will reach out to you shortly."
        }
      ></GlobalPopup>
      <PageTitle
        name="Request On-site Service"
        subname=" *Request does not guarantee service date."
      ></PageTitle>
      <div className="content_body">
        <div className="top-controls">
          <Form
            className="form_box"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
            ref={formRef}
          >
            <Row className="gx-3">
              <Col xs={12} md={6} xl={3} id="pickupAcc">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Pickup Account</Form.Label>
                  {options && (
                    <Select
                      required
                      options={options}
                      onChange={(e) => pickupAccountChange(e)}
                    />
                  )}
                  {errorPickupAccountMessage && (
                    <p className="error-validation">
                      {errorPickupAccountMessage}
                    </p>
                  )}

                  <Form.Control.Feedback type="invalid">
                    Please Select Pickup Account.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} xl={3}>
                <Form.Group
                  className="mb-3 custom-date-range"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Date Range</Form.Label>
                  <DatePicker
                    showIcon
                    toggleCalendarOnIconClick
                    className="form-control"
                    closeOnScroll={true}
                    selected={selectedFromDate}
                    onChange={(date) => handleFromDateChange(date)}
                    minDate={minDate}
                    maxDate={maxDate}
                    filterDate={isWeekday}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                  {errorFromMessage && (
                    <p className="error-validation">{errorFromMessage}</p>
                  )}

                  <Form.Control.Feedback type="invalid">
                    Please Select From Date.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} xl={3}>
                <Form.Group
                  className="mb-3 custom-date-range"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>&nbsp;</Form.Label>
                  <DatePicker
                    showIcon
                    toggleCalendarOnIconClick
                    className="form-control"
                    closeOnScroll={true}
                    selected={selectedToDate}
                    onChange={(date) => handleToDateChange(date)}
                    minDate={minDate}
                    maxDate={maxDate}
                    filterDate={isWeekday}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                  {errorToMessage && (
                    <p className="error-validation">{errorToMessage}</p>
                  )}
                  <Form.Control.Feedback type="invalid">
                    Please Select To Date.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} xl={3}>
                <Form.Group className="mt-4">
                  <Button variant="primary" className="px-3" type="submit">
                    Submit
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            borderTop: "2px solid rgba(29, 34, 49, 0.15)",
          }}
        ></div>
        <GridComponent
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          globalSearch={globalSearch}
          gridData={gridData}
          setGridData={setGridData}
          gridfilterbutton={false}
          sortChange={onSortChanged}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        ></GridComponent>
      </div>
    </Container>
  );
}
export default RequestOnsiteService;
