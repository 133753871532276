import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
// MSAL imports
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
  Configuration,
} from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import GlobalLoader from "./pages/shared/GlobalLoader/GlobalLoader";
import { datadogRum } from "@datadog/browser-rum";

export const msalInstance = new PublicClientApplication(
  msalConfig as Configuration
);

const applicationId = process.env.REACT_APP_DATADOG_APPID ?? "";
const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? "";
const service = process.env.REACT_APP_DATADOG_SERVICE ?? "";
const environment = process.env.REACT_APP_DATADOG_ENVIRONMENT ?? "";
if (environment === "prod" || environment === "staging") {
  datadogRum.init({
    applicationId: applicationId,
    clientToken: clientToken,
    site: "us3.datadoghq.com",
    service: service,
    env: environment,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackViewsManually: false,
    defaultPrivacyLevel: "allow",
  });
}
msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
      if (environment === "prod" || environment === "staging") {
        datadogRum.setUser({ email: account.username, name: account.name });
      }
    }
  });
  msalInstance
    .handleRedirectPromise()
    .then((tokenResponse) => {
      if (!tokenResponse) {
        const accounts = msalInstance.getAllAccounts();
        if (
          accounts.length > 0 &&
          (environment === "prod" || environment === "staging")
        ) {
          datadogRum.setUser({
            email: accounts[0].username,
            name: accounts[0].name,
          });
        }
        if (
          accounts.length === 0 &&
          window.location.href.indexOf("setup") < 1 &&  window.location.href.indexOf("admin-login") < 1
        ) {
          // No user signed in
          msalInstance.loginRedirect();
        }
      } else {
        if (window.location.href.indexOf("setup") > -1) {
          window.location.href = "/";
          msalInstance.logoutRedirect();
        }
        if (window.location.href.indexOf("change") > -1) {
          window.location.href = "/";
          localStorage.clear();
          msalInstance.logoutRedirect();
        }
        if (window.location.href.indexOf("admin-login") > -1) {
          window.location.href = "/";
          localStorage.setItem("isAdmin","true");
        }
        if (window.location.href.indexOf("login.aspx") > -1) {
          window.location.href = "/";
        }
        // Do something with the tokenResponse
      }
    })
    .catch((err) => {
      // Handle error
      localStorage.clear();
      msalInstance.logoutRedirect();
      console.error(err);
    });

  // ReactHeap.initialize('1667436808');

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    //   <React.StrictMode>
    <Provider store={store}>
      <GlobalLoader />
      <App pca={msalInstance} />
    </Provider>
    //   </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
