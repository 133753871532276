import { useEffect } from 'react';
import { useParams} from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';
import { checkExpiry } from '../../../store/api/apiSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { b2cPolicies } from '../../../authConfig';

export const CompleteSetup = () => {
    const { instance } = useMsal();

        const { email } = useParams() as { email: string };
        const dispatch = useAppDispatch();
        const { t } = useTranslation();
        useEffect(() => {
            localStorage.setItem('setupEmail', email);
            if(instance) {
                verifyToken()
            }
            
        }, []);
        const   verifyToken = () =>{
            try {
                let data = {
                    email:window.atob(localStorage.getItem("setupEmail") as string)
                  };
            dispatch(checkExpiry(data)).then(async (result: any) => {
               
                if(result?.payload?.data?.isValid) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.resetPass.authority,
                        scopes: [],
                        loginHint: localStorage.getItem("setupEmail")
                        ? window.atob(localStorage.getItem("setupEmail") as string)
                        : "",
                    };
                       await instance.loginRedirect(resetPasswordRequest);
                } else {
                    toast.error(<>{t("LINK_EXPIRE")}</>);
                    setTimeout(() => {
                         instance.logoutRedirect();
                     }, 2000);
                }
            });
        } catch (error) { }
         
        }


    return (
        <></>
    );
};

export default CompleteSetup;

