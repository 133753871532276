import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useState, useCallback, forwardRef, useImperativeHandle } from "react";
import GridFilterModal from "./gridFilterModal";
import Select from "react-select";

const GridHeader = forwardRef((props: any, ref) => {
  const [show, setShow] = useState(false);

  useImperativeHandle(ref, () => ({
    handleClose() {
      handleClose();
    },
  }));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const monthsFilter = [
    {
      id: "3",
      value: "3 Months",
    },
    {
      id: "6",
      value: "6 Months",
    },
    {
      id: "9",
      value: "9 Months",
    },
    {
      id: "12",
      value: "12 Months",
    },
  ];
  const options: any = [];

  monthsFilter &&
    monthsFilter.map((item: any) =>
      options.push({
        value: item.id,
        label: item.value,
      })
    );

  const clearData = () => {
    props.onClearFilter();
    let searchText = document.getElementById(props.searchId ? props.searchId : "filter-text-box") as any;
    if (searchText) {
      searchText.value = "";
    }
  };
  return (
    <div className={`content_body_head`}>
      <Row className="justify-content-between">
        <Col sm={4} lg={7}></Col>
        <Col sm={8} lg={5}>
          <div className={`form_box ${props.showProductTour ? "two" : ""}`}>
            <Row className="justify-content-end gx-2">
              {props.kitFilter ? (
                <Col sm={3}>
                  <Form.Group className="mb-3" controlId="">
                    {options && (
                      <Select
                        required
                        options={options}
                        onChange={(e) => props.kitFilterChange(e)}
                      />
                    )}
                  </Form.Group>
                </Col>
              ) : (
                <></>
              )}
              {props.hidesearch ? (
                <></>
              ) : (
                <Col xl={6} md={8} xs="auto">
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">
                      <i className="fa-solid fa-search"></i>
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="Search"
                      size="sm"
                      name="filter"
                      id={props.searchId ? props.searchId : "filter-text-box"}
                      autoComplete="off"
                      onInput={props.globalSearch}
                    />
                  </InputGroup>
                </Col>
              )}
              <Col xs="auto">
                <Button
                  variant="outline-dark"
                  title="Clear all filters"
                  size="sm"
                  onClick={clearData}
                >
                  <div className="icon-filter-clear">
                    <img
                      src="../../assets/images/filter-circle-xmark.svg"
                      alt=""
                    />
                  </div>
                </Button>
              </Col>
              <Col xs="auto">
                {props.onExport ? (
                  <Button
                    variant="outline-dark"
                    size="sm"
                    onClick={props.onExport}
                    title="Export"
                  >
                    <i className="fa-solid fa-file-excel"></i>
                  </Button>
                ) : (
                  <></>
                )}
              </Col>
              <Col xs="auto">
                {props.gridfilterbutton !== false ? (
                  <Button
                    variant="outline-dark"
                    title="Cutomize Columns"
                    size="sm"
                    onClick={handleShow}
                  >
                    <i className="fa-solid fa-sliders"></i>
                  </Button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {show &&
        <GridFilterModal
          show={show}
          defaultColumns={props.defaultColumns}
          handleClose={handleClose}
          columnDefs={props.columnDefs}
          handleSave={props.handleSave}
        >
        </GridFilterModal>
      }
    </div>
  );
});

export default GridHeader;
