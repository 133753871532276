import { configureStore } from '@reduxjs/toolkit'
import apiSlice from './api/apiSlice';
import accountsSlice from './slice/hierarchy'
const reducer = {
  auth: apiSlice,
  accounts: accountsSlice
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;
