import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

function NdcDetailsModal(props: any) {
  const [data, setData]: any = useState({
    ndc: "",
    product: "",
    deaClass: null,
  });
  const [errorNDCMessage, setErrorNDCMessage] = useState("");
  const [errorDEAMessage, setErrorDEAMessage] = useState("");

  const inputChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "ndc") {
      if (value.length > 11 || value.length < 11) {
        setErrorNDCMessage("NDC number length should be 11");
      } else {
        setErrorNDCMessage("");
        setData((values: any) => ({ ...values, [name]: value }));
      }
    } else if (name === "deaClass") {
      if (value > 5 || value < 0) {
        setErrorDEAMessage("DEA Class value should be between 0-5");
      } else {
        setErrorDEAMessage("");
        setData((values: any) => ({ ...values, [name]: value }));
      }
    } else {
      setData((values: any) => ({ ...values, [name]: value }));
    }
  };
  return (
    <Modal
      show={props.detailModalShow}
      onHide={props.handleClose}
      className="custom_modal"
    >
      <Modal.Header className="custom_modal_header">
        <Modal.Title className="custom_modal_header_title">
          <div className="custom_modal_header_title_left">NDC Details</div>
          <div className="custom_modal_header_title_right">
            <Button variant="link" onClick={props.handleClose}>
              <i className="fa-solid fa-times"></i>
            </Button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom_modal_body">
        <Form className="form_box">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
            <Form.Label>NDC</Form.Label>
            <Form.Control
              type="number"
              placeholder=""
              name="ndc"
              maxLength={11}
              onChange={(e) => inputChange(e)}
              onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
            />
            {errorNDCMessage && (
              <p className="error-validation">{errorNDCMessage}</p>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
            <Form.Label>Product</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="product"
              onChange={(e) => inputChange(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
            <Form.Label>DEA Class</Form.Label>
            <Form.Control
              type="number"
              placeholder=""
              name="deaClass"
              min={0}
              max={5}
              onChange={(e) => inputChange(e)}
              onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
            />
            {errorDEAMessage && (
              <p className="error-validation">{errorDEAMessage}</p>
            )}
          </Form.Group>
        </Form>
        {props.errorMessage && (
          <p className="error-validation">{props.errorMessage}</p>
        )}
      </Modal.Body>
      <Modal.Footer className="custom_modal_footer">
        <Button
          variant="primary"
          onClick={(e) => props.handleSave(e, data)}
          disabled={
            errorDEAMessage === "" && errorNDCMessage === "" ? false : true
          }
        >
          Save
        </Button>
        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NdcDetailsModal;
