// @ts-nocheck

import { Controller, useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";

import DropdownTreeSelect from "react-dropdown-tree-select";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/esm/Form";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Select from "react-select";
import 'react-dropdown-tree-select/dist/styles.css'
import { useAppDispatch } from "../../../../../store/hooks";
import { getAccountsForUser, editUser, GetAccountHierarchy } from "../../../../../store/api/apiSlice";
import { USER_ROLES } from "../../../../../interface/comman.constant";
import DropdownComponent from "./DropdownComponent";
import { cloneDeep, difference } from "lodash";

function removeEmptyChildren(obj: any): any {
  // Helper function to clone objects without references
  function deepClone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  // Recursively remove empty children arrays
  function removeEmpty(obj: any): any {
    // Base case: If obj is an array, filter out empty children arrays
    if (Array.isArray(obj)) {
      return obj.map(item => {
        if (item.children && item.children.length === 0) {
          const { children, ...rest } = item;
          return rest;
        } else {
          return removeEmpty(item);
        }
      });
    }
    // If obj is an object, iterate over its properties
    else if (typeof obj === 'object' && obj !== null) {
      let result: any = {};
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          // Recursively remove empty children arrays
          if (key === 'children' && Array.isArray(obj[key]) && obj[key].length === 0) {
            continue; // Skip empty children array
          } else {
            result[key] = removeEmpty(obj[key] as any); // Type assertion for TypeScript
          }
        }
      }
      return result;
    }
    // If obj is neither array nor object, return as is
    return obj;
  }

  // Deep clone the input object to avoid modifying the original
  let clonedObj = deepClone(obj);

  // Call the recursive function to remove empty children arrays
  return removeEmpty(clonedObj);
}

export function EditUser({ handleModalClose, show, userData, refreshList }: any) {
  const { getValues, setValue, register, formState: { errors }, control, handleSubmit, reset, clearErrors, setError } = useForm({ defaultValues: { firstName: '', lastName: '', email: '', phone: '', role: {}, account: [] } });
  const [isTouched, setIsTouched] = useState(false);
  const [accountsData, setAccountsData] = useState<any>([]);
  const dispatch = useAppDispatch();

  const getAllCheckedIds = () => {
    const tmpAccData = cloneDeep(accountsData);
    const selNodes = (getValues('account') || []);
    const selIds = [];

    const recursiveFn = (node) => {
      selIds.push(node._id_);

      if (node?.children) {
        (node?.children).forEach(element => recursiveFn(element));
      }
    }

    selNodes.forEach(obj => {
      const node = _.get(tmpAccData, obj.path, {});
      if (Object.keys(node).length > 0) recursiveFn(node);
    });

    return removeDuplicates(selIds);
  }

  const removeDuplicates = (arr: any) => {
    let unique = arr.reduce(function (acc: any, curr: any) {
      if (!acc.includes(curr) && curr !== 'Account') acc.push(curr);
      return acc;
    }, []);

    return unique;
  };

  const onSubmit = (data: any) => {
    if (getValues('account').length === 0) {
      setError("account", { type: "atleastOne", message: "Atleast one account needs to be selected." });
      return;
    }

    const formattedData = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.phone,
      accountIds: isTouched ? getAllCheckedIds() : (userData?.accountIds || []),
      userRole: data?.role?.value,
      id: userData?.id
    }

    editUserApiCall(formattedData);
  }

  const editUserApiCall = async (apiData) => {
    dispatch(editUser(apiData)).then((result: any) => {
      refreshList();
      closeModal();
    });
  }

  const onError = (errors: any) => {
    if (getValues('account').length === 0) {
      setError("account", { type: "atleastOne", message: "Atleast one account needs to be selected." });
    }
  }

  const onAccountChange = (currentNode: any, selectedNodes: any) => {
    setIsTouched(true);
    setValue('account', selectedNodes);
  };

  const closeModal = () => {
    handleModalClose();
    reset();
  }

  useEffect(() => {
    if (userData) {
      function getAccounts() {
        const assignObjectPaths = (obj, stack) => {
          const isArray = Array.isArray(obj);
          Object.keys(obj).forEach((k) => {
            const node = obj[k];
            const key = isArray ? `[${k}]` : k;

            if (typeof node === "object") {
              if (k !== 'children') {
                const id = (node.value.split(':')[0]).split(',');
                const length = (id?.length || 0);

                if (length > 1) {
                  node._id_ = id[length - 1];
                } else {
                  node._id_ = id[0]
                }

                if ((userData?.accountIds || []).includes(parseInt(node._id_)))
                  node.checked = true;
              }

              node.path = stack ? `${stack}.${key}` : key;

              assignObjectPaths(node, node.path);
            }
          });

          return obj;
        };

        dispatch(GetAccountHierarchy({})).then((result: any) => {
          let modifiedData = removeEmptyChildren([result?.payload?.data]);

          assignObjectPaths(modifiedData);

          setAccountsData(modifiedData);
        });
      }

      getAccounts();

      setValue('firstName', userData?.firstName);
      setValue('lastName', userData?.lastName);
      setValue('phone', userData?.phone);
      setValue('email', userData?.email);
      setValue('account', [...(userData?.accountIds || [])]);
      setValue('role', USER_ROLES.find((u) => userData?.userRole === u.value));
    }
  }, [userData]);

  return (
    <Modal show={show === 'edit'} onHide={handleModalClose} className="custom_modal">
      <Form
        id="form-create-user"
        className="form_box create_user"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Modal.Header className="custom_modal_header">
          <Modal.Title className="custom_modal_header_title">
            <div className="custom_modal_header_title_left">
              Edit User
            </div>
            <div className="custom_modal_header_title_right">
              <Button variant="link" onClick={closeModal}>
                <i className="fa-solid fa-times"></i>
              </Button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom_modal_body">

          <Row>
            <Col md={6}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" placeholder="" {...register("firstName", { required: true, maxLength: 20 })} />
                {errors.firstName?.type === "required" && (
                  <p className="error-validation">
                    First name is required.
                  </p>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" placeholder="" {...register("lastName", { required: true, maxLength: 20 })} />
                {errors.lastName?.type === "required" && (
                  <p className="error-validation">
                    Last name is required.
                  </p>
                )}
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput3"
              >
                <Form.Label>Email</Form.Label>
                <Form.Control type="text" placeholder="" {...register("email", { required: true, pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i })} />
                {errors.email && (
                  <p className="error-validation">
                    {errors.email?.type === "required" ? (
                      'Email is required.'
                    ) : (
                      'Email is invalid.'
                    )}
                  </p>
                )}

              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput4"
              >
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="text" placeholder="" {...register("phone", { pattern: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/ })} />
                {errors?.phone?.type === "pattern" && (
                  <p className="error-validation">
                    Phone number is invalid, please use (123) 123-1234 format!
                  </p>
                )}
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput5"
              >
                <Form.Label>Account</Form.Label>

                <DropdownComponent
                  data={accountsData}
                  onChange={onAccountChange}
                  inlineSearchInput={true}
                  className="bootstrap-demo"
                />
                {errors?.account?.type === 'atleastOne' && (
                  <p className="error-validation">
                    Please select atleast one account.
                  </p>
                )}
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput6"
              >
                <Form.Label>User Role</Form.Label>

                <Controller
                  control={control}
                  name="role"
                  rules={{ validate: { selectOne: v => Object.keys(v).length > 0 } }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={USER_ROLES}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                {errors?.role?.type === 'selectOne' && (
                  <p className="error-validation">
                    Please select atleast one role.
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="custom_modal_footer">
          <Button type="submit" variant="primary">
            Save
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}