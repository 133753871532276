
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalConfig, loginRequest, loginRequestAccess } from "../../authConfig";
import { msalInstance } from "../../index";
export default async function getToken(): Promise<string> {
  const account: any = msalInstance.getActiveAccount();
  if (account) {
    const response: any = await msalInstance.acquireTokenSilent({
      ...loginRequestAccess,
      account: account
    }).catch((error: any) => {
      if (error instanceof InteractionRequiredAuthError) {
        msalInstance.logoutRedirect();
        localStorage.clear();
      }
    });
    return response.accessToken
  } else {
    return '';
  }
  // return response.idToken      
}