import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "../../../../src/services/httpService";
import DragDropComponent from "../../../../src/pages/admin/serviceModule/startReturn/dragDropComponent";

import { toast } from "react-toastify";
import { defaultErrorMessage } from "../../../interface/comman.constant";

function PortalTraning(props: any) {
  const [file, setFile]: any = useState();
  const [showError, setShowError]: any = useState();

  const API_URL = axios.Api;
  const USERMANAGEAPI = axios.UserManageAPI || "";

  const importButtonClick = () => {
    if (file !== undefined && showError === false) {
      //POST API call
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(
          API_URL + USERMANAGEAPI + `Company/UploadPortalTraining`,
          file,
          config
        )
        .then((response) => {
          // handle the response
          toast.success("Saved Successfully");
          props.handleClose();
        })
        .catch((error) => {
          // handle errors
          toast.error(defaultErrorMessage);
        });
    }
  };

  const fileUploadChange = (e: any) => {
    if (e[0].name.split(".").pop() !== "pdf") {
      setShowError(true);
    } else {
      const formData = new FormData();
      formData.append("file", e[0]);
      setFile(formData);
      setShowError(false);
    }
  };

  return (
    <Modal show={props.show} className="custom_modal">
      <Modal.Header className="custom_modal_header">
        <Modal.Title className="custom_modal_header_title">
          <div className="custom_modal_header_title_left">
            Upload Portal Training
          </div>
          <div className="custom_modal_header_title_right">
            <Button variant="link" onClick={props.handleClose}>
              <i className="fa-solid fa-times"></i>
            </Button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom_modal_body">
        <>
          <DragDropComponent
            type={".pdf"}
            fileUploadChange={fileUploadChange}
            fileSelectMessage={file === undefined ? "No file selected" : ""}
            fileErrorMessage={
              showError ? "Invalid file type only supports '.pdf'" : ""
            }
          ></DragDropComponent>
        </>
      </Modal.Body>
      <Modal.Footer className="custom_modal_footer">
        <Button variant="primary" onClick={importButtonClick}>
          Add
        </Button>

        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PortalTraning;
