import { Button, Col, Collapse, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { usePermission } from "../../../../helpers/hooks/usePermissions";

export const ViewAllReports = () => {
  const permissions = usePermission(true);
  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  let navigate = useNavigate();
  return (
    <>
      <AuthenticatedTemplate>
        <Container fluid>
          <div className="content_header d-flex justify-content-between align-items-center">
            <div className="header_left">
              <h5 className="content_header_title">Reports</h5>
            </div>
          </div>
          <div className="content_body">
            <div className="collaps_wrap">
              <div className="collaps_inner">
                <div className="collaps_item">
                  <Button
                    variant="link"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="collaps_item_title"
                  >
                    Compliance Reports
                    <i className="fa-solid fa-chevron-down"></i>
                  </Button>
                  <Collapse in={open} className="collaps_item_desc">
                    <Row className="gx-3 gy-3 gy-lg-0">
                      {permissions?.['222FormDetail'] && (
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          onClick={(e) => navigate("/reports/form-222")}
                        >
                          <div className="report_btn_box">
                            <div
                              className="report_btn_box_title"
                              onClick={(e) => navigate("/reports/form-222")}
                            >
                              222 Form Detail
                            </div>
                            <div className="report_btn_box_icon">
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          </div>
                        </Col>
                      )}
                      {permissions?.['Form41Report'] && (
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          onClick={(e) => navigate("/reports/form-41")}
                        >
                          <div className="report_btn_box">
                            <div
                              className="report_btn_box_title"
                              onClick={(e) => navigate("/reports/form-41")}
                            >
                              Form 41 Report
                            </div>
                            <div className="report_btn_box_icon">
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          </div>
                        </Col>
                      )}
                      {permissions?.['EPAComplianceNotificationReport'] && (
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          onClick={(e) => navigate("/reports/epa-compliance")}
                        >
                          <div className="report_btn_box">

                            <div
                              className="report_btn_box_title"
                              onClick={(e) => navigate("/reports/epa-compliance")}
                            >
                              EPA Compliance Notification
                            </div>
                            <div className="report_btn_box_icon">
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          </div>
                        </Col>
                      )}
                      {/* <Col xs={12} md={4} lg={3}>
                        <div className="report_btn_box">
                          <div className="report_btn_box_title">
                            Control Discrepancy Report
                          </div>
                          <div className="report_btn_box_icon">
                            <i className="fa-solid fa-chevron-right"></i>
                          </div>
                        </div>
                      </Col> */}
                    </Row>
                  </Collapse>
                </div>
                <div className="collaps_item">
                  <Button
                    variant="link"
                    onClick={() => setOpen2(!open2)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open2}
                    className="collaps_item_title"
                  >
                    Financial Reports
                    <i className="fa-solid fa-chevron-down"></i>
                  </Button>
                  <Collapse in={open2} className="collaps_item_desc">
                    <Row className="gx-3 gy-3 gy-lg-0">
                      {/* <Col xs={12} md={4} lg={3}>
                        <div className="report_btn_box">
                          <div className="report_btn_box_title">
                           Order Summary
                          </div>
                          <div className="report_btn_box_icon">
                            <i className="fa-solid fa-chevron-right"></i>
                          </div>
                        </div>
                      </Col> */}
                      {permissions?.['TransactionRegisterTransactionSummary'] && (
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          onClick={(e) =>
                            navigate("/reports/transaction-summary")
                          }
                        >
                          <div className="report_btn_box">
                            <div
                              className="report_btn_box_title"
                              onClick={(e) =>
                                navigate("/reports/transaction-summary")
                              }
                            >
                              Transaction Summary
                            </div>
                            <div className="report_btn_box_icon">
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          </div>
                        </Col>
                      )}
                      {permissions?.['TransactionRegisterDisbursementSummary'] && (
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          onClick={(e) =>
                            navigate("/reports/disbursement-summary")
                          }
                        >
                          <div className="report_btn_box">
                            <div
                              className="report_btn_box_title"
                              onClick={(e) =>
                                navigate("/reports/disbursement-summary")
                              }
                            >
                              Disbursement Summary
                            </div>
                            <div className="report_btn_box_icon">
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          </div>
                        </Col>
                      )}
                      {permissions?.['TransactionRegisterInvoiceSummary'] && (
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          onClick={(e) => navigate("/reports/invoice-summary")}
                        >
                          <div className="report_btn_box">
                            <div
                              className="report_btn_box_title"
                              onClick={(e) =>
                                navigate("/reports/invoice-summary")
                              }
                            >
                              Invoice Summary
                            </div>
                            <div className="report_btn_box_icon">
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          </div>
                        </Col>
                      )}
                      {permissions?.['TransactionRegisterServiceFeeGuaranteesSummary'] && (
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          onClick={(e) =>
                            navigate("/reports/servicefeeguarantees")
                          }
                        >
                          <div className="report_btn_box">
                            <div
                              className="report_btn_box_title"
                              onClick={(e) =>
                                navigate("/reports/servicefeeguarantees")
                              }
                            >
                              Service Fee Guarantee
                            </div>
                            <div className="report_btn_box_icon">
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Collapse>
                </div>
                <div className="collaps_item">
                  <Button
                    variant="link"
                    onClick={() => setOpen3(!open3)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open3}
                    className="collaps_item_title"
                  >
                    Return Reports
                    <i className="fa-solid fa-chevron-down"></i>
                  </Button>
                  <Collapse in={open3} className="collaps_item_desc">
                    <Row className="gx-3 gy-3 gy-lg-0">
                      {permissions?.['OrderHistory'] && (
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          onClick={(e) => navigate("/reports/order-history")}
                        >
                          <div className="report_btn_box">
                            <div
                              className="report_btn_box_title"
                              onClick={(e) => navigate("/reports/order-history")}
                            >
                              Order History
                            </div>
                            <div className="report_btn_box_icon">
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          </div>
                        </Col>
                      )}
                      {permissions?.['DrugLiquidationOrderHistory'] && (
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          onClick={(e) => navigate("/reports/drug-liquidation-order-history")}
                        >
                          <div className="report_btn_box">
                            <div
                              className="report_btn_box_title"
                              onClick={(e) => navigate("/reports/drug-liquidation-order-history")}
                            >
                              Drug Liquidation Order History
                            </div>
                            <div className="report_btn_box_icon">
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          </div>
                        </Col>
                      )}
                      {permissions?.['OrderLineDetail'] && (
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          onClick={(e) => navigate("/reports/order-line-details")}
                        >
                          <div className="report_btn_box">
                            <div
                              className="report_btn_box_title"
                              onClick={(e) =>
                                navigate("/reports/order-line-details")
                              }
                            >
                              Order Line Details
                            </div>
                            <div className="report_btn_box_icon">
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          </div>
                        </Col>
                      )}
                      {permissions?.['Overstock'] && (
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          onClick={(e) => navigate("/reports/overstock-summary")}
                        >
                          <div className="report_btn_box">
                            <div
                              className="report_btn_box_title"
                              onClick={(e) =>
                                navigate("/reports/overstock-summary")
                              }
                            >
                              OverStock Summary
                            </div>
                            <div className="report_btn_box_icon">
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          </div>
                        </Col>
                      )}
                      {permissions?.['TrackAShipmentReport'] && (
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          onClick={(e) => navigate("/reports/track-shipment")}
                        >
                          <div className="report_btn_box">
                            <div
                              className="report_btn_box_title"
                              onClick={(e) => navigate("/reports/track-shipment")}
                            >
                              Track a Shipment
                            </div>
                            <div className="report_btn_box_icon">
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          </div>
                        </Col>
                      )}
                      {permissions?.['UpcomingReturns'] && (
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          onClick={(e) => navigate("/reports/upcoming-returns")}
                        >
                          <div className="report_btn_box">
                            <div
                              className="report_btn_box_title"
                              onClick={(e) =>
                                navigate("/reports/upcoming-returns")
                              }
                            >
                              Upcoming Service Events
                            </div>
                            <div className="report_btn_box_icon">
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          </div>
                        </Col>
                      )}
                      {permissions?.['InventoryReports'] && (
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          onClick={(e) => navigate("/reports/inventory-reports")}
                        >
                          <div className="report_btn_box">
                            <div
                              className="report_btn_box_title"
                              onClick={(e) =>
                                navigate("/reports/inventory-reports")
                              }
                            >
                              Inventory Reports
                            </div>
                            <div className="report_btn_box_icon">
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          </div>
                        </Col>
                      )}
                      {permissions?.['DisposalReturns'] && (
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          onClick={(e) => navigate("/reports/disposal-returns")}
                        >
                          <div className="report_btn_box">
                            <div
                              className="report_btn_box_title"
                              onClick={(e) =>
                                navigate("/reports/disposal-returns")
                              }
                            >
                              Disposal Returns
                            </div>
                            <div className="report_btn_box_icon">
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          </div>
                        </Col>
                      )}
                      {permissions?.['DestructionManifests'] && (
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          onClick={(e) =>
                            navigate("/reports/destruction-manifests")
                          }
                        >
                          <div className="report_btn_box">
                            <div
                              className="report_btn_box_title"
                              onClick={(e) =>
                                navigate("/reports/destruction-manifests")
                              }
                            >
                              Destruction Manifests
                            </div>
                            <div className="report_btn_box_icon">
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          </div>
                        </Col>
                      )}
                      {permissions?.['MonthyAccountSummaryStatement'] && (
                        <Col
                          xs={12}
                          md={4}
                          lg={3}
                          onClick={(e) => navigate("/reports/account-statement")}
                        >
                          <div className="report_btn_box">
                            <div
                              className="report_btn_box_title"
                              onClick={(e) =>
                                navigate("/reports/account-statement")
                              }
                            >
                              Monthly Account Summary/Statement
                            </div>
                            <div className="report_btn_box_icon">
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="claim-bg"></div>
      </UnauthenticatedTemplate>
    </>
  );
};

export default ViewAllReports;
