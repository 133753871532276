// @ts-nocheck
import React, { Component } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import isEqual from 'lodash/isEqual'

export default class DropdownComponent extends Component {
  constructor(props: any) {
    super(props)
    this.state = { data: props.data };
  }

  componentWillReceiveProps = (nextProps: any) => {
    if (!isEqual(nextProps.data, this.state.data)) {
      this.setState({ data: nextProps.data })
    }
  }

  shouldComponentUpdate = (nextProps: any) => {
    return !isEqual(nextProps.data, this.state.data)
  }

  render() {
    const { data, ...rest }: any = this.props

    return (
      <DropdownTreeSelect data={this.state.data} {...rest} />
    )
  }
}
