// @ts-nocheck
import { toast } from "react-toastify";
import axios from "../../../services/httpService";
import getToken from "../../../store/api/api";
import { defaultErrorMessage, formateIds } from "../../../interface/comman.constant";
import { hideLoader, showLoader } from "../GlobalLoader";

async function fileDownloaderPost(urlEndpoint: any, params: any) {
  const API_URL = axios.Api;

  const REPORTAPI = axios.ReportAPI || "";

  try {
    let data = {
      fileName: params.data.fileName,
      manifestNumber: params.data.manifestNumber
    };
    showLoader();
    const azureB2C = localStorage.getItem('AzureB2CImpersonate');

    fetch(API_URL + REPORTAPI + urlEndpoint, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (await getToken()),
        ...(((azureB2C) && { UserImpersonated: azureB2C }) || {})
      },
      body: JSON.stringify(data),
    })
      .then(async (response: any) => {
        let fileName: any = params.data.manifestNumber;
        // Get the content type from the headers
        const contentType = response.headers.get('Content-Type');
        console.log('Content-Type:', contentType);
        hideLoader();
        // Process response data based on content type
        if (contentType && contentType === 'application/pdf') {
          return response.blob().then(blob => {
            const fileURL = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = fileURL;
            a.download = fileName; // keep the original extension
            document.body.appendChild(a);
            a.click();
            hideLoader();
            a.remove();
          });
        } else {
          return response.blob().then(blob => {
            const fileURL = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = fileURL;
            a.download = fileName+'.jpg'; // change the extension to .jpg
            document.body.appendChild(a);
            a.click();
            hideLoader();
            a.remove();
          });
        }
        
      })
      .catch((err) => {
        hideLoader();
        toast.error(defaultErrorMessage);
      });
  } catch (error) {
    hideLoader();
  }
}
export default fileDownloaderPost;
