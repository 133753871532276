import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en', 
    // we init with resources
    resources: {
   
      en: {
        translations: {
          
          'Hi':'Enter',
          'PROFILE_UPDATED_SUCCESSFULLY':"Profile updated successfully.",
          'MY_PROFILE':'My Profile',
          'LINK_EXPIRE':'The Link You Followed Has Expired.',
          'NO_NEW_NOTIFICATION':'There are no more notifications to display.'

      }
      }
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
