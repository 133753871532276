import React from "react";
import { Button, Modal } from "react-bootstrap";

function GlobalPopup(props: any) {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      dialogClassName="modal-30w"
      className="custom_modal confirm_modal"
      backdropClassName="confirm_modal_backdrop"
      centered={true}
    >
      <Modal.Body className="custom_modal_body">
        <div className="d-flex justify-content-center align-items-center gap-3 flex-column py-2">
          <p className="pt-2">{props.message}</p>
          {props.beforeRegistration ?
            <div>
              <Button variant="primary" onClick={props.handleSave}>
                Logout
              </Button>
              <Button variant="primary" onClick={props.handleClose}>
                Back
              </Button>
            </div>
            :
            props.doneButton ? (
              <Button variant="primary" onClick={props.handleClose}>
                Done
              </Button>
            ) : (
              <div>
                <Button variant="primary" onClick={props.handleSave}>
                  Yes
                </Button>
                <Button variant="secondary" onClick={props.handleClose}>
                  No
                </Button>
              </div>
            )
          }
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default GlobalPopup;
