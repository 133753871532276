import React from "react";
import { Col } from "react-bootstrap";
import { usePermission } from "../../helpers/hooks/usePermissions";

const PermissionOverlay = (props: any) => {
  const permissions = usePermission(true);

  return (
    (Object.keys(permissions).length <= 1 && props?.loader) ? (
      props.isGraph ?
        props?.loader
        :
        <div className="kpi-box">
          {props?.loader}
        </div>
    ) : (
      (permissions?.[props?.permissions]) ? (
        props.isGraph ?
          props?.children
          :
          <div className="kpi-box">
            {props?.children}
          </div>
      ) : (
        null
        // <div className={props.isGraph ? "no-data-box graph-box" : "no-data-box kpi-box"}>
        //   <div className={props.className}></div>
        //   <div className="no-data-overlay">
        //     <h3>{props.title}</h3>
        //     <div className="text-denied">
        //       <i className="fa-solid fa-lock me-1"></i> Permission required
        //     </div>
        //   </div>
        // </div>
      )
    )
  );
};

export default PermissionOverlay;
