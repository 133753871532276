import { AuthenticatedTemplate } from "@azure/msal-react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { usePermission } from "../../../../helpers/hooks/usePermissions";
import { APP_BUILD_VERSION } from "../../../../interface/comman.constant";

export const Footer = () => {
  const permissions = usePermission(true);
  const location = useLocation();
  const productRedirect = window.location.href;

  return (
    <AuthenticatedTemplate>
      <footer className="footer" id="footer">
        <Container className="footer_inner" fluid>
          <div className="footer_left">Copyright © 2024 Pharma Logistics. {APP_BUILD_VERSION}</div>
          <div className="footer_right">
            {permissions?.["LinkLegal"] && (
              <>
                <Link
                  target="_blank"
                  to={process.env.REACT_APP_AZURE_CONTAINER+
                    "/legal.html?" +
                    "env=" +
                    window.btoa(productRedirect.split("//")[1].split(".")[0])
                  }
                >
                  Legal
                </Link>
                •
              </>
            )}
            {permissions?.["LinkPrivacyPolicy"] && (
              <>
                <Link
                  target="_blank"
                  to={ process.env.REACT_APP_AZURE_CONTAINER+
                    "/privacy-policy.html?" +
                    "env=" +
                    window.btoa(productRedirect.split("//")[1].split(".")[0])
                  }
                >
                  Privacy Policy
                </Link>
                •
              </>
            )}
            {permissions?.["LinkTermsOfSerivce"] && (
              <>
                <Link
                  target="_blank"
                  to={ process.env.REACT_APP_AZURE_CONTAINER+
                    "/terms-conditions-full-page.html?" +
                    "env=" +
                    window.btoa(productRedirect.split("//")[1].split(".")[0])
                  }
                >
                  Terms of Service
                </Link>
              </>
            )}
          </div>
        </Container>
      </footer>
    </AuthenticatedTemplate>
  );
};

export default Footer;
