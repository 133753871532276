import { useMsal } from "@azure/msal-react";
import { useEffect, useMemo, useState } from "react";
import { Button, Container, Navbar, Modal } from "react-bootstrap";
import DragDropComponent from "../../admin/serviceModule/startReturn/dragDropComponent";
import ReportIssue from "../../shared/help/reportIssue";
import GlobalPopup from "../../shared/GlobalPopup/globalPopup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const ValidateRegistrationPopup = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [storageTimer, setStorageTimer] = useState(false);
  const [timer, setTimer] = useState(120);
  const handleSuccessClose = () => setSuccessShow(false);
  const { instance } = useMsal();
  const userRegistrationDetails = useSelector((state: any) => state?.auth?.registrationDetails) || {};

  useEffect(() => {
    let timerObj: any = null;
    let storageTimer: any = null;

    function timerFn() {
      if (parseInt(window.localStorage.getItem('loader') ?? '0') === 0) {
        setStorageTimer(true);
        clearInterval(storageTimer);
      }
    }


    if (parseInt(window.localStorage.getItem('RegistrationTimer') ?? '1')) {
      storageTimer = setInterval(timerFn, 1000);

      if (storageTimer) {
        timerObj = setInterval(() => {
          if (timer === 0) {
            window.location.href = `${process.env.REACT_APP_NETSUITE_URL}&cn=${userRegistrationDetails?.customerId}&form=${userRegistrationDetails?.registrationId}&toast=${userRegistrationDetails?.toast}`;
            localStorage.setItem('RegistrationTimer', '0');
          }

          else setTimer((timer) => timer - 1);
        }, 1000);
      }
    }

    return () => { clearInterval(timerObj); clearInterval(storageTimer) };
  }, [timer, storageTimer]);

  const handleLogoutRedirect = async () => {
    if (localStorage.getItem('isAdmin') === 'true') {
      const resetPasswordRequest = {
        authority: 'https://login.microsoftonline.com/common/oauth2/v2.0/logout',
        scopes: [],
      };
      localStorage.clear();
      localStorage.setItem('logoutFromAll', Date.now().toString());
      await instance.logoutRedirect(resetPasswordRequest);

    } else {
      localStorage.removeItem("disbursementSummaryCol");
      localStorage.removeItem("invoiceSummaryCol");
      localStorage.removeItem("sfgSummaryCol");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("permissions");
      localStorage.removeItem("userSettings");
      localStorage.removeItem("registrationDetails");
      localStorage.removeItem("AzureB2CImpersonate");
      localStorage.removeItem('ImpersonateUser');
      localStorage.removeItem("product_tour");
      const idToken = instance.getAllAccounts()[0]?.idToken;
      localStorage.clear();
      localStorage.setItem('logoutFromAll', Date.now().toString());
      const currentAccount = instance.getAccountByHomeId(process.env.REACT_APP_CLIENTID as string);
      navigate("/");
      await instance.logoutRedirect({ account: currentAccount, idTokenHint: idToken });
    }
  };

  const formattedTime = useMemo(() => {

    function padTo2Digits(num: any) {
      return num.toString().padStart(2, '0');
    }

    const totalSeconds = timer;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const result = `( ${minutes.toString().padStart(1, '0')}m ${seconds.toString().padStart(2, '0')}s )`;

    return result;

  }, [timer]);

  const timeCondition = parseInt(window.localStorage.getItem('RegistrationTimer') ?? '1') && timer && timer > 1;

  return (
    <>
      <Modal centered={true} show={true} className="custom_modal">
        <Modal.Header className="custom_modal_header">
          <Modal.Title className="custom_modal_header_title">
            <div className="custom_modal_header_title_left">
              Complete Registration
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom_modal_body">
          <>
            Welcome to the Pharma Logistics Customer Portal! We are excited to provide you with an enhanced experience where unmatched visibility, simplified returns, and actionable insights come together to support your success. Pharma Logistics is committed to a seamless transition with virtually no change or disruption for your pharmacy, backed by exceptional service and support at every step.
            <br />
            <br />
            <b>To get started, please click Proceed to sign the registration form and complete your onboarding process.</b>
            <br />
            <br />
            If you have any questions or need assistance, feel free to reach out to us by clicking "Contact Us" below.
            <br />
            <br />
            Welcome aboard!
          </>
        </Modal.Body>
        <Modal.Footer className="custom_modal_footer">
          <Button variant="primary" onClick={() => { setShow(true) }}>
            Contact Us
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_NETSUITE_URL}&cn=${userRegistrationDetails?.customerId}&form=${userRegistrationDetails?.registrationId}&toast=${userRegistrationDetails?.toast}`;
              localStorage.setItem('RegistrationTimer', '0');
            }}
          >
            Proceed {timeCondition ? formattedTime : ''}
          </Button>
        </Modal.Footer>
      </Modal>
      <ReportIssue
        beforeRegistration={true}
        show={show}
        handleClose={() => { setShow(false) }}
        setShow={setShow}
        setSuccessShow={setSuccessShow}
      ></ReportIssue>
      <GlobalPopup
        beforeRegistration={true}
        show={successShow}
        showImage={true}
        handleSave={handleLogoutRedirect}
        handleClose={(e: any) => handleSuccessClose()}
        message={"Your issue has been successfully submitted. Our Customer Service team will contact you shortly."}
      ></GlobalPopup>
    </>
  );
};

export default ValidateRegistrationPopup;