import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  ModalFooter,
  Row,
} from "react-bootstrap";
import CheckboxTree from 'react-checkbox-tree';
import { useAppDispatch } from "../../../store/hooks";
import { GetAccountHierarchy } from "../../../store/api/apiSlice";
import { setSelectedAccounts, setRefreshPage } from "../../../store/slice/hierarchy";
import { useSelector } from "react-redux";
import Select from "react-select";
import { CollectionOption, CustomerReturnProgram, defaultNoRecordFound, ServiceOffering,internalAdminRole } from "../../../interface/comman.constant";
import { Link } from "react-router-dom";
function AccountHierarchy(props) {
  const userAccounts = useSelector((state) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage = useSelector((state) => state?.accounts?.refreshPage) || false;
  const role = useSelector((state) => state?.auth?.permissions?.role);

  const [checked, setChecked] = useState(userAccounts);
  const [expanded, setExpanded] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [nodesData, setNodedata] = useState([])
  const accountRef = React.useRef();
  const [filteredNodes, setFilteredNodes] = useState(nodesData);
  const [selectReturnProgramOption, setSelectReturnProgramOption] = useState(null);
  const [selectServiceOfferingOption, setSelectServiceOfferingOption] = useState(null);
  const [selectCollectionOption, setSelectCollectionOption] = useState(null);


  const dispatch = useAppDispatch();

  const filterNodes = (filtered, node) => {
    const children = (node.children || []).reduce(filterNodes, []);
    if (
      node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1 ||
      children.length
    ) {
      filtered.push({ ...node, children });
    }
    return filtered;
  };

  const filterTree = () => {
    // Reset nodes back to unfiltered state
    if (!filterText) {
      setFilteredNodes(nodesData)
      return;
    }
    setFilteredNodes(nodesData.reduce(filterNodes, []));
  }
  const onChange = (e) => {
    setFilterText(e.target.value)
  };

  useEffect(() => {
    refreshData("")
  }, []);
  // useEffect(() => {
  //   refreshData("filter");
  // }, [selectReturnProgramOption,selectServiceOfferingOption,selectCollectionOption]);

  const resetPage = () => {
    dispatch(setRefreshPage(!refreshPage))
    props.handleClose()
  }

  function removeEmptyChildren(obj) {
    // Helper function to clone objects without references
    function deepClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    }

    // Recursively remove empty children arrays
    function removeEmpty(obj) {
      // Base case: If obj is an array, filter out empty children arrays
      if (Array.isArray(obj)) {
        return obj.map(item => {
          if (item.children && item.children.length === 0) {
            const { children, ...rest } = item;
            return rest;
          } else {
            return removeEmpty(item);
          }
        });
      }
      // If obj is an object, iterate over its properties
      else if (typeof obj === 'object' && obj !== null) {
        let result = {};
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            // Recursively remove empty children arrays
            if (key === 'children' && Array.isArray(obj[key]) && obj[key].length === 0) {
              continue; // Skip empty children array
            } else {
              result[key] = removeEmpty(obj[key] ); // Type assertion for TypeScript
            }
          }
        }
        return result;
      }
      // If obj is neither array nor object, return as is
      return obj;
    }

    // Deep clone the input object to avoid modifying the original
    let clonedObj = deepClone(obj);

    // Call the recursive function to remove empty children arrays
    return removeEmpty(clonedObj);
  }
  const getNodeIds = (nodes) => {
    let ids= [];
    nodes?.forEach(({ value, children }) => {
      ids = [...ids, value];
    });
    return ids;
  };
  
  const refreshData = (from) => {
    try {
      let filter = {
        returnProgram: selectReturnProgramOption,
        serviceOffering: selectServiceOfferingOption,
        collectionOption: selectCollectionOption
      }
      dispatch(GetAccountHierarchy(filter)).then((result) => {
        let modifiedData = removeEmptyChildren([result?.payload?.data]);
        setNodedata(modifiedData);
        setFilteredNodes(modifiedData);
        setExpanded(getNodeIds(modifiedData))
        if (userAccounts.length === 0 && from != "filter") {
          setTimeout(() => {
            var link = document?.querySelector('[id*="-Account"]');
            if(link){
              link.click();
            }
          }, 100);
        }       
      });
    } catch (error) {

    }
  }
  useEffect(() => {
    filterTree();
  }, [filterText]);

  const selectReturnProgram = (e) => {
    setSelectReturnProgramOption(e.value)
  }
  const selectServiceOffering = (e) => {
    setSelectServiceOfferingOption(e.value)
  }
  const selectCollection = (e) => {
    setSelectCollectionOption(e.value)
  }
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className="custom_modal acc-listing-modal"
    >
      <Modal.Header className="custom_modal_header">
        <Modal.Title className="custom_modal_header_title">
          <div className="custom_modal_header_title_left">Select Account</div>
          <div className="custom_modal_header_title_right">
            <Button variant="link" onClick={props.handleClose}>
              <i className="fa-solid fa-times"></i>
            </Button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom_modal_body">
        {nodesData && nodesData.length > 0 &&
          <>
            <Form className="form_box">
              <Row>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label> Service Offering</Form.Label>
                    <Select
                      defaultValue={{
                        value: "",
                        label: "All",
                      }}
                      options={(ServiceOffering)}
                      onChange={(e) => selectServiceOffering(e)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label> Collection Option</Form.Label>
                    <Select
                      defaultValue={{
                        value: "",
                        label: "All",
                      }}
                      options={(CollectionOption)}
                      onChange={(e) => selectCollection(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              
              <Row>
              <Col md={12}>
              <Form.Group>
              <Form.Label> Return Program(Custom)</Form.Label>
                </Form.Group>
                </Col>
                <Col md={8}>
                <Form.Group className="mb-3">
                
                <Select
                     defaultValue={{
                       value: "",
                       label: "All",
                     }}
                     options={(CustomerReturnProgram)}
                     onChange={(e) => selectReturnProgram(e)}
                   />
                </Form.Group>
                </Col>

                <Col md={4}>
                <Button variant="primary"  className="mb-3" id="button-addon2" onClick={() => refreshData('filter')}>
                     Apply
                   </Button>
                </Col>
                  
              </Row>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  style={{ marginBottom: "20px" }}
                  placeholder="Search "
                  onChange={onChange}
                />

              </Form.Group>

            </Form>
            <div className="acc-checkbox-list">
              { filteredNodes[0]?.hasOwnProperty('children') &&
              <CheckboxTree
                id="my_account"
                ref={accountRef}
                nodes={filteredNodes}
                checked={checked}
                expanded={expanded}
                onCheck={(checked) => {
                  setChecked(checked)
                  dispatch(setSelectedAccounts(checked))
                    .unwrap()
                    .then(() => {

                    })
                    .catch(() => { });

                }}
                onExpand={(expanded) => setExpanded(expanded)}
                expandOnClick
                onClick={(expanded) => {

                }}
                showNodeIcon={false}
              />
              }
               { !filteredNodes[0]?.hasOwnProperty('children') && role !== internalAdminRole  &&
               <div className="text-center">{defaultNoRecordFound}</div>
              }
               { !filteredNodes[0]?.hasOwnProperty('children') && role === internalAdminRole  &&
               <div className="text-center"><div className="text-center">Please go to <Link to={'/settings/user-management'}>User Management</Link> and impersonate some user to see data.</div></div>
              }
            </div>
          </>
        }
      </Modal.Body>
      <ModalFooter>
        <Button variant="primary" onClick={resetPage}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default AccountHierarchy;
