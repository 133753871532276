import React from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

function PageTitle(props: any) {
  const location = useLocation();
  let navigate = useNavigate();
  const doesAnyHistoryEntryExist = location.key !== "default";

  return (
    <div className="content_header d-flex justify-content-between align-items-center">
      <div className="header_left">
        <h5 className="content_header_title" title={props.name}>
          {props.name}
        </h5>
        <small className={props.isNormal ? "" : "content_header_title"}>
          {props.subname}
        </small>
      </div>
      {props?.customRightSection && props?.customRightSection}
      {props.routePage ? (
        <div className="header_right">
          <Button
            variant={props.isPrimary ? "primary" : "link"}
            className="text-decoration-none"
            onClick={(e) => {
              props.redirectToRoute
                ? navigate(props.routePage)
                : navigate(doesAnyHistoryEntryExist ? -1 : props.routePage);
            }}
          >
            {props.isPrimary ? (
              <>
                <i className="fa-solid fas fa-file-alt me-1"></i>
                {props.buttonName}
              </>
            ) : (
              <>
                <i className="fa-solid fa-arrow-left me-1"></i>
                {props.buttonName}
              </>
            )}
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default PageTitle;
