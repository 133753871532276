export const getTableFields = (columnData: any) => {
  if (columnData !== undefined) {
    const filterData: any = [];
    Object.keys(columnData || {}).map((field, index) => {
      let fineFields = {
        field: field === "lastModifiedOn" ? "createdOn" : field || "",
        filterDataType: convertFilterDataType(columnData?.[field]?.filterType),
        filterType: convertFilterType(columnData?.[field]?.type, columnData?.[field]?.filterType),
        filterValue: getFilterValue(columnData?.[field]),
        filterValue2:
          columnData?.[field]?.filterType === "date" ||
            columnData?.[field]?.type === "inRange"
            ? getFilterValue2(columnData?.[field])
            : "",
      };
      filterData.push(fineFields);
    });
    return filterData;
  } else {
    return [];
  }
};

function getFilterValue2(data: any) {
  if (data.filterType === "date") {
    return data.dateTo !== null && data.dateTo !== undefined ? data.dateTo : "";
  } else if (data.filterType === "number") {
    return data.filterTo !== null && data.filterTo !== undefined
      ? data.filterTo.toString()
      : "";
  } else {
    return data.filter.toString();
  }
}
function getFilterValue(data: any) {
  if (data.filterType === "date") {
    return data.dateFrom !== null && data.dateFrom !== undefined
      ? data.dateFrom
      : "";
  } else {
    return data.filter !== null && data.filter !== undefined
      ? data.filter.toString()
      : "";
  }
}
function convertFilterDataType(data: any) {
  if (data === "text") {
    return 1;
  } else if (data === "number") {
    return 2;
  } else if (data === "date") {
    return 3;
  }
}

function convertFilterType(data: any, filterType: any) {
  if (filterType === "date" && data.toLowerCase() === 'greaterthan') {
    return 15;
  } else if (filterType === "date" && data.toLowerCase() === 'lessthan') {
    return 14;
  }

  switch (data.toLowerCase()) {
    case "contains":
      return 1;

    case "notcontains":
      return 2;

    case "equals":
      return 3;

    case "notequal":
      return 4;

    case "startswith":
      return 5;

    case "endswith":
      return 6;

    case "blank":
      return 7;

    case "notblank":
      return 8;

    case "greaterthan":
      return 9;

    case "greaterthanorequal":
      return 10;

    case "lessthan":
      return 11;

    case "lessthanorequal":
      return 12;

    case "inrange":
      return 13;

    case "before":
      return 14;

    case "after":
      return 15;

    default:
      return 0;
  }
}
