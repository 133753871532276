import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useState, useEffect } from 'react';
import { USER_ROLES } from "../../interface/comman.constant";
import { useSelector } from "react-redux";

export const ALL_PERMISSIONS: any = {
  'TotalCredits': true,
  'ServiceFeeGuarantee': true,
  'CreditsPerServiceFee': true,
  'OrdersItemsProcessed': true,
  'CountOfControlIIControlIII-VItems': true,
  'ExpectedReturnValueByReturnStatus': true,
  'AccountHierarchySelection': true,
  'LastReturn': true,
  'StartAReturn': true,
  'RequestBoxAndShipSupplies': true,
  'RequestAPharmawasteKit': true,
  'RequestATakebackKit': true,
  'RequestAdditionsOnSiteService': true,
  'TrackAShipmentReport': true,
  'UpcomingShipmentsReport': true,
  'PharmaDEALicensesLink': true,
  '222FormsList': true,
  'Form41': true,
  'EPAComplianceNotification': true,
  'UserManagement': true,
  'ReportManagement': true,
  'HelpContactDetails': true,
  'HelpReportAnIssue': true,
  'HelpPortalTraining': true,
  'QuickLinks': true,
  'MyProfile': true,
  'Notifications': true,
  'LinkPrivacyPolicy': true,
  'LinkTermsOfSerivce': true,
  'LinkLegal': true,
  'NotificationsListOfNotifcationsReceived': true,
  'ChatSupport': true,
  'OrderHistory': true,
  'TrackAShipment': true,
  'DestructionManifests': true,
  'InventoryReports': true,
  '222FormDetail': true,
  'Form41Report': true,
  'EPAComplianceNotificationReport': true,
  'Overstock': true,
  'IndividualOrderSummaryPartneredWholesaler': true,
  'IndividualOrderSummaryRCP': true,
  'IndividualOrderSummaryDrugLiquidation': true,
  'TransactionRegisterTransactionSummary': true,
  'TransactionRegisterDisbursementSummary': true,
  'TransactionRegisterDisbursementDetail': true,
  'TransactionRegisterInvoiceSummary': true,
  'TransactionRegisterInvoiceDetail': true,
  'TransactionRegisterServiceFeeGuaranteesSummary': true,
  'TransactionRegisterServiceFeeGuaranteeDetail': true,
  'OrderLineDetail': true,
  'MonthyAccountSummaryStatement': true,
  'DisposalReturns': true,
  'UpcomingReturns': true,
  'OrderLineDetailTopMfgs': true,
  'OrderLineDetailTopProducts': true,
};


export function usePermission(allPermissions: boolean = false, permission: string = "") {
  const userPermissions = useSelector((state: any) => state?.auth?.permissions?.permissions) || {};
  const role = useSelector((state: any) => state?.auth?.permissions?.role) || {};

  if (allPermissions)
    return { ...userPermissions, role };
  else
    return (userPermissions?.[permission] || false);

  // let activeAccount: any;
  // const { instance } = useMsal();

  // if (instance) {
  //   activeAccount = instance.getActiveAccount();
  // }

  // return { role: USER_ROLES?.[activeAccount?.idTokenClaims?.extension_userRole] };
}
