import React from "react";
import { Button, Modal } from "react-bootstrap";

function StartReturnPermissionPopup(props: any) {
  return (
    <Modal
      show={props.show}
      centered={true}
      dialogClassName="modal-30w"
      className="custom_modal"
    >
      <Modal.Body className="custom_modal_body">
        <div className="d-flex justify-content-center align-items-center gap-2 flex-column py-2">
          <p className="pt-2">Are you sure want to delete this record ?</p>
          <div>
            <Button variant="primary" onClick={props.handleYes}>
              Yes
            </Button>
            <Button variant="secondary" onClick={props.handleClose}>
              No
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default StartReturnPermissionPopup;
