import { useRef, useState } from "react";

function DragDropFile(props: any) {
  const [dragActive, setDragActive] = useState(false);
  const [fileName, setFileName] = useState();

  const inputRef: any = useRef(null);

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      props.fileUploadChange(e.dataTransfer.files);
      setFileName(e.dataTransfer.files[0].name);
    }
  };

  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      props.fileUploadChange(e.target.files);
      setFileName(e.target.files[0].name);
    }
  };

  const onButtonClick = () => {
    inputRef?.current.click();
  };

  return (
    <form
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple={true}
        onChange={handleChange}
        accept={props.type}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        <div className="upload_box_wrap">
          <div className="upload_box_inner">
            <div className="upload_box_icon" onClick={onButtonClick}>
              <i className="fa-solid fa-cloud-arrow-up"></i>
            </div>
            <div className="upload_text">
              Drag and Drop here, or Browse files
            </div>
            <div className="upload_text_second">
              Supported Format {props.type}, Maximum 1 file allowed
            </div>
          </div>
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
      {props.fileErrorMessage ? (
        props.fileErrorMessage
      ) : (
        <div>
          {props.fileSelectMessage ? props.fileSelectMessage : fileName}
        </div>
      )}
    </form>
  );
}
export default DragDropFile;
