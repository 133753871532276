export const showLoader = () => {
  var loader = parseInt(localStorage.getItem("loader") ?? "0");
  localStorage.setItem("loader", `${loader + 1}`);
  window.dispatchEvent(new Event("storage"));
};
export const hideLoader = (reset = false) => {
  var loader = parseInt(localStorage.getItem("loader") ?? "1");
  if (reset)
    localStorage.setItem("loader", `0`);
  else
    localStorage.setItem("loader", `${loader - 1}`);
  window.dispatchEvent(new Event("storage"));
};
