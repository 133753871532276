import { Col, Row } from "react-bootstrap";

function GridTotalCountHeader(props: any) {
  return (
    <>


      {(() => {
        if (props.isFourColumn) {
          return <Row>
            {props?.title &&
              <Col sm={6} xl={3} xs={12} className="mb-3 mb-xl-0">
                <div className="info_box_wrap">
                  <div className="info_box_left">
                    <div className="info_box_title">{props.title}</div>
                    <div className="info_box_main_title">{props.count}</div>
                    <div className="info_box_sub_title">{props.description}</div>
                  </div>
                  <div className="info_box_right">
                    <div className="info_box_icon">
                      <i className="fa-solid fa-file-lines"></i>
                    </div>
                  </div>
                </div>
              </Col>
            }
            
            {props?.title1 &&
              <Col sm={6} xl={3} xs={12} className="mb-3 mb-xl-0">
                <div className="info_box_wrap">
                  <div className="info_box_left">
                    <div className="info_box_title">{props.title1}</div>
                    <div className="info_box_main_title">{props.count1}</div>
                    <div className="info_box_sub_title">{props.description1}</div>
                  </div>
                  <div className="info_box_right">
                    <div className="info_box_icon">
                      <i className="fa-solid fa-file-lines"></i>
                    </div>
                  </div>
                </div>
              </Col>
            }

            {props?.title2 &&
              <Col sm={6} xl={3} xs={12} className="mb-3 mb-xl-0">
                <div className="info_box_wrap">
                  <div className="info_box_left">
                    <div className="info_box_title">{props.title2}</div>
                    <div className="info_box_main_title">{props.count2}</div>
                    <div className="info_box_sub_title">{props.description2}</div>
                  </div>
                  <div className="info_box_right">
                    <div className="info_box_icon">
                      <i className="fa-solid fa-file-lines"></i>
                    </div>
                  </div>
                </div>
              </Col>
            }

            {props?.title3 &&
              <Col sm={6} xl={3} xs={12} className="mb-3 mb-xl-0">
                <div className="info_box_wrap">
                  <div className="info_box_left">
                    <div className="info_box_title">{props.title3}</div>
                    <div className="info_box_main_title">{props.count3}</div>
                    <div className="info_box_sub_title">{props.description3}</div>
                  </div>
                  <div className="info_box_right">
                    <div className="info_box_icon">
                      <i className="fa-solid fa-file-lines"></i>
                    </div>
                  </div>
                </div>
              </Col>
            }
          </Row>
        } else if (props.isTwoColumn) {
          return <Row>
            <Col sm={6} xl={3} xs={12} className="mb-3 mb-xl-0">
              <div className="info_box_wrap">
                <div className="info_box_left">
                  <div className="info_box_title">{props.title}</div>
                  <div className="info_box_main_title">{props.count}</div>
                  <div className="info_box_sub_title">{props.description}</div>
                </div>
                <div className="info_box_right">
                  <div className="info_box_icon">
                    <i className="fa-solid fa-file-lines"></i>
                  </div>
                </div>
              </div>
            </Col>

            <Col sm={6} xl={3} xs={12} className="mb-3 mb-xl-0">
              <div className="info_box_wrap">
                <div className="info_box_left">
                  <div className="info_box_title">{props.title1}</div>
                  <div className="info_box_main_title">{props.count1}</div>
                  <div className="info_box_sub_title">{props.description1}</div>
                </div>
                <div className="info_box_right">
                  <div className="info_box_icon">
                    <i className="fa-solid fa-file-lines"></i>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        } else {
          return <Row>
            <Col sm={6} xl={3} xs={12} className="mb-3 mb-xl-0">
              <div className="info_box_wrap">
                <div className="info_box_left">
                  <div className="info_box_title">{props.title}</div>
                  <div className="info_box_main_title">{props.count}</div>
                  <div className="info_box_sub_title">{props.description}</div>
                </div>
                <div className="info_box_right">
                  <div className="info_box_icon">
                    <i className="fa-solid fa-file-lines"></i>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        }
      })()}



      <hr className="full_width section_devider" />
    </>
  );
}

export default GridTotalCountHeader;
