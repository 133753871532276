import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import GlobalPopup from "../../pages/shared/GlobalPopup/globalPopup";

function GridFilterModal(props: any) {
  const [columnValues, setcolumnValues] = useState(props.columnDefs);
  const [filtercolumnValues, setFiltercolumnValues] = useState(
    props.columnDefs
  );
  const [selectAll, setSelectAll] = useState(false);
  const [showResetPopup, setResetPopup] = useState(false);
  const [resetDefault, setResetDefault] = useState(false);

  useEffect(() => {
    setcolumnValues(props.columnDefs);
  }, [props.columnDefs])

  const onSearchChange = (e: any) => {
    if (e.target.value !== "") {
      const filtered = columnValues.filter((item: any) =>
        item?.headerName?.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFiltercolumnValues(filtered);
    } else {
      setFiltercolumnValues(cloneDeep(columnValues));
    }
  };

  const handleCheckBoxChange = (e: any) => {
    const { value, checked } = e.target;

    if (checked === false && columnValues?.filter((obj: any) => obj?.hide === false).length <= 1) return;

    const newState = columnValues?.map((obj: any) =>
      obj.field === value ? { ...obj, hide: !checked } : obj
    );
    const newStateFilter = filtercolumnValues?.map((obj: any) =>
      obj.field === value ? { ...obj, hide: !checked } : obj
    );
    setcolumnValues(newState);
    setFiltercolumnValues(newStateFilter);
  };

  const handleSelectAll = (e: any) => {
    setSelectAll(e.target.checked);

    const newState = columnValues?.map((obj: any, i: number) => (i === 0 && !e.target.checked ? { ...obj, hide: e.target.checked } : { ...obj, hide: !e.target.checked }));
    const newStateFilter = filtercolumnValues?.map((obj: any, i: number) => (i === 0 && !e.target.checked ? { ...obj, hide: e.target.checked } : { ...obj, hide: !e.target.checked }));

    setcolumnValues(newState);
    setFiltercolumnValues(newStateFilter);
  }

  useEffect(() => {
    setFiltercolumnValues(columnValues);
    let selectAll = true;

    columnValues?.forEach((element: any) => {
      if (element?.hide === true) {
        selectAll = false;
      }
    });

    setSelectAll(selectAll);

  }, [props.show]);

  const handleReset = () => {
    let selectAll = true;
    const defCols = cloneDeep(props.defaultColumns);

    defCols?.forEach((element: any) => {
      if (element?.hide === true) {
        selectAll = false;
      }
    });

    setResetDefault(true);
    setSelectAll(selectAll);
    setcolumnValues(defCols);
    setFiltercolumnValues(defCols);
    setResetPopup(false);
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="custom_modal"
      >
        <Modal.Header className="custom_modal_header">
          <Modal.Title className="custom_modal_header_title">
            <div className="custom_modal_header_title_left">
              Customize Columns
            </div>
            <div className="custom_modal_header_title_right">
              <span></span>
              <Button variant="link" onClick={props.handleClose}>
                <i className="fa-solid fa-times"></i>
              </Button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom_modal_body">
          <div className="form_box">
            <div className="modal_search_header">
              <InputGroup>
                <InputGroup.Text id="basic-addon1">
                  <i className="fa-solid fa-search"></i>
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="Seach"
                  name="filter"
                  id="filter-text-box"
                  onInput={(e) => onSearchChange(e)}
                />
              </InputGroup>
            </div>
            <div className="modal-search-list">
              <div className="modal_search_list_item" key="select-all">
                <Form.Check
                  className="ag-header-cell-comp-wrapper"
                  id="select-all"
                  aria-label="select-all"
                  label="Select all"
                  value="selectAll"
                  onChange={handleSelectAll}
                  checked={selectAll}
                />
              </div>
              {filtercolumnValues ? (
                filtercolumnValues?.map((item: any, index: any) => {
                  return (
                    <div className="modal_search_list_item" key={index}>
                      <Form.Check
                        className="ag-header-cell-comp-wrapper"
                        id={item?.id}
                        aria-label={item?.field}
                        label={
                          item?.headerName !== null &&
                            item?.headerName !== undefined
                            ? item?.headerName
                            : item?.field
                        }
                        value={item?.field}
                        onChange={(event) => handleCheckBoxChange(event)}
                        checked={!item?.hide}
                      />
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="custom_modal_footer">
          <div>
            <Button
              variant="primary"
              onClick={() => { setResetPopup(true) }}
            >
              Reset to default
            </Button>
          </div>
          <div>
            <Button
              variant="primary"
              onClick={(event) => props.handleSave(event, columnValues, resetDefault)}
            >
              Save
            </Button>
            <Button variant="secondary" onClick={props.handleClose}>
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <GlobalPopup
        show={showResetPopup}
        showImage={false}
        handleClose={() => setResetPopup(false)}
        handleSave={handleReset}
        message="Do you want to reset to default view?"
      />
    </>
  );
}

export default GridFilterModal;
