import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { Reorder } from "framer-motion";


import { useAppDispatch } from "../../../store/hooks";
import { addNewMessage, customBannerSort, deleteItem, getBannerCMSList } from "../../../store/api/apiSlice";
import GlobalPopup from "../GlobalPopup/globalPopup";
import { useForm } from "react-hook-form";

function BannerCSM(props: any) {
  const { getValues, setValue, register, formState: { errors }, control, handleSubmit, reset, clearErrors, setError } = useForm({ defaultValues: { title: '', description: '' } });

  const [file, setFile]: any = useState();
  const [showError, setShowError]: any = useState();
  const dispatch = useAppDispatch();
  const [allnews, setAllNews]: any = useState();
  let listItems: any = [];
  const [items, setItems] = useState(listItems);
  const [showDeletePopup, setDeletePopup] = useState(0);
  const [idToDelete, setIdToDelete] = useState(0);
  const [showAddMessage, setShowAddMessage] = useState(false)
  const newsArray: any = [];
  let dataFilter = {
    "filter": {
      "is_deleted": 0,

    }
  }
  useEffect(() => {
    refresh();
  }, [])

  const refresh = () => {
    dispatch(getBannerCMSList({})).then((res: any) => {
      setAllNews(res.payload.data);
      listItems = []
      res.payload.data?.map((list: any, index: any) => {
        listItems.push({ name: list.title, id: list.id, description: list.description })
      });
      setItems(listItems);
    })
  }
  const onAdd = () => {
    items?.map((list: any, index: any) => (
      newsArray.push({ id: list.id, no: index })
    ));
   
    dispatch(customBannerSort(newsArray)).then((response: any) => {
        refresh();
    })
  }
  allnews?.map((list: any, index: any) => {
    listItems.push({ name: list.title, id: list.id, description: list.description })
  });

  const handleDeleteUser = () => {
  
    dispatch(deleteItem(idToDelete)).then((result: any) => {
      toast.success("Item Deleted Successfully", {
        autoClose: 1000,
        onClose: () => {
          refresh();
        },
      });
    });

    setDeletePopup(0);
  };

  const addNewMassage = () => {
    setShowAddMessage(true);
  }
  const closePopup = () => {
    setShowAddMessage(false);
  }

  const onSubmit = (data: any) => {
    const formattedData = {
      title: data?.title,
      description: data?.description,
    }

    addUserApiCall(formattedData);
  }

  const addUserApiCall = async (apiData: any) => {
    if(allnews?.length < 7) {
      dispatch(addNewMessage(apiData)).then((result: any) => {
        refresh();
        closePopup();
        reset();
      });
    } else {
      toast.warning("The number of active messages must be seven or fewer.");
    }
  }
  const onError = (errors: any) => {

  }
  const setDelete = (Id:any) => {
    setIdToDelete(Id);
    setDeletePopup(Id)
  }
  return (
    <>
    <div className="container-fluid">
      <div className="content_header d-flex justify-content-between align-items-center">
        <div className="header_left">
          <h5
            className={`content_header_title `}
            title="Messages"
          >
            Marketing Updates
          </h5>
        </div>

        <div

        >
          <Button variant="primary" onClick={() => addNewMassage()}>
          <i className="fa-solid fa-add me-2"></i>  Add Message
          </Button>
        </div>

      </div>
      <div className="content_body">
        <div className="banner-msg-list">
      <Reorder.Group values={items} onReorder={setItems} >
        {items.map((item: any) => (

          // Change the li to Reorder.Item and add value prop
          <Reorder.Item key={item.id} value={item}>

            <div className="msg-title">
             <span><i className="fa fa-bars-staggered mr-5"></i>
             {item.name}</span> 
             {items?.length > 1 &&
              <span
                className="icon-edit-gray"
                onClick={() => setDelete(item.id)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </span>
             }
            </div>

            <div className="msg-desc ml-20"
              dangerouslySetInnerHTML={{
                __html: item.description,
              }}

             >

            </div>
          </Reorder.Item>
        ))}
      </Reorder.Group>
      </div>
      <Button variant="primary" className='font-18 mt-15 w-sm-100 wow fadeInDown' type="button" onClick={onAdd} >
        Save Changes
      </Button>
      </div>
      </div>
      <Modal show={showAddMessage} className="custom_modal">
        <Form
          id="form-create-user"
          className="form_box create_user"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <Modal.Header className="custom_modal_header">
            <Modal.Title className="custom_modal_header_title">
              <div className="custom_modal_header_title_left">
              Marketing Updates
              </div>
              <div className="custom_modal_header_title_right">
                <Button variant="link" onClick={closePopup}>
                  <i className="fa-solid fa-times"></i>
                </Button>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="custom_modal_body">
            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>Title</Form.Label>
                  <Form.Control type="text" placeholder="" {...register("title", { required: true})} />
                  {errors.title && (
                    <p className="error-validation">
                      {errors.title?.type === "required" ? (
                        'Title is required.'
                      ) : (
                        'Title is invalid.'
                      )}
                    </p>
                  )}

                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group
                  className=""
                  controlId="exampleForm.ControlInput4"
                >
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea"
                  placeholder=""
                    rows={3} {...register("description",{ required: true})}   />
                  {errors?.description?.type === "require" && (
                    <p className="error-validation">
                      {errors.description?.type === "required" ? (
                        'Message is required.'
                      ) : (
                        'Message is invalid.'
                      )}
                    </p>
                  )}
                </Form.Group>
             <span className="mt-0"> If you want to highlight some text then place it between &lt;span&gt;TEXT TO BE HIGHLIGHTED&lt;/span&gt;</span>
                
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              Save
            </Button>
            <Button variant="secondary" onClick={closePopup}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
     

      <GlobalPopup
        show={!!showDeletePopup}
        showImage={false}
        handleClose={() => setDeletePopup(0)}
        handleSave={handleDeleteUser}
        message="Are you sure you want to delete?"
      />
    </>
  );
}

export default BannerCSM;
