import { toast } from "react-toastify";
import axios from "../../../services/httpService";
import getToken from "../../../store/api/api";
import { defaultErrorMessage } from "../../../interface/comman.constant";
import { hideLoader, showLoader } from "../GlobalLoader";

export default async function drugFileDownload(
  urlEndpoint: any,
  fileName: any,
  orderNumber: any,
  customerIds: any
) {
  const API_URL = axios.Api;
  const REPORTAPI = axios.ReportAPI || "";
  try {
    let data = {
      customerIds: customerIds,
      orderNumber: orderNumber,
    };
    showLoader();
    const azureB2C = localStorage.getItem("AzureB2CImpersonate");
    fetch(API_URL + REPORTAPI + urlEndpoint, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (await getToken()),
        ...((azureB2C && { UserImpersonated: azureB2C }) || {}),
      },
      body: JSON.stringify(data),
    })
      .then(async (response: any) => {
        const contentType = response.headers.get('Content-Type');
        if (response.status === 200 && contentType.includes('application/json')) {
          hideLoader();
          toast.success("No data available.");
        } else if (response.status === 200) {
          const b = await response.blob();
          let url = URL.createObjectURL(b);
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.click();
          hideLoader();
        } else {
          hideLoader();
          toast.error(defaultErrorMessage);
        }
      })
      .catch((err) => {
        hideLoader();
        toast.error(defaultErrorMessage);
      });
  } catch (error) {
    hideLoader();
  }
}
