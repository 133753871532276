import './App.css';
import '../src/assets/scss/styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MainRoutes } from './router';
import { ToastContainer } from 'react-toastify';
import "./assets/language/i18n";
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import Zendesk, { ZendeskAPI } from "./zendesk/ZendeskConfig";
type AppProps = {
  pca: IPublicClientApplication;
};
const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY || "";

function App({ pca }: AppProps) {
  const handleLoaded = () => {
    ZendeskAPI("messenger", "close");
  };
  return (
    <MsalProvider instance={pca}>
    <div className="App">
       <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='colored'
        />
    <MainRoutes />
    <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={handleLoaded} />
    </div>
    </MsalProvider>
  );
}

export default App;
