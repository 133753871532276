import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { PaginationControl } from "react-bootstrap-pagination-control";
import Select from "react-select";

function GridFooter(props: any) {
  const options_value = [
    { value: "100", label: "100" },
    { value: "200", label: "200" },
    { value: "300", label: "300" },
  ];

  return (
    <div className="ag_table_footer mt-4">
      <Row className="justify-content-between">
        <Col xs="auto">
          <Row className="align-items-center gx-2">
            <Col xs="auto">
              {options_value && (
                <Select
                  defaultValue={{ value: "100", label: "100" }}
                  options={options_value}
                  onChange={(e) => {
                    props.setGridData((prev: any) => ({
                      ...prev,
                      ["pageSize"]: Number(e?.value),
                    }));
                  }}
                />
              )}
            </Col>
            <Col xs="auto">entries per page</Col>

            <Col xs="auto">
              {props.gridData.page === 1
                ? props.gridData.totalRows === 0
                  ? props.gridData.totalRows
                  : props.gridData.page
                : (props.gridData.page - 1) * props.gridData.pageSize + 1}
              -
              {props.gridData.totalRows >
              props.gridData.page * props.gridData.pageSize
                ? props.gridData.page * props.gridData.pageSize
                : props.gridData.totalRows}{" "}
              of {props.gridData.totalRows} entries
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          <PaginationControl
            page={props.gridData.page}
            total={props.gridData.totalRows}
            limit={props.gridData.pageSize}
            changePage={(page) => {
              props.setGridData((prev: any) => ({ ...prev, ["page"]: page }));
            }}
            ellipsis={2}
          />
        </Col>
      </Row>
    </div>
  );
}

export default GridFooter;
