import { Container, Navbar } from "react-bootstrap";
import Footer from "../layout/footer/footer";

export const AccessDenied = () => {
  return (
    <>
      <Container fluid>
        <div className="content_body  mt-4">
          <div className="d-flex align-items-center justify-content-center page-not-found-wrapper">
            <div className="icon">
              <i className="fas fa-exclamation-triangle"></i>
            </div>
            <div className="description">
              <h3>Ooops!</h3>
              <h4>Access Denied</h4>
              <p>Services on the page were trying to access are not available now.</p>
              <p>Please check with admin.</p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default AccessDenied;