// @ts-nocheck
import { toast } from "react-toastify";
import axios from "../../../services/httpService";
import getToken from "../../../store/api/api";
import { defaultErrorMessage, formateIds } from "../../../interface/comman.constant";
import { hideLoader, showLoader } from "../GlobalLoader";

async function getFiles(props: any, visibleColumn: any, userAccounts: any) {
  const API_URL = axios.Api;
  const MID_URL =
    props.URL === "User/List" ? axios.UserManageAPI : axios.ReportAPI || "";

  try {
    let data = {};
    if (props.DisbursementId) {
      data = {
        pageNumber: props.page,
        pageSize: props.pageSize,
        sortField: props.sortField,
        sortDirection: props.sortOrder === "asc" ? 0 : 1,
        isForExcel: true,
        globalSearch: props.globalSearch,
        VisibleColumns: visibleColumn,
        DisbursementId: props.DisbursementId,
        filterOptions: props.filterOptions,
        CustomerIds: userAccounts,
      };
    } else if (props.orderNumber && props.customerId) {
      data = {
        pageNumber: props.page,
        pageSize: props.pageSize,
        sortField: props.sortField,
        sortDirection: props.sortOrder === "asc" ? 0 : 1,
        isForExcel: true,
        globalSearch: props.globalSearch,
        VisibleColumns: visibleColumn,
        orderNumber: props.orderNumber,
        customerId: props.customerId,
        filterOptions: props.filterOptions,
        CustomerIds: userAccounts,
      };
    } else if (props.orderNumber) {
      data = {
        pageNumber: props.page,
        pageSize: props.pageSize,
        sortField: props.sortField,
        sortDirection: props.sortOrder === "asc" ? 0 : 1,
        isForExcel: true,
        globalSearch: props.globalSearch,
        VisibleColumns: visibleColumn,
        orderNumber: props.orderNumber,
        filterOptions: props.filterOptions,
        CustomerIds: userAccounts,
        returnProgram: props.returnProgram
      };
    } else if (props.customerId) {
      data = {
        pageNumber: props.page,
        pageSize: props.pageSize,
        sortField: props.sortField,
        sortDirection: props.sortOrder === "asc" ? 0 : 1,
        isForExcel: true,
        globalSearch: props.globalSearch,
        VisibleColumns: visibleColumn,
        customerId: props.customerId,
        filterOptions: props.filterOptions,
        CustomerIds: userAccounts,
      };
    } else {
      data = {
        pageNumber: props.page,
        pageSize: props.pageSize,
        sortField: props.sortField,
        sortDirection: props.sortOrder === "asc" ? 0 : 1,
        isForExcel: true,
        globalSearch: props.globalSearch,
        VisibleColumns: visibleColumn,
        filterOptions: props.filterOptions,
        CustomerIds: userAccounts,
      };
    }

    showLoader();
    const azureB2C = localStorage.getItem('AzureB2CImpersonate');

    fetch(API_URL + MID_URL + props.URL, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (await getToken()),
        ...(((azureB2C) && { UserImpersonated: azureB2C }) || {})
      },
      body: JSON.stringify(data),
    })
      .then(async (response: any) => {
        const b = await response.blob();
        let url = URL.createObjectURL(b);
        let a = document.createElement("a");
        a.href = url;
        a.download = props.csvName;
        a.click();
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        toast.error(defaultErrorMessage);
      });
  } catch (error) {
    hideLoader();
  }
}
export default getFiles;
