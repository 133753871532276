import { Bar } from "react-chartjs-2";

function BarChart(props: any) {

    return (<>
        <Bar
            id={props.id}
            data={props.data}
            options={props.options}
        />
    </>
    );

}
export default BarChart;
