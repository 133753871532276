import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Button, Container, Dropdown, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import AccountHierarchy from "../../accountHierarchy/accountHierarchy";
import { useAppDispatch } from "../../../../store/hooks";
import { getAllNotification, getTransactionSummary, makAsReadNotification, markAllAsReadNotification, setUserPermissions } from "../../../../store/api/apiSlice";
import axios from "../../../../services/httpService";
import * as signalR from '@microsoft/signalr';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { usePermission } from "../../../../helpers/hooks/usePermissions";
import { toast } from "react-toastify";
import { setrefreshNotificationPage } from "../../../../store/slice/hierarchy";
import { hideLoader, showLoader } from "../../GlobalLoader";
const NOTIFICATION_API_URL = axios.NotificationSendApi;
export const Header = () => {
  const permissions = usePermission(true);
  const azureImpersonateId = (localStorage.getItem('AzureB2CImpersonate') || '');
  const impersonatingUser = (localStorage.getItem('ImpersonateUser') || '');
  let navigate = useNavigate();
  const [showProductTour, setShowProductTour] = useState(true);
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const [showHierarchy, setShowHierarchy] = useState(false);
  const user = useSelector((state: any) => state?.auth?.userInfo?.data?.userDetail) || {};
  const userRegistrationDetails = useSelector((state: any) => state?.auth?.registrationDetails) || {};
  const btnRef = useRef<any>();
  const { instance } = useMsal();
  const [connection, setConnection] = useState<any>(null);
  const [notifications, setNotifications] = useState<any>([]);
  const [notificaionCount, setNotificationsCount] = useState<any>(0);
  const refreshNotificationFlag = useSelector((state: any) => state?.accounts?.refreshNotificationFlag) || '';
  const { t } = useTranslation();
  let activeAccount: any;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }
  const handleLogoutRedirect = async () => {
    if (localStorage.getItem('isAdmin') === 'true') {
      const resetPasswordRequest = {
        authority: 'https://login.microsoftonline.com/common/oauth2/v2.0/logout',
        scopes: [],
      };
      localStorage.clear();
      localStorage.setItem('logoutFromAll', Date.now().toString());
      await instance.logoutRedirect(resetPasswordRequest);

    } else {
      localStorage.removeItem("disbursementSummaryCol");
      localStorage.removeItem("invoiceSummaryCol");
      localStorage.removeItem("sfgSummaryCol");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("permissions");
      localStorage.removeItem("userSettings");
      localStorage.removeItem("registrationDetails");
      localStorage.removeItem("AzureB2CImpersonate");
      localStorage.removeItem('ImpersonateUser');
      localStorage.removeItem("product_tour");
      const idToken = instance.getAllAccounts()[0]?.idToken;
      localStorage.clear();
      localStorage.setItem('logoutFromAll', Date.now().toString());
      const currentAccount = instance.getAccountByHomeId(process.env.REACT_APP_CLIENTID as string);
      navigate("/");
      await instance.logoutRedirect({ account: currentAccount, idTokenHint: idToken });
    }
  };

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === 'logoutFromAll') {
        // If logout event detected, close the tab
        navigate("/");
        instance.logoutRedirect();
      }
    };
    // Add event listener for storage changes
    window.addEventListener('storage', handleStorageChange);
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    let latestValue: any = null;
    let timeId: any = null;

    const interval = setInterval(() => {
      const loaderValue = parseInt(localStorage.getItem('loader') ?? '0');
      console.log("checking loader ====> ", loaderValue);

      if (latestValue !== loaderValue) {

        if (timeId) clearTimeout(timeId);
        latestValue = loaderValue;

        timeId = setTimeout(() => {
          console.log("loader reset ====> ", loaderValue, interval);
          if (interval) clearInterval(interval)
          hideLoader(true);
        }, 25000);
      }
    }, 1000);

    return () => {
      if (timeId) clearTimeout(timeId);
      if (interval) clearInterval(interval);
    };
  }, []);

  const handleProfileEdit = () => {
    navigate("/my-profile");
  };
  const openHierarchy = () => {
    setShowHierarchy(true);
  };
  const gotoPage = (page: any) => {
    navigate(page);
    setShow(false);
  };
  const gotoTourPage = (page: any) => {
    localStorage.setItem("product_tour", "true");
    navigate(page, { state: { productTour: true } });
  };
  useEffect(() => {
    getNotification();
  }, [user]);
  const getNotification = async () => {
    if (Object.keys(user).length > 0) {
      dispatch(getAllNotification()).then((result: any) => {
        setNotifications(result?.payload?.data);
        setNotificationsCount(notifications?.filter((_r: { isRead: boolean; }) => _r?.isRead === false).length)
      });
    }
  };
  useEffect(() => {
    const closeDropdown = (e: { target: any }) => {
      if (btnRef?.current && !btnRef.current.contains(e.target)) {
        setShow(false);
      }
    };
    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  useEffect(() => {
    let newConnection = new signalR.HubConnectionBuilder()
      .withUrl(NOTIFICATION_API_URL + "api", { withCredentials: false })
      .withAutomaticReconnect()
      .build();
    setConnection(newConnection);

  }, []);

  useEffect(() => {
    if (connection) {
      connection.start()
        .then(() => {
          connection.on(activeAccount?.username, (message: any) => {
            setNotifications((notifications: any) => [message, ...notifications]);
            dispatch(setrefreshNotificationPage("all"));
          });
        })
        .catch((e: any) => console.log(e))
      return () => {
        connection.stop();
      };
    }
  }, [connection]);

  const redirectTo = (notification: any) => {
    const data = {
      id: [notification.id],
      isReadAll: false
    };
    if (notification.isRead === false) {
      markAsRead(data);
      setInstatntRead(notification.id);
    }
  }
  const markAsRead = (data: object) => {
    dispatch(makAsReadNotification(data)).then((result: any) => {
    });
  }
  const setInstatntRead = (Id: any) => {
    let updatedNotifications = notifications?.map((notification: any) => {
      if (notification.id === Id) {
        dispatch(setrefreshNotificationPage(Id));
        return { ...notification, isRead: true };
      } else {
        return notification;
      }
    });
    setNotifications(updatedNotifications)

  }
  const handleImpersonation = () => {
    showLoader();
    localStorage.removeItem('AzureB2CImpersonate');
    localStorage.removeItem('ImpersonateUser');

    dispatch(setUserPermissions({})).then((result: any) => {
      localStorage.setItem("permissions", JSON.stringify((result?.payload || {})));
      toast.success("User Impersonation Canceled", {
        autoClose: 2000,
        onClose: () => {
          hideLoader();
          window.location.href = '/settings/user-management';
          hideLoader();
        }
      });
    });
  }

  useEffect(() => {
    setNotificationsCount(notifications?.filter((_r: { isRead: boolean; }) => _r?.isRead === false).length);
  }, [notifications]);
  useEffect(() => {
    const data = {
      id: [refreshNotificationFlag],
      isReadAll: false
    };
    if (refreshNotificationFlag != "" && refreshNotificationFlag != "all") {
      markAsRead(data);
      setInstatntRead(refreshNotificationFlag);
    }
    if (refreshNotificationFlag === "all") {
      getNotification();
      dispatch(setrefreshNotificationPage(""));
    }
  }, [refreshNotificationFlag]);

  const readAllnotification = () => {
    const data = {
      isReadAll: true
    };
    dispatch(markAllAsReadNotification(data)).then((result: any) => {
      getNotification();
      dispatch(setrefreshNotificationPage("all"));
    });
  }

  const showServicesDropDown = useMemo(() => {
    return (
      permissions?.['StartAReturn'] ||
      permissions?.['RequestBoxAndShipSupplies'] ||
      permissions?.['RequestAPharmawasteKit'] ||
      permissions?.['RequestATakebackKit'] ||
      permissions?.['RequestAdditionsOnSiteService'] ||
      permissions?.['TrackAShipmentReport'] ||
      permissions?.['UpcomingShipmentsReport']
    )
  }, [permissions]);

  const showTourDropDown = useMemo(() => {
    return (
      (
        (permissions?.["DrugLiquidationOrderHistory"] && permissions?.["OrderHistory"] && permissions?.["RCSCP"]) ||
        !(
          (permissions?.["DrugLiquidationOrderHistory"] && !permissions?.["OrderHistory"]) ||
          (permissions?.["RCSCP"])
        )
      ) ||
      permissions?.['StartAReturn'] ||
      permissions?.['RequestBoxAndShipSupplies'] ||
      permissions?.['OrderHistory']
    )
  }, [permissions]);

  const showComplianceDropDown = useMemo(() => {
    return (
      permissions?.['222FormsList'] ||
      permissions?.['PharmaDEALicensesLink'] ||
      permissions?.['Form41'] ||
      permissions?.['EPAComplianceNotification']
    )
  }, [permissions]);

  const showReportsDropDown = useMemo(() => {
    return (
      permissions?.['OrderHistory'] ||
      permissions?.['OrderLineDetail'] ||
      permissions?.['Overstock'] ||
      permissions?.['TransactionRegisterTransactionSummary'] ||
      permissions?.['DisposalReturns'] ||
      permissions?.['DestructionManifests']
    )
  }, [permissions]);

  return (
    <AuthenticatedTemplate>
      <header className="header" id="header">
        <Navbar expand="lg" className="">
          <Container fluid className="align-items-center ">
            <Navbar.Brand href="/" className="header_logo">
              <img src="/assets/images/logo-pharma.svg" alt="" className="img-fluid" />
            </Navbar.Brand>
            <Nav className="d-flex d-lg-none flex-row align-items-center gap-2 header_action">
              {/* <Nav.Link eventKey={2} href="#" className="btn-search">
                <i className="fa-solid fa-lg fa-search"></i>
              </Nav.Link> */}
              {/* <Nav.Link eventKey={2} href="#" className="btn-notify">
              <i className="fa-solid fa-xl fa-bell"></i>
            </Nav.Link> */}
              {userRegistrationDetails?.showLegacyPortal && (
                <div className="cursor-pointer" onClick={() => { window.open(process.env.REACT_APP_RXRD_URL, "_blank"); }}>
                  <Button className="rxrd_btn" variant="primary">RxRD Portal</Button>
                </div>
              )}

              {(permissions?.['Notifications']) && (
                <Dropdown className="notify-dropdown">
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic-1"
                    className="btn-notify"
                  >
                    <i className="fa-solid fa-lg fa-bell"></i>
                    {notificaionCount > 0 &&
                      <span className="recent-notify">{notificaionCount}</span>
                    }
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <div className="notify-list-wrapper p-3">
                      <div className="d-flex heading">
                        <h1>Notifications</h1>
                        {notifications?.length > 0 &&
                          <a href="#" onClick={() => readAllnotification()}>Read All</a>
                        }
                      </div>
                      <div className="notification-list">
                        {notifications?.length > 0 &&
                          <>
                            <ul>
                              {notifications?.slice(0, 10)?.map((notification: any, index: any) => (
                                <li key={index} className="cursor_pointer">
                                  <a onClick={() => redirectTo(notification)}>
                                    <div className="icon">
                                      <i className="fa-solid fa-bell"></i>
                                      {!notification?.isRead &&
                                        <span className="recent-notify"></span>
                                      }
                                    </div>
                                    <div className="detail">
                                      <p>{notification?.message}</p>
                                      <small>{moment(notification?.createdOn).format('MMMM DD, YYYY hh:mm A')}</small>
                                    </div>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </>
                        }
                        {notifications?.length === 0 && (
                          <div className="no-records-wrapper mincardheight justify-content-center">
                            <svg
                              width="48"
                              height="42"
                              viewBox="0 0 48 42"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 5.25C0 2.3543 2.39167 0 5.33333 0H18.6667V10.5C18.6667 11.952 19.8583 13.125 21.3333 13.125H32V16.2914C25.8417 18.0059 21.3333 23.5758 21.3333 30.1875C21.3333 35.0355 23.7583 39.3176 27.475 41.9426C27.2083 41.9836 26.9417 42 26.6667 42H5.33333C2.39167 42 0 39.6457 0 36.75V5.25ZM32 10.5H21.3333V0L32 10.5ZM36 18.375C39.1826 18.375 42.2348 19.6195 44.4853 21.8348C46.7357 24.0501 48 27.0546 48 30.1875C48 33.3204 46.7357 36.3249 44.4853 38.5402C42.2348 40.7555 39.1826 42 36 42C32.8174 42 29.7652 40.7555 27.5147 38.5402C25.2643 36.3249 24 33.3204 24 30.1875C24 27.0546 25.2643 24.0501 27.5147 21.8348C29.7652 19.6195 32.8174 18.375 36 18.375ZM36 38.0625C36.5304 38.0625 37.0391 37.8551 37.4142 37.4859C37.7893 37.1167 38 36.6159 38 36.0938C38 35.5716 37.7893 35.0708 37.4142 34.7016C37.0391 34.3324 36.5304 34.125 36 34.125C35.4696 34.125 34.9609 34.3324 34.5858 34.7016C34.2107 35.0708 34 35.5716 34 36.0938C34 36.6159 34.2107 37.1167 34.5858 37.4859C34.9609 37.8551 35.4696 38.0625 36 38.0625ZM36 22.3125C35.2667 22.3125 34.6667 22.9031 34.6667 23.625V30.1875C34.6667 30.9094 35.2667 31.5 36 31.5C36.7333 31.5 37.3333 30.9094 37.3333 30.1875V23.625C37.3333 22.9031 36.7333 22.3125 36 22.3125Z"
                                fill="#3F475C"
                                fillOpacity="0.3"
                              />
                            </svg>
                            <span>{t('NO_NEW_NOTIFICATION')}</span>
                          </div>
                        )}
                      </div>
                      {notifications?.length > 0 &&
                        <div className="text-end">
                          {(permissions?.['NotificationsListOfNotifcationsReceived']) && (
                            <a href="/notifications" className="text-link ">
                              View All
                            </a>
                          )}
                        </div>
                      }
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              )}

              <Dropdown className="user_dropdown">
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  className="btn-user"
                >
                  <i className="fa-solid  fa-lg fa-user"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Dropdown.Item
                    href="#/action-1"
                    className="user_dropdown_menu"
                  >
                    <div className="user_img">
                      <i className="fa-solid fa-user"></i>
                    </div>
                    <div className="uesr_detail">
                      <div className="user_name">
                        {user.firstName} {user.lastName}
                      </div>
                      <div className="user_email">
                        {activeAccount && activeAccount.username
                          ? activeAccount.username
                          : "Unknown"}
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  {azureImpersonateId && (
                    <>
                      <div className="impersonate-name">
                        <Dropdown.Item onClick={handleImpersonation}> Impersonating: {impersonatingUser} <span className="icon-close"> <i className=" fa-solid fa-xmark"></i></span> </Dropdown.Item>
                      </div>
                      <Dropdown.Divider />
                    </>
                  )}
                  {(permissions?.['MyProfile']) && (
                    <Dropdown.Item onClick={handleProfileEdit}>
                      My Profile
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item onClick={handleLogoutRedirect}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </Nav>

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className={`header_menu flex-grow-1  justify-content-center ${showProductTour ? "my-sixth-step" : ""}`}>
                <Nav.Link className={`content_header_title ${showProductTour ? "my-seventh-step" : ""}`} href="/">
                  Dashboard
                </Nav.Link>
                {showServicesDropDown &&
                  <NavDropdown
                    title="Services"
                    id="basic-nav-dropdown"
                    className={`${showProductTour ? "my-eight-step" : ""}`}
                  >
                    {(permissions?.['StartAReturn']) && (
                      <NavDropdown.Item href="/services/start-Inventoried-return">
                        Start an Inventoried Return
                      </NavDropdown.Item>
                    )}
                    {(permissions?.['RequestBoxAndShipSupplies']) && (
                      <NavDropdown.Item href="/services/request-supplies">
                        Request Box and Ship Supplies
                      </NavDropdown.Item>
                    )}
                    {(permissions?.['RequestAPharmawasteKit']) && (
                      <NavDropdown.Item href="/services/request-pharma-kit">
                        Request a PharmaWaste Kit Supplies
                      </NavDropdown.Item>
                    )}
                    {(permissions?.['RequestATakebackKit']) && (
                      <NavDropdown.Item href="/services/request-take-back-kit">
                        Request a Take Back Kit
                      </NavDropdown.Item>
                    )}
                    {(permissions?.['RequestAdditionsOnSiteService']) && (
                      <NavDropdown.Item href="/services/request-on-site-service">
                        Request On-site Service
                      </NavDropdown.Item>
                    )}
                    {(permissions?.['TrackAShipmentReport']) && (
                      <NavDropdown.Item href="/reports/track-shipment">
                        Track a Shipment
                      </NavDropdown.Item>
                    )}
                    {(permissions?.['UpcomingShipmentsReport']) && (
                      <NavDropdown.Item href="/reports/upcoming-returns">
                        Upcoming Service Events
                      </NavDropdown.Item>
                    )}
                    {/* */}
                  </NavDropdown>
                }
                {showComplianceDropDown &&
                  <NavDropdown
                    title="Compliance"
                    id="basic-nav-dropdown"
                    className={`${showProductTour ? "my-nine-step" : ""}`}
                  >
                    {(permissions?.['222FormsList']) && (
                      <NavDropdown.Item href="/reports/form-222">
                        222 Form Detail
                      </NavDropdown.Item>
                    )}
                    {(permissions?.['PharmaDEALicensesLink']) && (
                      <NavDropdown.Item
                        target="_blank"
                        href="https://pharmalogistics.com/licenses/"
                      >
                        Pharma Logistics' Licences
                      </NavDropdown.Item>
                    )}
                    {(permissions?.['Form41']) && (
                      <NavDropdown.Item href="/reports/form-41">
                        Form 41 Report
                      </NavDropdown.Item>
                    )}
                    {(permissions?.['EPAComplianceNotification']) && (
                      <NavDropdown.Item href="/reports/epa-compliance">
                        EPA Compliance Notification
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                }
                {(!(permissions?.['role'] === 1) && showReportsDropDown) &&
                  <NavDropdown
                    title="Reporting"
                    id="basic-nav-dropdown"
                    className={`${showProductTour ? "my-ten-step" : ""}`}
                  >
                    {(permissions?.['OrderHistory']) && (
                      <NavDropdown.Item href="/reports/order-history">
                        Order History
                      </NavDropdown.Item>
                    )}
                    {(permissions?.['OrderLineDetail']) && (
                      <NavDropdown.Item href="/reports/order-line-details">
                        Order Line Details
                      </NavDropdown.Item>
                    )}
                    {(permissions?.['Overstock']) && (
                      <NavDropdown.Item href="/reports/overstock-summary">
                        Overstock
                      </NavDropdown.Item>
                    )}
                    {(permissions?.['TransactionRegisterTransactionSummary']) && (
                      <NavDropdown.Item href="/reports/transaction-summary">
                        Transactions
                      </NavDropdown.Item>
                    )}
                    {(permissions?.['DisposalReturns']) && (
                      <NavDropdown.Item href="/reports/disposal-returns">
                        Disposal Returns
                      </NavDropdown.Item>
                    )}
                    {(permissions?.['DestructionManifests']) && (
                      <NavDropdown.Item href="/reports/destruction-manifests">
                        Destruction Manifest
                      </NavDropdown.Item>
                    )}
                    <Dropdown.Divider />
                    <NavDropdown.Item
                      href="/reports/view-all"
                      className="text-end text-link"
                    >
                      View All
                    </NavDropdown.Item>
                  </NavDropdown>
                }
                {(
                  (permissions?.['AccountHierarchySelection']) ||
                  (permissions?.['ReportManagement']) ||
                  (permissions?.['UserManagement'])
                ) &&
                  <NavDropdown
                    title="Settings"
                    id="basic-nav-dropdown"
                    className={`${showProductTour ? "my-eleven-step" : ""}`}
                  >
                    {(permissions?.['AccountHierarchySelection']) && (
                      <NavDropdown.Item onClick={() => openHierarchy()}>
                        Account Hierarchy
                      </NavDropdown.Item>
                    )}
                    {(permissions?.['ReportManagement']) && (
                      <NavDropdown.Item href="/reports/reportManagement">
                        Report Management
                      </NavDropdown.Item>
                    )}
                    {(permissions?.['UserManagement']) && (
                      <NavDropdown.Item
                        href="#"
                        onClick={() => {
                          navigate("/settings/user-management");
                        }}
                      >
                        User Management
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                }
                {/* <Nav.Link
                  href="/help"
                  className={`${showProductTour ? "my-twelve-step" : ""}`}
                >
                  Help
                </Nav.Link> */}
                {showTourDropDown &&
                  <NavDropdown title="Site Tour" id="basic-nav-dropdown">
                    {(
                      (permissions?.["DrugLiquidationOrderHistory"] && permissions?.["OrderHistory"] && permissions?.["RCSCP"]) ||
                      !(
                        (permissions?.["DrugLiquidationOrderHistory"] && !permissions?.["OrderHistory"]) ||
                        (permissions?.["RCSCP"])
                      )
                    ) && (
                        <NavDropdown.Item onClick={() => gotoTourPage("/")}>
                          Dashboard
                        </NavDropdown.Item>
                      )}
                    {(permissions?.['StartAReturn']) && (
                      <NavDropdown.Item
                        onClick={() =>
                          gotoTourPage("/services/start-Inventoried-return")
                        }
                      >
                        Start an Inventoried Return
                      </NavDropdown.Item>
                    )}
                    {(
                      permissions?.["RequestATakebackKit"] ||
                      permissions?.["RequestAPharmawasteKit"] ||
                      permissions?.["RequestBoxAndShipSupplies"]
                    ) && (
                        <NavDropdown.Item
                          onClick={() => {
                            gotoTourPage(
                              permissions?.["RequestBoxAndShipSupplies"] ?
                                "/services/request-supplies"
                                :
                                permissions?.["RequestATakebackKit"] ?
                                  '/services/request-take-back-kit'
                                  : '/services/request-pharma-kit'
                            )
                          }}
                        >
                          Request Supplies
                        </NavDropdown.Item>
                      )}
                    {(permissions?.['OrderHistory']) && (
                      <NavDropdown.Item onClick={() => gotoTourPage("/reports/order-history")}>
                        Order History
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                }
                {/* <Nav.Link href="#/action-2">User Management</Nav.Link> */}
                {/* <Nav.Link href="/help">Help</Nav.Link> */}
                {(
                  permissions?.['HelpContactDetails'] ||
                  permissions?.['HelpReportAnIssue'] ||
                  permissions?.['HelpPortalTraining']
                ) && (
                    <NavDropdown title="Help" id="basic-nav-dropdown">
                      {(permissions?.['HelpContactDetails']) && (
                        <NavDropdown.Item href="/help">
                          Contact Us
                        </NavDropdown.Item>
                      )}
                      {(permissions?.['HelpReportAnIssue']) && (
                        <NavDropdown.Item href="/help/reportIssue">
                          Report Issue
                        </NavDropdown.Item>
                      )}
                      {(permissions?.['HelpPortalTraining']) && (
                        (activeAccount?.username === "kashline@pharmalogistics.com") && (
                          <NavDropdown.Item href="/help/portalTraning">
                            Portal Training
                          </NavDropdown.Item>
                        )
                      )}
                      {(activeAccount?.username === "kashline@pharmalogistics.com" || activeAccount?.username === "ehendricks@pharmalogistics.com") && (
                        <NavDropdown.Item href="/help/banner-cms">
                          Marketing Updates
                        </NavDropdown.Item>
                      )}
                    </NavDropdown>
                  )}
              </Nav>
              <Nav className="header_action d-none d-lg-flex align-items-center ">
                {/* <Nav.Link eventKey={2} href="#" className="btn-search">
                  <i className="fa-solid fa-search"></i>
                </Nav.Link> */}
                {/* <Nav.Link eventKey={2} href="#">
                
                </Nav.Link> */}
                {/* <Nav.Link eventKey={2} href="#" className="btn-notify">
                <i className="fa-solid fa-bell"></i>
                </Nav.Link> */}
                {(permissions?.['QuickLinks']) && (
                  <Dropdown
                    ref={btnRef}
                    className="grid-dropdown"
                    autoClose="inside"
                    show={show}
                  >
                    <Dropdown.Toggle
                      variant=""
                      id="dropdown-basic-2"
                      className="btn-grid"
                      onClick={() => setShow(!show)}
                    >
                      <img
                        src="/assets/images/icon-grid.svg"
                        alt=""
                        className="icon-grid"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <div className="grid-list-wrapper p-3">
                        <div className="d-flex heading">
                          <h1>Quick Links</h1>
                        </div>
                        <div>
                          <ul>
                            {(permissions?.['StartAReturn']) && (
                              <li style={{ maxWidth: "130px" }}>
                                <a
                                  className="cursor_pointer"
                                  onClick={() =>
                                    gotoPage("/services/start-Inventoried-return")
                                  }
                                >
                                  <div className="icon">
                                    <img
                                      src="/assets/images/icon-start-return.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="title">
                                    Start an Inventoried Return
                                  </div>
                                </a>
                              </li>
                            )}
                            {(permissions?.['RequestBoxAndShipSupplies']) && (
                              <li>
                                <a
                                  className="cursor_pointer"
                                  onClick={() =>
                                    gotoPage("/services/request-supplies")
                                  }
                                >
                                  <div className="icon">
                                    <img
                                      src="/assets/images/icon-request-supplies-blue.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="title">Request Supplies</div>
                                </a>
                              </li>
                            )}
                            {(permissions?.['TrackAShipmentReport']) && (
                              <li>
                                <a
                                  className="cursor_pointer"
                                  onClick={() =>
                                    gotoPage("/reports/track-shipment")
                                  }
                                >
                                  <div className="icon">
                                    <img
                                      src="/assets/images/icon-track-shipment.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="title">Track A Shipment</div>
                                </a>
                              </li>
                            )}
                            {(permissions?.['LastReturn']) && (
                              <li>
                                <a
                                  className="cursor_pointer"
                                  onClick={() =>
                                    gotoPage(
                                      localStorage.getItem("transactionCount") === "1"
                                        ? "/reports/transaction-summary"
                                        : "/reports/order-history"
                                    )
                                  }
                                >
                                  <div className="icon">
                                    <img
                                      src="/assets/images/icon-last-return.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="title">Last Return</div>
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {userRegistrationDetails?.showLegacyPortal &&
                  <div className="cursor-pointer" onClick={() => { window.open(process.env.REACT_APP_RXRD_URL, "_blank"); }}>
                    <Button className="rxrd_btn" variant="primary">RxRD Portal</Button>
                  </div>
                }
                {(permissions?.['Notifications']) && (
                  <Dropdown className="notify-dropdown">
                    <Dropdown.Toggle
                      variant=""
                      id="dropdown-basic-1"
                      className="btn-notify"
                    >
                      <i className="fa-solid fa-lg fa-bell"></i>
                      {notificaionCount > 0 &&
                        <span className="recent-notify">{notificaionCount}</span>
                      }
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <div className="notify-list-wrapper p-3">
                        <div className="d-flex heading">
                          <h1>Notifications</h1>
                          {notifications?.length > 0 &&
                            <a href="#" onClick={() => readAllnotification()}>Read All</a>
                          }
                        </div>
                        <div className="notification-list">
                          {notifications?.length > 0 &&
                            <>
                              <ul>
                                {notifications.slice(0, 10).map((notification: any, index: any) => (
                                  <li key={index} className="cursor_pointer">
                                    <a onClick={() => redirectTo(notification)}>
                                      <div className="icon">
                                        <i className="fa-solid fa-bell"></i>
                                        {!notification?.isRead &&
                                          <span className="recent-notify"></span>
                                        }
                                      </div>
                                      <div className="detail">
                                        <p>{notification?.message}</p>
                                        <small>{moment(notification?.createdOn).format('MMMM DD, YYYY hh:mm A')}</small>
                                      </div>
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </>
                          }
                          {notifications?.length === 0 && (
                            <div className="no-records-wrapper mincardheight justify-content-center">
                              <svg
                                width="48"
                                height="42"
                                viewBox="0 0 48 42"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0 5.25C0 2.3543 2.39167 0 5.33333 0H18.6667V10.5C18.6667 11.952 19.8583 13.125 21.3333 13.125H32V16.2914C25.8417 18.0059 21.3333 23.5758 21.3333 30.1875C21.3333 35.0355 23.7583 39.3176 27.475 41.9426C27.2083 41.9836 26.9417 42 26.6667 42H5.33333C2.39167 42 0 39.6457 0 36.75V5.25ZM32 10.5H21.3333V0L32 10.5ZM36 18.375C39.1826 18.375 42.2348 19.6195 44.4853 21.8348C46.7357 24.0501 48 27.0546 48 30.1875C48 33.3204 46.7357 36.3249 44.4853 38.5402C42.2348 40.7555 39.1826 42 36 42C32.8174 42 29.7652 40.7555 27.5147 38.5402C25.2643 36.3249 24 33.3204 24 30.1875C24 27.0546 25.2643 24.0501 27.5147 21.8348C29.7652 19.6195 32.8174 18.375 36 18.375ZM36 38.0625C36.5304 38.0625 37.0391 37.8551 37.4142 37.4859C37.7893 37.1167 38 36.6159 38 36.0938C38 35.5716 37.7893 35.0708 37.4142 34.7016C37.0391 34.3324 36.5304 34.125 36 34.125C35.4696 34.125 34.9609 34.3324 34.5858 34.7016C34.2107 35.0708 34 35.5716 34 36.0938C34 36.6159 34.2107 37.1167 34.5858 37.4859C34.9609 37.8551 35.4696 38.0625 36 38.0625ZM36 22.3125C35.2667 22.3125 34.6667 22.9031 34.6667 23.625V30.1875C34.6667 30.9094 35.2667 31.5 36 31.5C36.7333 31.5 37.3333 30.9094 37.3333 30.1875V23.625C37.3333 22.9031 36.7333 22.3125 36 22.3125Z"
                                  fill="#3F475C"
                                  fillOpacity="0.3"
                                />
                              </svg>
                              <span>{t('NO_NEW_NOTIFICATION')}</span>
                            </div>
                          )}
                        </div>
                        {notifications?.length > 0 &&
                          <div className="text-end">
                            {(permissions?.['NotificationsListOfNotifcationsReceived']) && (
                              <a href="/notifications" className="text-link ">
                                View All
                              </a>
                            )}
                          </div>
                        }
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                <Dropdown className="user_dropdown">
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="btn-user"
                  >
                    <i className="fa-solid fa-user"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <Dropdown.Item
                      href="#/action-1"
                      className="user_dropdown_menu"
                    >
                      <div className="user_img">
                        <i className="fa-solid fa-user"></i>
                      </div>
                      <div className="uesr_detail">
                        <div className="user_name">
                          {user.firstName} {user.lastName}
                        </div>
                        <div className="user_email">
                          {activeAccount && activeAccount.username
                            ? activeAccount.username
                            : "Unknown"}
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    {azureImpersonateId && (
                      <>
                        <div className="impersonate-name">
                          <Dropdown.Item onClick={handleImpersonation}> Impersonating: {impersonatingUser} <span className="icon-close"> <i className=" fa-solid fa-xmark"></i></span> </Dropdown.Item>
                        </div>
                        <Dropdown.Divider />
                      </>
                    )}
                    {(permissions?.['MyProfile']) && (
                      <Dropdown.Item onClick={handleProfileEdit}>
                        My Profile
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={handleLogoutRedirect}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {showHierarchy && (
          <AccountHierarchy
            show={showHierarchy}
            handleClose={() => setShowHierarchy(false)}
          ></AccountHierarchy>
        )}
      </header>
    </AuthenticatedTemplate>
  );
};
export default Header;
