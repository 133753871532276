import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import { useAppDispatch } from "../../../../store/hooks";
import { getOverstockSummary } from "../../../../store/api/apiSlice";
import CurrencyConverter from "../../../shared/currencyConverter/CurrencyConverter";
import getFiles from "../../../shared/csvDownload/csvDownload";
import {
  COLUMN_CONFIG_REPORT_NAMES,
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { getTableFields } from "../../../../helpers/getTableFields";
import { useSelector } from "react-redux";
import { useUserSettings } from "../../../../helpers/hooks/useUserSettings";
import { showConfigColumns } from "../../../../helpers/getSortedVisibleCols";

function OverstockSummary() {
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const dispatch = useAppDispatch();
  const [gridApi, setGridApi] = useState<any>(null);
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const [gridData, setGridData] = useState({
    defaultFilter: true,
    sortOrder: "",
    rows: [],
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    isForExcel: false,
    sortField: "",
    overstockProduct: [],
    totalRows: 0,
    totalAmount: "",
    csvName: "OverstockSummary.csv",
    URL: "Reports/GetOverstockSummary",
    filterOptions: [],
  });

  const columnItems = [
    {
      headerName: "Line ID",
      headerTooltip: "Line ID",
      tooltipShowDelay: 0,
      field: "lineId",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Customer ID",
      headerTooltip: "Customer ID",
      tooltipShowDelay: 0,
      field: "customerId",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Customer Name",
      headerTooltip: "Customer Name",
      tooltipShowDelay: 0,
      field: "customerName",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Order#",
      headerTooltip: "Order#",
      tooltipShowDelay: 0,
      field: "orderNumber",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Manufacturer",
      headerTooltip: "Manufacturer",
      tooltipField: "manufacturer",
      tooltipShowDelay: 0,
      field: "manufacturer",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "NDC",
      headerTooltip: "NDC",
      tooltipShowDelay: 0,
      field: "ndc",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Product",
      headerTooltip: "Product",
      tooltipShowDelay: 0,
      tooltipField: "product",
      field: "product",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Quantity",
      headerTooltip: "Quantity",
      tooltipShowDelay: 0,
      field: "quantity",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        maxNumConditions: 1,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Lot#",
      headerTooltip: "Lot#",
      tooltipShowDelay: 0,
      field: "lot",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Process Date",
      headerTooltip: "Process Date",
      tooltipShowDelay: 0,
      field: "processDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: false,
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Expiration Date",
      headerTooltip: "Expiration Date",
      tooltipShowDelay: 0,
      field: "expirationDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: false,
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Overstock Return Date",
      headerTooltip: "Overstock Return Date",
      tooltipShowDelay: 0,
      field: "overstockReturnDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: false,
      comparator: customComparator,
      valueFormatter: function (params: any) {
        return params.value !== null && params.value !== undefined
          ? formatDatePipe(params.value)
          : "";
      },
    },
    {
      headerName: "Overstock Status",
      headerTooltip: "Overstock Status",
      tooltipShowDelay: 0,
      tooltipField: "overstockStatus",
      field: "overstockStatus",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Credit Disposition",
      headerTooltip: "Credit Disposition",
      tooltipShowDelay: 0,
      field: "creditDisposition",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "ERV",
      headerTooltip: "ERV",
      tooltipShowDelay: 0,
      field: "erv",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Expected Credit Method",
      headerTooltip: "Expected Credit Method",
      tooltipShowDelay: 0,
      tooltipField: "expectedCreditMethod",
      field: "expectedCreditMethod",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Credit Status",
      headerTooltip: "Credit Status",
      tooltipShowDelay: 0,
      tooltipField: "creditStatus",
      field: "creditStatus",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "ARV",
      headerTooltip: "ARV",
      tooltipShowDelay: 0,
      field: "arv",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
      type: gridAlignment.RIGHT,
    },
    {
      headerName: "Denial Reason",
      headerTooltip: "Denial Reason",
      tooltipShowDelay: 0,
      field: "denialReason",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    // {
    //   headerName: "Overstock Recon Locked",
    //   field: "overstockReconLocked",
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ["reset"],
    //     debounceMs: 1000,
    //     suppressAndOrCondition: true,
    //   },
    //   menuTabs: ["filterMenuTab"],
    //   hide: true,
    //   comparator: customComparator,
    // },
  ];

  const { id, dbColumnConfig } = useUserSettings(COLUMN_CONFIG_REPORT_NAMES?.OverstockSummary);
  const [colDefs, setColDefs] = useState(() => showConfigColumns(columnItems, dbColumnConfig));

  function customComparator() {
    return 0; //means no comparing and no sorting client side
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        overstockSummary();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      overstockSummary();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
  ]);


  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  useEffect(() => {
    if (Object.keys(dbColumnConfig).length > 0)
      setColDefs(showConfigColumns(columnItems, dbColumnConfig));
  }, [dbColumnConfig])

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();

    setColDefs(columnValues);

    myRef?.current.handleClose();
  };

  // this function defination change after enterprise
  const overstockSummary = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let generateFilter;
            if (Object.keys(params.request.filterModel).length === 0) {
              generateFilter = getTableFields(
                {
                  overstockReconLocked: {
                    filterType: "text",
                    type: "equals",
                    filter: "no",
                  },
                }
              );
            } else {
              generateFilter = getTableFields(params.request.filterModel)
            }
            let data = {
              pageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              pageSize: gridData.pageSize,
              sortField: gridData.sortField,
              sortDirection: gridData.sortOrder
                ? parseInt(gridData.sortOrder)
                : 1,
              isForExcel: gridData.isForExcel,
              globalSearch: gridData.globalSearch,
              filterOptions: generateFilter,
              CustomerIds: formateIds(userAccounts),
            };

            updateGridData("filterOptions", generateFilter);

            dispatch(getOverstockSummary(data)).then((result: any) => {
              if (result?.payload?.data?.overstockSummary?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.overstockSummary,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
              updateGridData("defaultFilter", false);
              updateGridData(
                "overstockProduct",
                result?.payload?.data?.overstockProduct
              );

              updateGridData("totalRows", result?.payload?.data?.totalCount);
              updateGridData(
                "totalAmount",
                CurrencyConverter.format(result?.payload?.data?.totalAmount)
              );
            });
          },
        };
        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) { }
  };

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();

    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };

  const onExport = (e: any) => {
    updateGridData("isForExcel", true);
  };

  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  }
  return (
    <Container fluid>
      <PageTitle
        name="Overstock Summary"
        buttonName="Back"
        routePage="/reports/view-all"
      ></PageTitle>

      <div className="overstock-storage">
        <div className="content_body mb-4">
          <div className="heading">
            <h2>Overstock Product in storage</h2>
          </div>

          <div className="card-gray d-flex">
            <div className="highlight-sec d-flex flex-column">
              <span>Returnable In:</span>
              <span>ERV:</span>
            </div>
            <div className="month-details d-flex justify-content-between">
              {(gridData?.overstockProduct || []).map((ele: any, index) => {
                return (
                  <div
                    key={`${ele?.returnableIn}-${index}`}
                    className="d-flex flex-column"
                  >
                    <span>{ele?.returnableIn || ""}</span>
                    <span>{CurrencyConverter.format(ele?.erv)}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="content_body">
        <GridComponent
          dbUserReportId={id}
          dbReportName={COLUMN_CONFIG_REPORT_NAMES?.OverstockSummary}
          dbColumnConfig={dbColumnConfig}
          defaultColumns={columnItems}
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          autoSizeStrategy={{
            type: "fitCellContents",
            colIds: [
              "lot",
              "lineId",
              "quantity",
              "processDate",
              "customerId",
              "orderNumber",
              "ndc",
              "erv",
              "lotNumber",
            ],
          }}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
        />
      </div>
    </Container>
  );
}
export default OverstockSummary;
