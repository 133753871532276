import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
 export const b2cPolicies = {
    authorities: {
        signUpSignIn: {
            authority: process.env.REACT_APP_AUTHORITY_BASE_URL as string +process.env.REACT_APP_SIGN_IN
        },
        resetPass: {
            authority: process.env.REACT_APP_AUTHORITY_BASE_URL as string+process.env.REACT_APP_ACCOUNT_SETUP
        },
        changePass: {
            authority: process.env.REACT_APP_AUTHORITY_BASE_URL as string+process.env.REACT_APP_CHANGE_PASSWORD
        },
        parma_login: {
            authority: process.env.REACT_APP_AUTHORITY_BASE_URL as string+process.env.REACT_APP_SIGN_IN_PHARMAUSER
        }
    },
    authorityDomain: process.env.REACT_APP_AUTHORITYDOMAIN
}

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENTID,
        authority: localStorage.getItem("isAdmin") === "true" ? b2cPolicies.authorities.parma_login.authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: (level:any, message:any, containsPii:any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        // console.error(message);
                        return;
                    case LogLevel.Info:
                        // console.info(message);
                        return;
                    case LogLevel.Verbose:
                        // console.debug(message);
                        return;
                    case LogLevel.Warning:
                        // console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
    scopes: [b2cPolicies.authorities.signUpSignIn.authority]
};

export const loginRequestAccess = {
    scopes: [process.env.REACT_APP_SCOPE_BASE as string+process.env.REACT_APP_CLIENTID+process.env.REACT_APP_SCOPE]
};

