import { getUserRegistrationDetails, validateUserRegistrationDetails } from "../../../store/api/apiSlice";
import { useAppDispatch } from "../../../store/hooks";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const ValidateRegistrationPage = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const cn = searchParams.get('cn');
  const form = searchParams.get('form');
  const toast = searchParams.get('toast');

  function validateRegistration() {
    const payload: any = {
      registrationId: form,
      customerId: cn,
      toast: toast
    };

    dispatch(validateUserRegistrationDetails(payload)).then((result: any) => {
      dispatch(getUserRegistrationDetails({})).then((result: any) => {
        if (result?.payload?.data) navigate('/');
      })
    })
  }

  useEffect(() => {
    validateRegistration();
  }, []);

  return (
    <div>
    </div>
  );
};

export default ValidateRegistrationPage;